import ReactSelect from 'react-select';
import Creatable from 'react-select/creatable';

export const Select = ({
  options,
  innerRef,
  name,
  onChange,
  value,
  label,
  defaultValue,
  isClearable,
  isCreatable,
  error,
  ...rest
}) => {
  const foundDefaultValue = options?.find(opt => opt.value === defaultValue);
  const selectProps = {
    options,
    name,
    ref: innerRef,
    defaultValue:
      (defaultValue || defaultValue === false || defaultValue === 0) &&
      (foundDefaultValue ? foundDefaultValue : { value: defaultValue, label: defaultValue }),
    isClearable,
    onChange,
    value: value && (value?.value ? value : { value, label: value }),
    ...rest,
    classNamePrefix: 'select',
  };

  return (
    <div className={'select-wrapper ' + (error ? '--with-error' : '')}>
      {label && <label className="form-label">{label}</label>}
      {isCreatable ? <Creatable {...selectProps} /> : <ReactSelect {...selectProps} />}
      {error && <div className="invalid-feedback">{error}</div>}
    </div>
  );
};
