import { createSelector } from 'reselect';
import { APPLICATIONS_STORE_KEY, ApplicationsSliceNames } from '~/modules/applications/redux/applications.store';

const selectApplicationsState = state => state[APPLICATIONS_STORE_KEY];

export const selectCourses = createSelector(
  selectApplicationsState,
  store => store[ApplicationsSliceNames.courses].value?.results || []
);

export const selectDatasets = createSelector(selectApplicationsState, store => store[ApplicationsSliceNames.datasets]);

export const selectApplications = createSelector(
  selectApplicationsState,
  store => store[ApplicationsSliceNames.applications].value?.results || []
);

export const selectApplicationsModel = createSelector(
  selectApplicationsState,
  store => store[ApplicationsSliceNames.applications]
);
