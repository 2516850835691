import { GlobalSpinner } from '~/modules/app/components/GlobalSpinner/GlobalSpinner';
import { useSelector } from 'react-redux';
import { selectAppIsLoading, selectSnackbarNotifications } from '~/modules/app/redux/app.selectors';
import { Snackbar } from '~/common/components/Snackbar/Snackbar';
import { SnackbarContainer } from '~/modules/app/components/SnackbarContainer/SnackbarContainer';
import { useLoadInitialData, useOnRouteChange } from '~/modules/app/models/app.hooks';

export const AppModule = () => {
  const isLoading = useSelector(selectAppIsLoading);
  const snackbarNotifications = useSelector(selectSnackbarNotifications);
  useLoadInitialData();
  useOnRouteChange();

  return (
    <>
      {isLoading && <GlobalSpinner />}
      {snackbarNotifications?.length > 0 && (
        <SnackbarContainer>
          {snackbarNotifications.map(sn => (
            <Snackbar text={sn.text} id={sn.id} title={sn.title} />
          ))}
        </SnackbarContainer>
      )}
    </>
  );
};
