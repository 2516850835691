import ReactCreatableAsyncSelect from 'react-select/async-creatable';
import ReactAsyncSelect from 'react-select/async';
import { useState, useCallback } from 'react';

export const AsyncSelect = ({
  loadOptions,
  onChange,
  defaultOptions,
  isCreatable,
  error,
  label,
  value,
  defaultValue,
  isClearable,
  name,
  noOptionsMessage = 'Start typing to search',
  ...rest
}) => {
  const [delayLoadOptionsTimeout, setDelayLoadOptionsTimeout] = useState(null);
  const foundDefaultValue = defaultOptions?.find(opt => opt.value === defaultValue);

  const _loadOptions = useCallback(
    (inputValue, callback) => {
      if (delayLoadOptionsTimeout) {
        clearTimeout(delayLoadOptionsTimeout);
      }
      const timeout = setTimeout(() => {
        loadOptions(inputValue, callback);
      }, 500);
      setDelayLoadOptionsTimeout(timeout);
    },
    [delayLoadOptionsTimeout, loadOptions]
  );

  const props = {
    onChange,
    defaultOptions,
    loadOptions: _loadOptions,
    value: value && (value?.value ? value : { value, label: value }),
    defaultValue:
      (defaultValue || defaultValue === false || defaultValue === 0) &&
      (foundDefaultValue ? foundDefaultValue : { value: defaultValue, label: defaultValue }),
    isClearable,
    name,
    ...rest,
    classNamePrefix: 'select',
    noOptionsMessage: () => noOptionsMessage,
  };
  return (
    <div className={'select-wrapper ' + (error ? '--with-error' : '')}>
      {label && <label className="form-label">{label}</label>}
      {isCreatable ? <ReactCreatableAsyncSelect {...props} /> : <ReactAsyncSelect {...props} />}
      {error && <div className="invalid-feedback">{error}</div>}
    </div>
  );
};
