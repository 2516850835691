import { StudentApplicationStatus } from '~/modules/applications/pages/CourseDashboardPage/blocks/components/StudentApplicationStatus/StudentApplicationStatus';
import { StudentPodInfo } from '~/modules/applications/pages/CourseDashboardPage/blocks/components/StudentPodInfo/StudentPodInfo';
import { useGetApplicationRequest } from '~/modules/applications/models/application.hooks';
import { useParams } from 'react-router-dom';
import { applicationTypes } from '~/modules/applications/models/application.model';

export const StudentDashboard = () => {
  const { id } = useParams();
  const application = useGetApplicationRequest(id, applicationTypes.student);
  return (
    <div className="narrow mt-20">
      {application && (
        <>
          <StudentApplicationStatus application={application} />
          <StudentPodInfo application={application} />
        </>
      )}
    </div>
  );
};
