import { ReduxController } from '~/common/libs/ReduxController/ReduxController';
import { axios } from '~/app/axios/axios';
import { UserActionGroups, UserSliceNames } from '~/modules/user/redux/user.store';
import { AuthToken } from '~/modules/user/models/user.model';
import { setAppReady, setCanRequest, setSnackbar } from '~/modules/app/redux/app.actions';
import { clearApplications, loadCourses } from '~/modules/applications/redux/applications.actions';
const authTokenController = new AuthToken();

export const signUp = data =>
  ReduxController.createAction({
    callApi: () => axios.post('/users/sign-up/', data),
  });

export const verifyPass = data =>
  ReduxController.createAction({
    callApi: () => axios.post('/media_files/verify_password/', data),
  });

export const update = data =>
  ReduxController.createAction({
    callApi: () => axios.patch('/users/me/', data),
    isUpdate: true,
    actionGroupName: UserActionGroups[UserSliceNames.user],
    afterSuccessCall: (_, dispatch) => {
      dispatch(loadCourses());
    },
  });

export const extractId = (props) => {
    const query = new URLSearchParams(props.location.search);
    const uid = query.get('uid')
    return uid
}

export const extractDataParam = (props) => {
    const query = new URLSearchParams(props.location.search);
    const data = query.get('data')
    return data
}

export const redirectTo = (address) => {
    window.location.href = address
}

export const verificate = token =>
  ReduxController.createAction({
    callApi: () => axios.post('/users/verification/', { token }),
  });

export const logout = () => dispatch => {
  const types = ReduxController.createTypesSequence(UserActionGroups[UserSliceNames.user]);
  dispatch({
    type: types.CLEAR,
  });
  dispatch(
    ReduxController.createAction({
      callApi: () => axios.get('/users/logout/'),
      withGlobalLoading: false,
    })
  );
  dispatch(clearApplications());
  dispatch(setCanRequest(false));
  authTokenController.delete();
};

export const whoami = () => {
  const token = authTokenController.get();
  if (token) {
    authTokenController.set(token);
    return ReduxController.createAction({
      callApi: () => axios.get('/users/me/'),
      actionGroupName: UserActionGroups[UserSliceNames.user],
      afterSuccessCall: (_, dispatch) => {
        dispatch(setAppReady(true));
        dispatch(setCanRequest(true));
      },
      afterFailCall: (_, dispatch) => {
        dispatch(setAppReady(true));
        authTokenController.delete();
      },
    });
  }
  authTokenController.delete();
  return setAppReady(true);
};

export const login = data =>
  ReduxController.createAction({
    callApi: () => axios.post('/users/sign-in/', data),
    actionGroupName: UserActionGroups[UserSliceNames.user],
    afterSuccessCall: (response, dispatch) => {
      authTokenController.set(response.token);
      dispatch(setCanRequest(true));
    },
  });

export const getORCID = () => 
  ReduxController.createAction({
    callApi: () => axios.get('/users/me/'),
  })

export const getORCIDEducation = () => 
  ReduxController.createAction({
    callApi: () => axios.get('/social/orcid/education/'),
  })

export const postCertToORCID = data =>
  ReduxController.createAction({
    callApi: () => axios.post('/social/orcid/education/', data),
    afterSuccessCall: (response, dispatch) => {
      dispatch(setSnackbar('Published to Orcid'));
    }
  });

export const deleteCertFromORCID = data =>
  ReduxController.createAction({
    callApi: () => axios.delete('/social/orcid/education/', {data: data}),
    afterSuccessCall: (response, dispatch) => {
      dispatch(setSnackbar('Removed from Orcid'));
    }
  });

export const getSocialAccounts = () =>
  ReduxController.createAction({
    callApi: () => axios.get('/social/accounts/'),
  })

export const githubLogin = (data, isConnect = false) =>
  ReduxController.createAction({
    callApi: () => axios.post('/social/github/' + (isConnect ? 'connect/' : ''), data),
    actionGroupName: UserActionGroups[UserSliceNames.user],
    afterSuccessCall: (response, dispatch) => {
      if (!isConnect) {
        authTokenController.set(response.token);
      } else {
        dispatch(setSnackbar('Connected to github'));
      }
      dispatch(setCanRequest(true));
    },
    afterFailCall: (error, dispatch) => {
      dispatch(setSnackbar('Failed to connect to github'))
    }
  });

export const googleLogin = (data, isConnect) =>
  ReduxController.createAction({
    callApi: () => axios.post('/social/google/' + (isConnect ? 'connect/' : ''), data),
    actionGroupName: UserActionGroups[UserSliceNames.user],
    afterSuccessCall: (response, dispatch) => {
      if (!isConnect) {
        authTokenController.set(response.token);
      } else {
        dispatch(setSnackbar('Connected to google'));
      }
      dispatch(setCanRequest(true));
    },
    afterFailCall: (error, dispatch) => {
      dispatch(setSnackbar('Failed to connect to google'))
    }
  });

export const orcidLogin = (data, isConnect) =>
  ReduxController.createAction({
    callApi: () => axios.post('/social/orcid/' + (isConnect ? 'connect/' : ''), data),
    actionGroupName: UserActionGroups[UserSliceNames.user],
    afterSuccessCall: (response, dispatch) => {
      if (!isConnect) {
        authTokenController.set(response.token);
      } else {
        dispatch(setSnackbar('Connected to ORCID'));
      }
      dispatch(setCanRequest(true));
    },
    afterFailCall: (error, dispatch) => {
      dispatch(setSnackbar('Failed to connect to ORCID'))
    }
  });

export const disconnectSocialAccount = (pk, socialAccount) => 
  ReduxController.createAction({
    callApi: () => axios.post(`/social/accounts/${pk}/disconnect/`),
    actionGroupName: UserActionGroups[UserSliceNames.user],
    afterSuccessCall: (response, dispatch) => {
      dispatch(setSnackbar(`Successfully disconnected from ${socialAccount}`));
    },
    afterFailCall: (error, dispatch) => {
      // console.error('There was an error disconnecting the social account!', error);
      dispatch(setSnackbar(`Failed to disconnect from ${socialAccount}.\nMake sure you have a password set first.`));     
    }
  });

export const forgotPassword = data =>
  ReduxController.createAction({
    callApi: () => axios.post('/users/forgot-password/', data),
  });

export const resetPassword = (token, password) =>
  ReduxController.createAction({
    callApi: () => axios.post('/users/reset-password/', { token, password }),
  });

export const createLogistic = data =>
  ReduxController.createAction({
    callApi: () => axios.post('/users/logistic/', data),
    afterSuccessCall: (_, dispatch) => {
      dispatch(whoami());
    },
  });

export const updateLogistic = (data, userId) =>
  ReduxController.createAction({
    callApi: () => axios.patch(`/users/logistic/${userId}/`, data),
    afterSuccessCall: (_, dispatch) => {
      dispatch(whoami());
    },
  });

export const createGeneralInfo = data =>
  ReduxController.createAction({
    callApi: () => axios.post('/users/general-info/', data),
    afterSuccessCall: (_, dispatch) => {
      dispatch(whoami());
    },
  });

export const getRedirectLink = (data, uid) =>
    ReduxController.createAction({
        callApi: () => axios.get(`/redirect/get/${uid}/`, data),
        afterSuccessCall: (response, dispatch) => {
            const route = response.results[0].route
            redirectTo(route)
            dispatch(whoami());
        },
        afterFailCall: (error, dispatch) => {
            console.log(error)
            dispatch()
        }
    });

export const submitEvaluationData = data =>
  ReduxController.createAction({
    callApi: () => axios.post(`/evaluation/submit/`, { tutorial_response: data }),
    afterSuccessCall: (_, dispatch) => {
      dispatch(whoami());
    },
  });

export const updateGeneralInfo = (data, userId) =>
  ReduxController.createAction({
    callApi: () => axios.patch(`/users/general-info/${userId}/`, data),
    afterSuccessCall: (_, dispatch) => {
      dispatch(whoami());
    },
  });

export const createDemographic = data =>
  ReduxController.createAction({
    callApi: () => axios.post('/users/demographic/', data),
    afterSuccessCall: (_, dispatch) => {
      dispatch(whoami());
    },
  });

export const updateDemographic= (data, userId) =>
  ReduxController.createAction({
    callApi: () => axios.patch(`/users/demographic/${userId}/`, data),
    afterSuccessCall: (_, dispatch) => {
      dispatch(whoami());
    },
  });

export const searchInstitutes = str =>
  ReduxController.createAction({
    callApi: () => axios.get('/initial-data/institutions/', { params: { search: str } }),
    withGlobalLoading: false,
  });

export const verificateNewEmail = token =>
  ReduxController.createAction({
    callApi: () => axios.post('/users/verification-new-email/', { token }),
  });

export const wantsToEnroll = id =>
  ReduxController.createAction({
    callApi: () => axios.patch(`/courses/student-applications/${id}/wants-to-enroll/`, { wants_to_enroll: true }),
    afterFailCall: (_, dispatch) => {
      dispatch(setSnackbar('You cannot perform this action'));
    },
  });

export const wantsToEnrollTA = id =>
  ReduxController.createAction({
    callApi: () => axios.patch(`/courses/ta-applications/${id}/wants-to-enroll/`, { wants_to_enroll: true }),
    afterFailCall: (_, dispatch) => {
      dispatch(setSnackbar('You cannot perform this action'));
    },
  });

export const getReceipts = () =>
  ReduxController.createAction({
    callApi: () => axios.get('/payments/user-receipts/'),
  });

export const getMyCertificates = () =>
  ReduxController.createAction({
    callApi: () => axios.get('/users/certificates/'),
  });

export const getCertificate = id =>
  ReduxController.createAction({
    callApi: () => axios.get(`/users/certificates/${id}/`),
  });
