import { AppActionGroups, AppSliceNames } from '~/modules/app/redux/app.store'
import { ReduxController } from '~/common/libs/ReduxController/ReduxController'
import { axios } from '~/app/axios/axios'

export const setAppReady = bool => ({
    type: AppActionGroups[AppSliceNames.isReady],
    payload: bool,
})

export const setLoading = bool => ({
    type: AppActionGroups[AppSliceNames.isLoading],
    payload: bool,
})

export const setCanRequest = bool => ({
    type: AppActionGroups[AppSliceNames.canRequest],
    payload: bool,
})

export const deleteSnackbar = id => ({
    type: AppActionGroups.DELETE_SNACKBAR,
    payload: { id },
})

export const getInitialData = () =>
    ReduxController.createAction({
        callApi: () => axios.get('/initial-data/'),
        actionGroupName: AppActionGroups[AppSliceNames.initialData],
    })

export const getMediaFiles = () =>
    ReduxController.createAction({
        callApi: () => axios.get('/media_files'),
        actionGroupName: AppActionGroups[AppSliceNames.mediaFiles],
    })

export const setSnackbar = (text, title = 'Notification', withAutoClose = true, delayToClose = 5000) => dispatch => {
    const id = Math.random().toString(36).substring(2)
    dispatch({
        type: AppActionGroups.SET_SNACKBAR,
        payload: { text, title, id },
    })
    if (withAutoClose) {
        setTimeout(() => {
            dispatch(deleteSnackbar(id))
        }, delayToClose)
    }
}

export const searchCountries = str =>
    ReduxController.createAction({
        callApi: () => axios.get('/initial-data/countries/', { params: { search: str } }),
        withGlobalLoading: false,
    })

export const resendEmail = (token, type) =>
    ReduxController.createAction({
        callApi: () => axios.post('/users/resend-email/', { token, token_type: type }),
    })

export const checkIsTokenExpired = (token, type) =>
    ReduxController.createAction({
        callApi: () => axios.post('/users/verification-token-check/', { token, token_type: type }),
    })
