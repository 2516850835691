import { Form } from '~/common/components/Form/Form';

export const Checkbox = ({ className, innerRef, label, name, value, defaultChecked, text, error, onChange, checked, disabled }) => {
  return (
    <div className={'checkbox form-check ' + (className ? className : '')}>
      {label && <Form.Label>{label}</Form.Label>}
      <input
        type="checkbox"
        className="form-check-input"
        ref={innerRef}
        name={name}
        value={value}
        defaultChecked={defaultChecked}
        onChange={onChange}
        checked={checked}
        disabled={disabled}
      />
      {text && (
        <label title="" className="form-check-label">
          {text}
        </label>
      )}
      {error && <div className="invalid-feedback">{error}</div>}
    </div>
  );
};
