import { SignIn } from '~/modules/user/pages/SignIn/blocks/SignIn';
import { Layout } from '~/common/components/Layout/Layout';
import { useTitle } from '~/modules/app/models/app.hooks';

export const SignInPage = () => {
  useTitle('Login');
  return (
    <Layout withFooter={true}>
      <SignIn />
    </Layout>
  );
};
