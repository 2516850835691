import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Form } from '~/common/components/Form/Form';
import { Input } from '~/common/components/Input/Input';
import { Button } from '~/common/components/Button/Button';
import { Link } from '~/common/components/Link/Link';
import { userRoutes } from '~/modules/user/router/Router';
import { useDispatch } from 'react-redux';
import { resetPassword } from '~/modules/user/redux/user.actions';
import { history } from '~/app/history/history';
import { useIsTokenExpired, useSnackbar } from '~/modules/app/models/app.hooks';
import { tokenTypes } from '~/modules/app/models/app.model';
import { verificationErrors } from '~/modules/user/models/user.model';

const formNames = {
  password: 'password',
  confirm_password: 'confirm_password',
};

export const ResetPassword = () => {
  const { token } = useParams();
  const { register, handleSubmit, getValues, errors } = useForm();
  const dispatch = useDispatch();
  const checkIsTokenExpired = useIsTokenExpired();
  const { setSnackbar } = useSnackbar();

  const onSubmit = data => {
    dispatch(resetPassword(token, data[formNames.password])).then(() => {
      history.push(userRoutes.login);
      dispatch(setSnackbar('Password has been reset'));
    });
  };

  useEffect(() => {
    checkIsTokenExpired(token, tokenTypes.password).catch(e => {
      const error = e?.response?.data?.non_field_errors;
      const errorType = error[0]?.code;
      if (errorType === verificationErrors.expired) {
        setSnackbar('Your token has expired', 'Notification', false);
      } else {
        setSnackbar('Your token is not active', 'Notification', false);
      }
      history.push(userRoutes.forgot_password);
    });
  }, [checkIsTokenExpired, setSnackbar, token]);

  return (
    <div className="reset-password">
      <div className="inner">
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Row>
            <p className="reset-password__description">Enter new password</p>
          </Form.Row>
          <Form.Row>
            <Input
              // label="Email address"
              type="password"
              placeholder="Enter password"
              innerRef={register({ required: true })}
              name={formNames.password}
            />
          </Form.Row>
          <Form.Row>
            <Input
              // label="Email address"
              type="password"
              placeholder="Confirm password"
              innerRef={register({
                required: true,
                validate: value => {
                  const values = getValues();
                  return values.password === value || 'Passwords don`t match';
                },
              })}
              name={formNames.confirm_password}
              error={errors[formNames.confirm_password]?.message}
            />
          </Form.Row>
          <Form.Row>
            <Button variant="primary" type="submit" className="reset-password__submit-btn">
              Submit
            </Button>
          </Form.Row>
          <Form.Row className="reset-password__auth-links">
            <div className="reset-password__login">
              <Link to={userRoutes.login}>Sign In</Link>
            </div>
            <div className="reset-password__sign-up">
              <Link to={userRoutes.sign_up}>Create a new account</Link>
            </div>
          </Form.Row>
        </Form>
      </div>
    </div>
  );
};
