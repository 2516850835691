import { Block } from '~/common/components/Block/Block';
import { Form } from '~/common/components/Form/Form';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

export const TASuccess = ({ application }) => {


  return (
    <Block title="TA Confirmation">
    <Form>
        <Form.Row>
        Congrats, you are officially enrolled as a TA in {application.course_name}! Your pod info will be released here in June
        </Form.Row>
    </Form>
    </Block>
  );
};
