import { Layout } from '~/common/components/Layout/Layout';
import { EditStudentApplication } from '~/modules/applications/pages/EditStudentApplication/blocks/EditStudentApplication';

export const EditStudentApplicationPage = () => {
  return (
    <Layout>
      <EditStudentApplication />
    </Layout>
  );
};
