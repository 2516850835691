import { Table } from '~/common/components/Table/Table';
import { timeSlotsLabels } from '~/modules/user/models/user.model';
import { Block } from '~/common/components/Block/Block';

export const ProjectTAPodInfo = ({ pods, application }) => {

  return (
    <>
        <Block title={application.course_name} className="ta-role-info">
            <table   className="role-table" cellPadding="0" cellSpacing="0">
              <thead>
                  <tr>
                      <th scope="col">Possible Role</th>
                      <th scope="col" className="alignright">Your Role</th>
                  </tr>
              </thead>
              <tbody>
              <tr>
                <td>Lead Regular TA</td>
                <td className="alignright">  {(application.selected_as_lead_ta && application.ta_for === 'regular_TA') ? <div>&#10004;</div> : ''} </td>
              </tr>
              <tr>
                <td>Lead Project TA</td>
                <td className="alignright">{(application.selected_as_lead_ta && application.ta_for === 'project_TA') ? <div>&#10004;</div> : ''}</td>
              </tr>
              <tr>
                <td>Regular TA</td>
                <td className="alignright">{(!application.selected_as_lead_ta && application.ta_for === 'regular_TA') ? <div>&#10004;</div> : ''}</td>
              </tr>
              <tr>
                <td>Project TA</td>
                <td className="alignright">{(!application.selected_as_lead_ta && application.ta_for === 'project_TA') ? <div>&#10004;</div> : ''}</td>
              </tr>
              </tbody>
            </table>
      </Block>
      <Block title="Your project pods info" className="ta-pod-info">
        {pods['results'].map(pod => {
          return (
            <div style={{marginBottom: "1.2rem"}}>
              <Table
                className="project-ta-pod-info"
                rows={[
                  ['Pod id', pod.id],
                  ['Pod name', pod.name],
                  ['Pod time slot', timeSlotsLabels[pod.time_slot]],
                  ['Pod language', pod.language],
                  ['Pod dataset', pod.dataset],
                  ['Pod second dataset', pod?.second_dataset],
                  ['Pod ta name', pod.ta_info?.full_name],
                  ['Pod ta email', pod.ta_info?.email],
                  ['Meeting Link', pod?.zoom?.meeting_link],
                  ['Claim host key', pod?.zoom?.claim_host_key],
                  ['Login email', pod?.zoom?.login_email],
                  ['Email password', pod?.zoom?.email_password],
                  ['Transcripts', pod?.zoom?.transcripts],
                ]}
              />
            </div>
          );
        })}
      </Block>
    </>
  );
};
