import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { verificate } from '~/modules/user/redux/user.actions';
import { history } from '~/app/history/history';
import { userRoutes } from '~/modules/user/router/Router';
import { verificationErrors } from '~/modules/user/models/user.model';
import { applicationsRoutes } from '~/modules/applications/router/Router';
import { Button } from '~/common/components/Button/Button';
import { useResendEmail, useSnackbar } from '~/modules/app/models/app.hooks';
import { tokenTypes } from '~/modules/app/models/app.model';

export const Verification = () => {
  const [isError, setIsError] = useState(false);
  const { token } = useParams();
  const dispatch = useDispatch();
  const resendEmail = useResendEmail();
  const { setSnackbar } = useSnackbar();

  const onResendButtonClick = () => {
    resendEmail(token, tokenTypes.email)
      .then(() => {
        setSnackbar('Email has been sent');
        history.push(applicationsRoutes.homepage);
      })
      .catch(() => {
        setSnackbar('An error appeared');
      });
  };

  useEffect(() => {
    dispatch(verificate(token))
      .then(() => {
        history.push(userRoutes.login);
      })
      .catch(e => {
        const error = e?.response?.data?.non_field_errors;
        const errorType = error[0]?.code;

        if (errorType === verificationErrors.expired) {
          setIsError(true);
        } else {
          history.push(applicationsRoutes.homepage);
        }
      });
  }, [token, dispatch]);
  return (
    <>
      {isError ? (
        <div className="verification">
          <div className="verification__text">
            Your token has expired. Click the button below to resend your verification email.
          </div>
          <Button className="verification__btn" onClick={onResendButtonClick}>
            Resend
          </Button>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};
