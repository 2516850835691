import { UserModule } from '~/modules/user';
import { AppModule } from '~/modules/app';
import { ApplicationsModule } from '~/modules/applications';
import { Router } from '~/app/router/Router';

export const App = () => {
  return (
    <>
      <UserModule />
      <ApplicationsModule />
      <AppModule />
      <Router />
    </>
  );
};
