import React, { useState } from 'react';
import { Button } from '~/common/components/Button/Button';
import { Form } from '~/common/components/Form/Form';
import { Input } from '~/common/components/Input/Input';
import { useForm } from 'react-hook-form';
import { Link } from '~/common/components/Link/Link';
import { userRoutes } from '~/modules/user/router/Router';
import { Modal } from '~/common/components/Modal/Modal';
import { signUp } from '~/modules/user/redux/user.actions';
import { useDispatch, useSelector } from 'react-redux';
import { setErrorsFunction } from '~/common/libs/FormHelpers/FormHelpers';
import { setSnackbar } from '~/modules/app/redux/app.actions';
import { history } from '~/app/history/history';
import { SocialServices } from '~/modules/user/components/SocialServices/SocialServices';
import { selectRegisterPageDescription } from '~/modules/app/redux/app.selectors';
import { Markdown } from '~/common/components/Markdown/Markdown';

const formNames = {
  first_name: 'first_name',
  last_name: 'last_name',
  email: 'email',
  password: 'password',
  confirm_password: 'confirm_password',
};

export const SignUp = () => {
  const [showSuccessRegisterModal, setShowSuccessRegisterModal] = useState(false);
  const { register, handleSubmit, getValues, errors, setError } = useForm();
  const dispatch = useDispatch();
  const setErrors = setErrorsFunction(setError);
  const descriptionText = useSelector(selectRegisterPageDescription);

  const onSubmit = data => {
    dispatch(signUp(data))
      .then(() => {
        dispatch(setSnackbar('Confirmation message has been sent to your email', 'Notification', false));
        history.push(userRoutes.login);
      })
      .catch(e => {
        const error = e.response.data;
        setErrors(error);
      });
  };

  return (
    <div className="sign_up mt-20 narrow">
      <div className="inner">
        <div className="sign_up__text">
          <Markdown>{descriptionText}</Markdown>
        </div>
        <Form onSubmit={handleSubmit(onSubmit)} className="sign_up__form">
          <Form.Row>
            <Input
              label="First name"
              type="text"
              placeholder="First name"
              innerRef={register({ required: true })}
              name={formNames.first_name}
            />
          </Form.Row>
          <Form.Row>
            <Input
              label="Last name"
              type="text"
              placeholder="Last name"
              innerRef={register({ required: true })}
              name={formNames.last_name}
            />
          </Form.Row>
          <Form.Row>
            <Input
              label="Email address"
              type="email"
              placeholder="Enter email"
              innerRef={register({ required: true })}
              name={formNames.email}
              error={errors[formNames.email]?.message}
            />
          </Form.Row>
          <Form.Row>
            <Input
              label="Password"
              type="password"
              placeholder="Password"
              innerRef={register({ required: true })}
              name={formNames.password}
              error={errors[formNames.password]?.message}
            />
          </Form.Row>
          <Form.Row>
            <Input
              label="Confirm password"
              type="password"
              placeholder="Confirm password"
              innerRef={register({
                required: true,
                validate: value => {
                  const values = getValues();
                  return values.password === value || 'Passwords don`t match';
                },
              })}
              name={formNames.confirm_password}
              error={errors[formNames.confirm_password]?.message}
            />
          </Form.Row>
          <Button variant="primary" type="submit">
            Submit
          </Button>
        </Form>
        <SocialServices className="sign_up__social-services" isLogin={false} />
        <div className="sign_up__login">
          Already have an account? <Link to={userRoutes.login}>Click here to login</Link>
        </div>
      </div>
      <Modal show={showSuccessRegisterModal} close={() => setShowSuccessRegisterModal(false)} headerText="Success">
        <Modal.Body>UAUAU</Modal.Body>
        <Modal.Footer>FFFF</Modal.Footer>
      </Modal>
    </div>
  );
};
