export const Form = ({ onSubmit, children, className = '' }) => {
  return (
    <form onSubmit={onSubmit} className={'form ' + className}>
      {children}
    </form>
  );
};

Form.Row = ({ children, className = '' }) => {
  return <div className={'form-group ' + className}>{children}</div>;
};

Form.Title = ({ children, className = '' }) => <Form.Row className={'form__title' + className}>{children}</Form.Row>;

Form.Label = ({ children }) => <label className="form-label">{children}</label>;
