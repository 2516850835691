import React, { useCallback } from 'react';
import { Button } from '~/common/components/Button/Button';
import { Form } from '~/common/components/Form/Form';
import { Input } from '~/common/components/Input/Input';
import { Checkbox } from '~/common/components/Checkbox/Checkbox';
import { useForm } from 'react-hook-form';
import { Link } from '~/common/components/Link/Link';
import { userRoutes } from '~/modules/user/router/Router';
import { useDispatch, useSelector } from 'react-redux';
import { login } from '~/modules/user/redux/user.actions';
import { SocialServices } from '~/modules/user/components/SocialServices/SocialServices';
import { selectLoginPageDescription } from '~/modules/app/redux/app.selectors';
import { Markdown } from '~/common/components/Markdown/Markdown';
import Logo from '~/common/assets/img/header/logo-black.png';

const formNames = {
  email: 'username',
  password: 'password',
  check_me: 'check_me',
};

export const SignIn = () => {
  const { register, handleSubmit, setError, errors } = useForm();
  const dispatch = useDispatch();
  const descriptionText = useSelector(selectLoginPageDescription);

  const onSubmit = useCallback(
    data => {
      dispatch(login(data)).catch(e => {
        const error = e?.response?.data?.non_field_errors;
        if (error) {
          setError(formNames.email, { message: error[0].message });
        }
      });
    },
    [dispatch, setError]
  );

  return (
    <div className="sign_in">
      <div className="inner">
        <img className='dual-logo' src={ Logo } alt='logo'/>
        <div className="sign_in__text">
          <Markdown>{descriptionText}</Markdown>
        </div>
        <Form onSubmit={handleSubmit(onSubmit)} className="sign_in__form">
          <Form.Row>
            <Input
              label="Email address"
              type="email"
              placeholder="Enter email"
              // smallText="We'll never share your email with anyone else."
              innerRef={register({ required: true })}
              name={formNames.email}
              error={errors[formNames.email]?.message}
            />
          </Form.Row>
          <Form.Row>
            <Input
              label="Password"
              type="password"
              placeholder="Password"
              innerRef={register({ required: true })}
              name={formNames.password}
            />
          </Form.Row>
          <Form.Row>
            <Checkbox text="Check me out" name={formNames.check_me} innerRef={register} />
          </Form.Row>
          <Form.Row>
            <Link to={userRoutes.forgot_password}>Forgot password?</Link>
          </Form.Row>
          <Button variant="primary" type="submit">
            Login
          </Button>
        </Form>
        <SocialServices className="sign_in__social-services" />
        <div className="sign_in__sign_up">
          Don't have an account? <Link to={userRoutes.sign_up}>Click here to sign up</Link>
        </div>
      </div>
    </div>
  );
};
