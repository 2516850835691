// import { useEffect } from 'react';

export const GlobalSpinner = () => {
  /*
  if you need to add class on global loading
  useEffect(() => {
    document.body.classList.add('is-loading');
    return () => {
      document.body.classList.remove('is-loading');
    };
  });
   */

  return (
    <div className="global-spinner">
      <div className="spinner-border text-primary" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );
};
