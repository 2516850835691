export const applicationsRoutes = {
  homepage: '/',
  helppage: '/help',
  // create_mentor_application: '/create/mentor_application',
  create_student_application: '/create/student_application',
  create_ta_application: '/create/ta_application',
  edit_student_application: '/edit/student_application',
  edit_ta_application: '/edit/ta_application',
  // edit_mentor_application: '/edit/mentor_application',
  course: '/course',
};
