import { default as axiosBase } from 'axios';

export const API_BASE = process.env.REACT_APP_API_HOST.toString().replace(/"/g, ``);

export const axios = axiosBase.create({
  baseURL: API_BASE + '/api',
  timeout: 30000,
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    'Content-Type': 'application/json',
  },
});
