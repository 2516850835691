import useCollapse from 'react-collapsed';
import { FaChevronCircleUp } from '@react-icons/all-files/fa/FaChevronCircleUp';
import { FaChevronCircleDown } from '@react-icons/all-files/fa/FaChevronCircleDown';

export const Collapse = (props) => {
    const config = {
        defaultExpanded: props.defaultExpanded || false,
        collapsedHeight: props.collapsedHeight || 0,
    };
    const { getCollapseProps, getToggleProps, isExpanded } = useCollapse(config);
return (
    <div className="collapsible">
        <div className="collapsible-header" {...getToggleProps()}>
            <div className={`title${props.isSml ? "-sml" : ""}`}>{props.title}</div>
            <div className="icon">
              {isExpanded ? <FaChevronCircleUp /> : <FaChevronCircleDown />}
            </div>
        </div>
        <div {...getCollapseProps()}>
            <div className="content">
                {props.children}
            </div>
        </div>
    </div>
    );
}
