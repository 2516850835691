export const flattenObj = (obj, globalKey = '') => {
  let result = {};
  Object.entries(obj).forEach(([key, value]) => {
    const localKey = globalKey ? globalKey + '.' + key : key;
    if (!Array.isArray(value) && typeof value !== 'string') {
      const temp = flattenObj(value, localKey);
      result = { ...result, ...temp };
    } else {
      result[localKey] = value;
    }
  });
  return result;
};

export const setErrorsFunction = setError => errors => {
  if (typeof errors === 'object') {
    const flattenedErrors = flattenObj(errors);
    // remove this field
    delete flattenedErrors['non_field_errors'];
    // delete flattenedErrors['user'];
    Object.entries(flattenedErrors).forEach(([key, value]) => {
      try {
        setError(key, { message: value[0]?.message });
      } catch (_) {}
    });
  }
};

export const createFormData = data => {
  const formData = new FormData();
  Object.entries(data).forEach(([item, value]) => {
    if (!(value instanceof FileList)) {
      formData.set(item, value);
    } else {
      if (value.length > 0) {
        const [file] = value;
        formData.set(item, file, file.name);
      }
    }
  });

  return formData;
};
