import { Layout } from '~/common/components/Layout/Layout';
import { useTitle } from '~/modules/app/models/app.hooks';
import FileViewer from '~/common/components/FileViewer/FileViewer';

export const CourseContentPage = () => {
  useTitle('Course Content');
  return (
    <Layout>
      <FileViewer title='Course Content' />
    </Layout>
  );
};
