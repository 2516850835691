import { SideBar } from '~/common/components/NewLayout/components/SideBar/SideBar';

export const NewLayout = ({children, direction = true}) => {
    return (
        <>
            {direction ?
                <LeftNewLayout>{children}</LeftNewLayout>
                :
                <RightNewLayout>{children}</RightNewLayout>
            }
            <SideBar direction={direction}><p></p></SideBar>
        </>
    )
}

const LeftNewLayout = ({children}) => {
    return (
        <>
        <main  className="content-main__left">
            {children}
        </main>
        </>
    )
}
const RightNewLayout = ({children}) => {
    return (
        <>
            <main  className="content-main__right">
                {children}
            </main>
        </>
    )
}