const getTASeniority = (user) => {
  const highestDegree = user.general_info.highest_degree;
  const status = user.general_info.status;
  const yearsHeld = user.general_info.held_position_time;

  switch(highestDegree){
    // rejected TA application
    case 'no_degree':
      return 'undefined'
    // rejected TA application
    case 'secondary_school':
      return 'undefined'
    case 'undergraduate_degree':
      switch(status){
        case 'masters_student':
          switch(yearsHeld){
            case 'zero_years':
              return 'junior'
            case 'one_year':
              return 'junior'
            case 'two_years':
              return 'junior'
            default:
              return 'intermediate'
            }
        case 'phd_student':
         switch(yearsHeld){
            case 'zero_years':
              return 'junior'
            case 'one_year':
              return 'junior'
            default:
              return 'intermediate'
            }
        case 'lecturer_professor_faculty':
         switch(yearsHeld){
            case 'zero_years':
              return 'undefined'
            case 'one_year':
              return 'junior'
            case 'two_years':
              return 'junior'
            default:
              return 'intermediate'
            }
        case 'industry':
         switch(yearsHeld){
            case 'between_four_and_five_years':
              return 'intermediate'
            case 'more_than_six_years':
              return 'intermediate'
            default:
              return 'junior'
            }
        case 'other':
          return 'undefined'
        default:
          break;
      }
      break;
    case 'masters_degree':
      switch(status){
        case 'phd_student':
          switch(yearsHeld){
            case 'zero_years':
              return 'junior'
            case 'one_year':
              return 'junior'
            case 'more_than_six_years':
              return 'senior'
            // between 2 and 6 years
            default:
              return 'intermediate'
            }
        case 'lecturer_professor_faculty':
          switch(yearsHeld){
            case 'zero_years':
              return 'junior'
            case 'one_year':
              return 'junior'
            case 'more_than_six_years':
              return 'senior'
            // between 2 and 6 years
            default:
              return 'intermediate'
            }
        case 'industry':
          switch(yearsHeld){
            case 'zero_years':
              return 'junior'
            case 'one_year':
              return 'junior'
            case 'more_than_six_years':
              return 'senior'
            // between 2 and 6 years
            default:
              return 'intermediate'
            }
        case 'other':
          return 'undefined'
        default:
          break;
      }
      break;
    case 'doctoral_degree':
      switch(status){
        case 'postdoc':
          switch(yearsHeld){
            case 'between_four_and_five_years':
              return 'senior'
            case 'more_than_six_years':
              return 'senior'
            // less than 4 years
            default:
              return 'intermediate'
          }
        case 'lecturer_professor_faculty':
          return 'senior'
        case 'industry':
          switch(yearsHeld){
            case 'between_four_and_five_years':
              return 'senior'
            case 'more_than_six_years':
              return 'senior'
            // less than 4 years
            default:
              return 'intermediate'
          }
        case 'other':
          return 'undefined'
        default:
          break;
      }
      break;
    // other case
    default:
      switch(status){
        case 'undergraduate_student':
          return 'undefined'
        case 'industry':
          return 'undefined'
        case 'other':
          return 'undefined'
        default:
          return 'undefined'
      }
  }
}

export { getTASeniority }
