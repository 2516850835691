import { Account } from '~/modules/user/pages/Profile/blocks/components/Account/Account';
import { ProfileTabs } from '~/modules/user/pages/Profile/blocks/components/ProfileTabs/ProfileTabs';
import { GeneralInfo } from '~/modules/user/pages/Profile/blocks/components/GeneralInfo/GeneralInfo';
import { Documents } from '~/modules/user/pages/Profile/blocks/components/Documents/Documents';
import { useSelector } from 'react-redux';
import { selectCourses } from '~/modules/applications/redux/applications.selectors';
import { useGetORCIDEducation } from '~/modules/user/models/user.hooks';
import { useEffect, useState } from 'react';

export const Profile = () => {
  const courses = useSelector(selectCourses);
  const [ORCIDEdu, setORCIDEdu] = useState(null);
  const getORCIDEducation = useGetORCIDEducation();
  // State switch to trigger data pull from ORCID
  const [dataChanged, setDataChanged] = useState(false);
  const [certRecordMap, setCertRecordMap] = useState({});

  useEffect(() => {
    getORCIDEducation().then(response => {
      setORCIDEdu(response.data);
    })
  }, [getORCIDEducation, dataChanged]);
  
  // Effect to update certRecordMap when ORCIDEdu changes
  useEffect(() => {
    if (ORCIDEdu) {
      const map = {};
      ORCIDEdu.forEach(certRecord => {
        map[certRecord.cert_id] = certRecord.record_id;
      });
      setCertRecordMap(map);
    }
  }, [ORCIDEdu]);

  return (
    <div className="profile">
      <div className="inner">
        <ProfileTabs>
          <>
            <Account />
            <GeneralInfo courses={courses} />
          </>
          <Documents setDataChanged={setDataChanged} certRecordMap={certRecordMap} />
        </ProfileTabs>
      </div>
    </div>
  );
};
