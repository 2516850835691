import { Link } from '~/common/components/Link/Link';
import Logo from '~/common/assets/img/newLayout/logo.png';
import bannerImage from '~/common/assets/img/newLayout/bannerImageLanding.png';
import invoiceImage from '~/common/assets/img/newLayout/invoiceImage.png';
import { applicationsRoutes } from '~/modules/applications/router/Router';

export const SideBar = ({children, direction = true}) => {
    return (
        <>
            {direction && <RightSideBar></RightSideBar>}
            {!direction && <LeftSideBar></LeftSideBar>}
        </>
    )
}

export const RightSideBar = () => {
    return(
        <>
            <div className= "sidebar-right" >
                <div className="sidebar-right__logo">
                    <Link to={applicationsRoutes.homepage}>
                        <img style={{width: 100, marginRight: 20, paddingRight: 10}} src={Logo} alt="logo" />
                    </Link>
                </div>
                <img src={ bannerImage} className= "sidebar-right__image" alt=""/>
            </div>
        </>
    )
}

export const LeftSideBar = () => {
    return(
        <>
            <div className= "sidebar-left" >
                <div className="sidebar-left__logo">
                    <Link to={applicationsRoutes.homepage}>
                        <img src={Logo} alt="logo" />
                    </Link>
                </div>
                <img src={ invoiceImage} className= "sidebar-left__image" alt=""/>
            </div>
        </>
    )
}
