import { Form } from '~/common/components/Form/Form';
import { Input } from '~/common/components/Input/Input';
import { Button } from '~/common/components/Button/Button';
import UserPlaceholder from '~/common/assets/img/user/user-placeholder.svg';
import { InputFile } from '~/common/components/InputFile/InputFile';
import { useUser, useGetORCID } from '~/modules/user/models/user.hooks';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { createFormData, setErrorsFunction } from '~/common/libs/FormHelpers/FormHelpers';
import { update } from '~/modules/user/redux/user.actions';
import { SocialServices } from '~/modules/user/components/SocialServices/SocialServices';
import { useSnackbar } from '~/modules/app/models/app.hooks';

const formNames = {
  email: 'email',
  first_name: 'first_name',
  last_name: 'last_name',
  password: 'password',
  confirm_password: 'confirm_password',
  profile_image: 'profile_image',
};

export const Account = () => {
  const user = useUser();
  const [selectedPhoto, setSelectedPhoto] = useState(null);
  const { setSnackbar } = useSnackbar();
  const defaultValues = { ...user };
  delete defaultValues[formNames.profile_image];
  const { register, setError, handleSubmit, getValues, trigger, errors } = useForm({ defaultValues });
  const dispatch = useDispatch();
  const setErrors = setErrorsFunction(setError);
  const { ORCID, loading, error } = useGetORCID();

  const clearData = data => {
    const result = {};
    Object.entries(data).forEach(([item, value]) => {
      if (!(typeof value === 'string' && !value)) {
        if (item === formNames.email) {
          if (user[formNames.email] !== value) {
            result[item] = value;
          }
        } else {
          result[item] = value;
        }
      }
    });
    return result;
  };

  const onSubmit = data => {
    const clearedData = clearData(data);
    const formData = createFormData(clearedData);
    dispatch(update(formData))
      .then(() => {
        if (user.email !== data.email) {
          setSnackbar('Profile updated. Please confirm your change of email using your new email address for the changes to take effect.');
        } else if (!(user.first_name === data.first_name && user.last_name === data.last_name && data.password === "")) {
          setSnackbar('Profile has been updated');
        }
      })
      .catch(e => {
        const error = e.response.data;
        setErrors(error);
      });
  };

  const onInputFileChange = () => {
    trigger(formNames.profile_image).then(() => {
      const { profile_image } = getValues();
      if (profile_image.length) {
        const reader = new FileReader();
        reader.onload = e => {
          setSelectedPhoto(e.target.result);
        };
        reader.readAsDataURL(profile_image[0]);
      }
    });
  };
  return (
    <div className="edit-profile">
      <Form className="edit-profile__form" onSubmit={handleSubmit(onSubmit)}>
        <div className="edit-profile__inputs">
          <Form.Title>Edit Profile</Form.Title>
          <Form.Row>
            <Input
              name={formNames.first_name}
              placeholder="First Name"
              label="First Name"
              innerRef={register({ required: true })}
              error={errors[formNames.first_name]?.message}
            />
          </Form.Row>
          <Form.Row>
            <Input
              name={formNames.last_name}
              placeholder="Last Name"
              label="Last Name"
              innerRef={register({ required: true })}
              error={errors[formNames.last_name]?.message}
            />
          </Form.Row>
          <Form.Row>
            <Input
              name={formNames.email}
              placeholder="Email"
              label="Email"
              type="email"
              innerRef={register({ required: true })}
              error={errors[formNames.email]?.message}
            />
          </Form.Row>
          <Form.Row>
            <Input
              name={formNames.password}
              placeholder="Password"
              label="Password"
              type="password"
              innerRef={register}
              error={errors[formNames.password]?.message}
            />
          </Form.Row>
          <Form.Row>
            <Input
              name={formNames.confirm_password}
              placeholder="Confirm Password"
              label="Confirm Password"
              type="password"
              innerRef={register({
                validate: value => {
                  const values = getValues();
                  return values.password === value || 'Passwords don`t match';
                },
              })}
              error={errors[formNames.confirm_password]?.message}
            />
          </Form.Row>
          <Form.Row>
            <Button type="submit" variant="primary">
              Save
            </Button>
          </Form.Row>
        </div>
        <div className="edit-profile__photo">
          <Form.Row className="img-placeholder">
            <img src={selectedPhoto || user[formNames.profile_image] || UserPlaceholder} alt="" />
          </Form.Row>
          <Form.Row>
            <InputFile
              text="Upload"
              name={formNames.profile_image}
              innerRef={register}
              accept=".jpg,.jpeg,.png"
              onChange={onInputFileChange}
            />
          </Form.Row>
          {ORCID && 
          <div _ngcontent-ng-c751883576="" className="container">
            <div _ngcontent-ng-c751883575="" alt="orcid logo"> </div>
            <div _ngcontent-ng-c751883576="" className="text-container">
              <div _ngcontent-ng-c751883576="" dir="ltr" className="id orc-font-body-large">
                <h5 >{ORCID}</h5>
                <p> ORCID</p>
              </div>
            </div>
          </div>}
          <SocialServices 
            withText={false}
            getTextButtonFn={name => 'Connect to ' + name} 
            isConnect={true}
          />
        </div>
      </Form>
    </div>
  );
};


