import { Link as NavLink } from 'react-router-dom';

export const Link = ({ children, className = '', to, onClick, innerRef, isCrossDomain, newTab }) => {
  const props = {
    className: 'link ' + className,
    onClick,
    ref: innerRef,
    target: newTab && '_blank',
  };
  return isCrossDomain ? (
    <a href={to} {...props}>
      {children}
    </a>
  ) : (
    <NavLink to={to} {...props}>
      {children}
    </NavLink>
  );
};
