import { Block } from '~/common/components/Block/Block';
import { Form } from '~/common/components/Form/Form';
import { Controller, useForm } from 'react-hook-form';
import { Button } from '~/common/components/Button/Button';
import { useEffect } from 'react';
import { Select } from '~/common/components/Select/Select';
import { useGetStudentApplication } from '~/modules/applications/models/application.hooks';
import { baselinePrice, calculateAcademicDiscount, calculateCOLAAdjustment, calculateSelfFundedDiscount, calculateAffordabilityDiscount, calculatePayment } from '~/modules/applications/models/application.model';
import { useDispatch } from 'react-redux';
import { payStudentApplication } from '~/modules/stripe/redux/stripe.actions';
import { loadStripe } from '@stripe/stripe-js';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { scrollToTop } from '~/modules/app/models/app.model';
import {
  yesOrNoPairings,
  yesOrNo
} from '~/modules/applications/models/application.model';

dayjs.extend(utc);

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const formNames = {
    affordability_discount : 'affordability_discount',
}

export const StudentPayAffordabilityDiscount = ({ application, previousPage, nextPage, defaultValues}) => {

  const { register, handleSubmit, errors, watch, control } = useForm({ defaultValues });
  const studentApplication = useGetStudentApplication(application.student_application)?.data;
  const affordabilityValue = watch(formNames.affordability_discount);

  useEffect(() => {
    register({ name: formNames.affordability_discount });
  }, [register]);

  const dispatch = useDispatch();

  const isFunded = false

  const isAcademia = studentApplication && !!(studentApplication.is_industry ? 0 : 1)
  const academicDiscount = studentApplication && calculateAcademicDiscount(studentApplication.is_industry)
  const colaAdjustment = studentApplication && calculateCOLAAdjustment(studentApplication.cola, studentApplication.is_industry)
  const residingCountry = studentApplication && studentApplication.residing_country_during_course
  const selfFundedDiscount = studentApplication && calculateSelfFundedDiscount(isFunded, studentApplication.cola, studentApplication.is_industry)
  const affordabilityDiscount = studentApplication && calculateAffordabilityDiscount(isFunded, studentApplication.cola, studentApplication.is_industry)
  const recommendedPayment = (baselinePrice - academicDiscount - colaAdjustment - selfFundedDiscount - affordabilityDiscount).toFixed(2);

  const onSubmit = data => {
    const locationUrl = window.location.href;

    const price = Math.floor(recommendedPayment * 100);
    dispatch(
      payStudentApplication({
        courseApplication: application.id,
        amount: price,
        successUrl: locationUrl,
        cancelUrl: locationUrl,
        isFunded,
        recommendedPayment: Math.floor(recommendedPayment * 100),
      })
    ).then(async response => {
      const sessionId = response.data?.session_id;
      const stripe = await stripePromise;
      stripe.redirectToCheckout({
        sessionId,
      });
    });
  };

  return (
    <>
      <Block title="Enrollment: Affordability Discount" className="student-payment">
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Row>
          <Button className="student-application-status__go-back" onClick={() => {previousPage()}}>
            Go Back
          </Button>
          </Form.Row>
          <Form.Row>
          We understand that cost of living adjustments, institutional rates, and adjustments for self-funded students may not always be enough to account for every person's unique situation. That said, we try to support our Teaching Assistants as well as possible.
          </Form.Row>
          <Form.Row>
          If you are being partially refunded, click No then “I can’t comfortably afford this price” and you will be able to enter an amount on the following page.  If you can contribute more on top of the amount you are being reimbursed for, please do so.
          </Form.Row>
          <Form.Row>
            <Controller
                name={formNames.affordability_discount}
                control={control}
                rules={{ required: true }}
                render={({ onChange, value, name, ref }) => (
                    <Select
                        inputRef={ref}
                        label="Are you able to pay with a 50% affordability discount?"
                        options={yesOrNo}
                        value={yesOrNo.find(c => c.value === value)}
                        onChange={val => onChange(val.value)}
                    />
                )}
              />
              {errors[formNames.affordability_discount] && errors[formNames.affordability_discount]?.type === "required" ? (
                <div className="error">
                    {"Must select one option"}
                </div>
              ) : null}
           </Form.Row>
          <Form.Row className="student-application-status__discount-table">
            <center>
            <table   className="student-application-status__discount-table" cellPadding="0" cellSpacing="0">
              <tbody>
              <tr>
                <td>Baseline Price</td>
                <td className="alignright">  $ {baselinePrice}</td>
              </tr>
              {isAcademia && (<tr>
                <td>Academic Discount</td>
                <td className="alignright">- $ {academicDiscount}</td>
              </tr>)}
              <tr>
                <td>Cost of Living Adjustment ({residingCountry})</td>
                <td className="alignright">- $ {colaAdjustment}</td>
              </tr>
              {!isFunded && (<tr>
                <td>Self-funded discount</td>
                <td className="alignright">- $ {selfFundedDiscount}</td>
              </tr>)}
              <tr>
                <td>Affordability discount</td>
                <td className="alignright">- $ {affordabilityDiscount}</td>
              </tr>
              <tr className="total">
                <td className="alignright" width="80%"><b>Your Total</b></td>
                <td className="alignright"><b>$ {recommendedPayment} (USD)</b></td>
              </tr>
              </tbody>
            </table>
              </center>
            </Form.Row>
          <Form.Row className="student-application-status__pay-button">
            <Button
              type="submit"
            className="student-application-status__pay-button">
              Pay now
            </Button>
          </Form.Row>
          {affordabilityValue === yesOrNoPairings.no && (<Form.Row className="student-application-status__buttons">
            <Button className="student-application-status__buttons" onClick={() => {nextPage(); scrollToTop();}}>
              I can't comfortably afford this price
            </Button>
          </Form.Row>)}
        </Form>
      </Block>
    </>
  );
};
