import { Link } from '~/common/components/Link/Link';
import { useRef, useState } from 'react';
import { ReactComponent as Arrow } from '~/common/assets/img/header/arrow.svg';
import { useClickOutside } from '~/common/hooks';

export const LinkWithSubmenu = ({ text = '', children }) => {
  const [showSubmenu, setShowSubmenu] = useState(false);
  const ref = useRef();
  useClickOutside(ref, () => setShowSubmenu(false));
  const toggleMenu = e => {
    e?.preventDefault();
    setShowSubmenu(prev => !prev);
  };
  return (
    <div className={'link--with-submenu ' + (showSubmenu ? 'active' : '')}>
      <Link to="#" className="header__link link--with-submenu" onClick={toggleMenu} innerRef={ref}>
        {text}
        <Arrow />
      </Link>
      <div className="submenu">
        <div className="submenu__item">{children}</div>
      </div>
    </div>
  );
};
