import { SignUp } from '~/modules/user/pages/SignUp/blocks/SignUp';
import { Layout } from '~/common/components/Layout/Layout';
import { useTitle } from '~/modules/app/models/app.hooks';

export const SignUpPage = () => {
  useTitle('Sign Up');
  return (
    <Layout withFooter={true}>
      <SignUp />
    </Layout>
  );
};
