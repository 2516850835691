import { applicationRequestStatuses } from '~/modules/applications/models/application.model';
import { StudentWishToEnroll } from '~/modules/applications/pages/CourseDashboardPage/blocks/components/StudentWishToEnroll/StudentWishToEnroll';
import { StudentPay } from '~/modules/applications/pages/CourseDashboardPage/blocks/components/StudentPay/StudentPay';
import { StudentPaySelfFundedDiscount } from '~/modules/applications/pages/CourseDashboardPage/blocks/components/StudentPaySelfFundedDiscount/StudentPaySelfFundedDiscount';
import { StudentPayAffordabilityDiscount } from '~/modules/applications/pages/CourseDashboardPage/blocks/components/StudentPayAffordabilityDiscount/StudentPayAffordabilityDiscount';
import { WaiveFeeModal } from '~/modules/applications/pages/CourseDashboardPage/blocks/components/WaiveFeeModal/WaiveFeeModal';
import { StudentSupplementalEnrollment } from '~/modules/applications/pages/CourseDashboardPage/blocks/components/StudentSupplementalEnrollment/StudentSupplementalEnrollment';
import { useState } from 'react';

const pages = {
  intro: 'intro',
  supplemental_form: 'supplemental_form',
  pay: 'pay',
  self_funded_pay: 'self_funded_pay',
  affordability_discount: 'affordability_discount',
  waive_fee: 'waive_fee'
};

export const StudentApplicationStatus = ({ application }) => {
  const isPayOrWaive = application.status === applicationRequestStatuses.accepted
  const [page, setPage] = useState(pages.intro);

  return (
    <>
      {isPayOrWaive && page === pages.intro && <StudentWishToEnroll application={application} nextPage={() => {setPage(pages.supplemental_form)}}/>}
      {isPayOrWaive  && page === pages.supplemental_form && <StudentSupplementalEnrollment application={application} previousPage={() => {setPage(pages.intro)}} nextPage={() => {setPage(pages.pay)}} />}
      {isPayOrWaive && page === pages.pay && <StudentPay application={application} previousPage={() => {setPage(pages.supplemental_form)}} nextPage={() => {setPage(pages.self_funded_pay)}} waiveFee={() => {setPage(pages.waive_fee)}}/>}
      {isPayOrWaive && page === pages.self_funded_pay && <StudentPaySelfFundedDiscount application={application} previousPage={() => {setPage(pages.pay)}} nextPage={() => {setPage(pages.affordability_discount)}}/>}
      {isPayOrWaive && page === pages.affordability_discount && <StudentPayAffordabilityDiscount application={application} previousPage={() => {setPage(pages.self_funded_pay)}} nextPage={() => {setPage(pages.waive_fee)}}/>}
      {isPayOrWaive && page === pages.waive_fee && <WaiveFeeModal application={application} previousPage={() => {setPage(pages.affordability_discount )}}/>}
      </>
  );
};
