import { useMemo } from 'react';

const variants = {
  // primary: 'btn-primary',
};

const generateClassName = (variant, className) => {
  let result = 'button btn ';
  if (variant && variants[variant]) {
    result += variants[variant] + ' ';
  }
  if (className) {
    result += className + ' ';
  }
  return result;
};

export const Button = ({ type = 'button', children, className, variant, onClick, disabled = false }) => {
  const resultClassName = useMemo(() => {
    return generateClassName(variant, className);
  }, [variant, className]);
  const handleButtonClick = (event) => {
    if (onClick) {
      onClick(event);
    }
    event.currentTarget.blur();
  };

  return (
    <button type={type} className={resultClassName} onClick={handleButtonClick} disabled={disabled}>
      {children}
    </button>
  );
};
