import { Layout } from '~/common/components/Layout/Layout';
import { Profile } from '~/modules/user/pages/Profile/blocks/Profile';
import { useTitle } from '~/modules/app/models/app.hooks';

export const ProfilePage = () => {
  useTitle('Account');
  return (
    <Layout>
      <Profile />
    </Layout>
  );
};
