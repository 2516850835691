export const APPLICATIONS_STORE_KEY = 'APPLICATIONS';

const getActionGroupName = name => {
  return APPLICATIONS_STORE_KEY + '_' + name;
};

export const ApplicationsSliceNames = {
  courses: 'courses',
  datasets: 'datasets',
  applications: 'applications',
};

export const ApplicationsActionGroups = {
  [ApplicationsSliceNames.courses]: getActionGroupName(ApplicationsSliceNames.courses),
  [ApplicationsSliceNames.datasets]: getActionGroupName(ApplicationsSliceNames.datasets),
  [ApplicationsSliceNames.applications]: getActionGroupName(ApplicationsSliceNames.applications),
};
