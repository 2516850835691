import { Layout } from '~/common/components/Layout/Layout';
import { CreateMentorApplication } from '~/modules/applications/pages/CreateMentorApplication/blocks/CreateMentorApplication';

export const CreateMentorApplicationsPage = () => {
  return (
    <Layout>
      <CreateMentorApplication />
    </Layout>
  );
};
