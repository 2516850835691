const generateClassName = (error, className) => {
  let result = 'form-control ';
  if (error) {
    result += 'is-invalid ';
  }
  if (className) {
    result += className;
  }
  return result;
};

export const Input = ({
  innerRef,
  className,
  label,
  type = 'text',
  placeholder,
  smallText,
  name,
  defaultValue,
  error,
  onChange,
  ...rest
}) => {
  const resultClassName = generateClassName(error, className);
  return (
    <div className={'input-field ' + (error ? 'input-field--with-error' : '')}>
      {label && <label className="form-label">{label}</label>}
      <input
        type={type}
        ref={innerRef}
        className={resultClassName}
        placeholder={placeholder}
        name={name}
        defaultValue={defaultValue}
        onChange={onChange}
        {...rest}
      />
      {error && <div className="invalid-feedback">{error}</div>}
      {smallText && (
        <>
          <small className="text-muted form-text">{smallText}</small>
        </>
      )}
    </div>
  );
};
