export const applicationStatuses = {
    can_be_sent: 'can_be_sent',
    deadline_ended: 'deadline_ended',
    enrolled_deadline_ended: 'enrolled_deadline_ended',
    profile_not_filled: 'profile_not_filled',
    profile_not_updated: 'profile_not_updated',
    application_already_sended: 'application_already_sended',
    accepted_other_application: 'accepted_other_application',
}

export const applicationTypes = {
    mentor: 'mentor',
    ta: 'ta',
    student: 'student',
}

export const taForTypes = {
    no_preference: 'no_preference',
    regular_TA: 'regular_TA',
    project_TA: 'project_TA',
}

export const priorNMAExperienceOptions = {
    student_CN_2020: 'student_CN_2020',
    student_CN_2021: 'student_CN_2021',
    student_DL_2021: 'student_DL_2021',
    student_CN_2022: 'student_CN_2022',
    student_DL_2022: 'student_DL_2022',
    student_CN_2023: 'student_CN_2023',
    student_DL_2023: 'student_DL_2023',
    student_CS_2023: 'student_CS_2023',
    TA_CN_2020: 'TA_CN_2020',
    TA_CN_2021: 'TA_CN_2021',
    TA_DL_2021: 'TA_DL_2021',
    TA_CN_2022: 'TA_CN_2022',
    TA_DL_2022: 'TA_DL_2022',
    TA_CN_2023: 'TA_CN_2023',
    TA_DL_2023: 'TA_DL_2023',
    TA_CS_2023: 'TA_CS_2023',
    have_not_participated: 'have_not_participated',
}

export const priorNMAExperienceOptionsLabels = {
    [priorNMAExperienceOptions.student_CN_2020]: 'Student in Computational Neuroscience 2020',
    [priorNMAExperienceOptions.student_CN_2021]: 'Student in Computational Neuroscience 2021',
    [priorNMAExperienceOptions.student_DL_2021]: 'Student in Deep Learning 2021',
    [priorNMAExperienceOptions.student_CN_2022]: 'Student in Computational Neuroscience 2022',
    [priorNMAExperienceOptions.student_DL_2022]: 'Student in Deep Learning 2022',
    [priorNMAExperienceOptions.student_CN_2023]: 'Student in Computational Neuroscience 2023',
    [priorNMAExperienceOptions.student_DL_2023]: 'Student in Deep Learning 2023',
    [priorNMAExperienceOptions.student_CS_2023]: 'Student in Computational Tools for Climate Science 2023',
    [priorNMAExperienceOptions.TA_CN_2020]: 'TA in Computational Neuroscience 2020',
    [priorNMAExperienceOptions.TA_CN_2021]: 'TA in Computational Neuroscience 2021',
    [priorNMAExperienceOptions.TA_DL_2021]: 'TA in Deep Learning 2021',
    [priorNMAExperienceOptions.TA_CN_2022]: 'TA in Computational Neuroscience 2022',
    [priorNMAExperienceOptions.TA_DL_2022]: 'TA in Deep Learning 2022',
    [priorNMAExperienceOptions.TA_CN_2023]: 'TA in Computational Neuroscience 2023',
    [priorNMAExperienceOptions.TA_DL_2023]: 'TA in Deep Learning 2023',
    [priorNMAExperienceOptions.TA_CS_2023]: 'TA in Computational Tools for Climate Science 2023',
    [priorNMAExperienceOptions.have_not_participated]: 'I have not participated in previous years',
}
export const contestedAreas = {
    ukraine: 'Ukraine',
}

export const applicationTypesLabels = {
    [applicationTypes.mentor]: 'Mentor',
    [applicationTypes.ta]: 'TA',
    [applicationTypes.student]: 'Student',
}

export const applicationRequestStatuses = {
    applied: 'applied',
    accepted: 'accepted',
    rejected: 'rejected',
    paid: 'paid',
    waived_fee: 'waived_fee',
    matched: 'matched',
    declined: 'declined',
    dismissed: 'dismissed',
    approved: 'approved',
    waitlist: 'waitlist',
    dropped_out: 'dropped_out',
}

export const certificateTypes = {
    completed_the_course: 'completed_the_course',
    completed_the_course_and_project: 'completed_the_course_and_project',
}

export const applicationRequestStatusesLabels = {
    [applicationRequestStatuses.applied]: 'Applied',
    [applicationRequestStatuses.accepted]: 'Accepted',
    [applicationRequestStatuses.rejected]: 'Rejected',
    [applicationRequestStatuses.paid]: 'Paid',
    [applicationRequestStatuses.waived_fee]: 'Waived fee',
    [applicationRequestStatuses.matched]: 'Matched',
    [applicationRequestStatuses.declined]: 'Declined',
    [applicationRequestStatuses.dismissed]: 'Dismissed',
    [applicationRequestStatuses.approved]: 'Approved',
    [applicationRequestStatuses.waitlist]: 'Waiting list',
    [applicationRequestStatuses.dropped_out]: 'Dropped Out'
}

export const applicationStatusesLabels = {
    [applicationStatuses.deadline_ended]: 'Application deadline has passed',
    [applicationStatuses.enrolled_deadline_ended]: 'Enrollment deadline has passed',
    [applicationStatuses.profile_not_filled]: 'Please fill out your profile/logistics first',
    [applicationStatuses.profile_not_updated]: 'Please update your profile/logistics first',
    [applicationStatuses.application_already_sended]: 'Application is already sent',
    [applicationStatuses.accepted_other_application]: 'Other application is already accepted',
}

export const googleColabOptions = [
    { label: 'Yes', value: true },
    { label: 'No', value: false },
]

export const yesOrNoOptions = [
    { label: 'Yes', value: true },
    { label: 'No', value: false },
]

export const yesOrNoPairings = {
    yes: 'Yes',
    no: 'No',
}

export const yesOrNo = [
    { label: 'Yes', value: yesOrNoPairings.yes },
    { label: 'No', value: yesOrNoPairings.no },
]

export const programProficiencyOptions = [
    { label: 'have not touched it', value: '0' },
    { label: 'learned some, have edited scripts', value: '1' },
    { label: 'have written my own chunks of code', value: '2' },
    { label: 'have written my own complete programs', value: '3' },
    { label: 'am completely comfortable writing programs', value: '4' },
]

export const experienceOptions = [
    { label: 'have read about it a bit', value: '0' },
    { label: 'have taken an introductory course', value: '1' },
    { label: 'have taken several courses', value: '2' },
    { label: 'have taken courses and am studying it', value: '3' },
    { label: 'extensive experience for 4+ years', value: '4' },
]

export const internetQualityOptions = [
    { label: 'Yes, I have access to stable internet at home or somewhere else', value: true },
    { label: 'No, my internet access is unreliable', value: false },
]

export const preferBeingTAForOptions = [
    { label: 'No Preference', value: 'no_preference' },
    { label: 'Regular TA', value: 'regular_TA' },
    { label: 'Project TA', value: 'project_TA' },
]

export const lowfiTAOptions = [
    { label: 'No', value: 'no' },
    { label: 'Yes but prefer not to', value: 'yes_but_prefer_not_to' },
    { label: 'Yes prefer it', value: 'yes_prefer_it' },
]

export const lowfiStudentOptions = [
    { label: "No, I'm not interested in the low-fi track", value: 'no' },
    { label: "I would prefer to be put in the low-fi track, but would still be able to participate in the course through it's regular synchronous format", value: 'no_but_prefer_it' },
    { label: 'I will only be able to participate in the course if I am in the low-fi track', value: 'yes_only' },
]

export const yearsOfCourseResearchOptions = [
    { label: '0', value: 'zero_years' },
    { label: '1', value: 'one_year' },
    { label: '2-3', value: 'between_two_and_three_years' },
    { label: '4-5', value: 'between_four_and_five_years' },
    { label: '6+', value: 'more_than_six_years' },
]

export const getDatasetsOptions = (datasets = []) => datasets.map(set => ({ label: set, value: set }))

export const funding = {
    yes_funded: 'yes_funded',
    yes_denied: 'yes_denied',
    self_funded: 'self_funded',
}

export const fundingOptions = [
    { label: 'Yes, my manager/PI/institution will pay or reimburse me for this amount', value: funding.yes_funded },
    { label: 'Yes, but the request was denied', value: funding.yes_denied },
    { label: 'I am paying', value: funding.self_funded },
]
export let baselinePrice = 2090
// changed from const to let and introduced the set func for climate
export const setBaselinePrice = newPrice => (baselinePrice = newPrice)

export const calculateAcademicDiscount = (isIndustry) => {
    const academicDiscount = Math.floor(1200/2090 * baselinePrice);
    return academicDiscount * (isIndustry ? 0 : 1)
}

export const calculateCOLAAdjustment = (cola, isIndustry) => {
    const academicDiscount = calculateAcademicDiscount(isIndustry)
    return ((baselinePrice - academicDiscount) * (1 - cola)).toFixed(2)
}

export const calculateSelfFundedDiscount = (isFunded, cola, isIndustry) => {
    const academicDiscount = calculateAcademicDiscount(isIndustry)
    const colaAdjustment = calculateCOLAAdjustment(cola, isIndustry)
    return ((baselinePrice - academicDiscount - colaAdjustment) * (0.5 * !isFunded)).toFixed(2)
}

export const calculateAffordabilityDiscount = (isFunded, cola, isIndustry) => {
    const academicDiscount = calculateAcademicDiscount(isIndustry)
    const colaAdjustment = calculateCOLAAdjustment(cola, isIndustry)
    const selfFundedDiscount = calculateSelfFundedDiscount(isFunded, cola, isIndustry)
    return ((baselinePrice - academicDiscount - colaAdjustment - selfFundedDiscount) * 0.5).toFixed(2)
}

// Financial

export const citizenship = {
    us_citizen: 'us_citizen',
    us_resident_alien: 'us_resident_alien',
    foreign_national: 'foreign_national',
    no_payment: 'no_payment',
}

export const citizenshipOptions = [
    { label: 'United States citizen', value: citizenship.us_citizen },
    { label: 'United States resident alien', value: citizenship.us_resident_alien },
    { label: 'Nonresident alien of the United States (foreign national)', value: citizenship.foreign_national },
    { label: 'Other/no payment (please explain below)', value: citizenship.no_payment },
]

export const researchConsent = {
    transcript_and_data: 'transcript_and_data',
    data_only: 'data_only',
    do_no_consent: 'do_no_consent',
}

export const researchConsentOptions = [
    { label: 'I agree to share anonymized survey & zoom transcript data', value: researchConsent.transcript_and_data },
    { label: 'I agree to share anonymized survey data only', value: researchConsent.data_only },
    {
        label: 'I do NOT consent to sharing ANY anonymized data outside Neuromatch',
        value: researchConsent.do_no_consent,
    },
]

export const visa = {
    f: 'f',
    j: 'j',
    m: 'm',
    q: 'q',
    other: 'other',
}

export const visaOptions = [
    { label: 'F', value: visa.f },
    { label: 'J', value: visa.j },
    { label: 'M', value: visa.m },
    { label: 'Q', value: visa.q },
    { label: 'Other (please describe)', value: visa.other },
]

export const visaElligability = {
    yes_have_opt_or_cpt: 'yes_have_opt_or_cpt',
    yes_intent_opt_or_cpt: 'yes_intent_opt_or_cpt',
    no: 'no',
}

export const visaElligabilityOptions = [
    { label: 'Yes, I already have OPT or CPT', value: visaElligability.yes_have_opt_or_cpt },
    { label: 'Yes, I intend to apply to apply for OPT or CPT', value: visaElligability.yes_intent_opt_or_cpt },
    { label: 'No', value: visaElligability.no },
]

export const paymentPartner = {
    volunteer: 'volunteer',
    partner_organization: 'partner_organization',
    unique_circumstance: 'unique_circumstance',
}

export const paymentPartnerOptions = [
    { label: 'I will volunteer and receive no payment', value: paymentPartner.volunteer },
    { label: 'I want to explore being paid by a partner organization', value: paymentPartner.partner_organization },
    {
        label: 'I have a unique circumstance to discuss (explain in Other Info below)',
        value: paymentPartner.unique_circumstance,
    },
]
