import { ReduxController } from '~/common/libs/ReduxController/ReduxController';
import { UserActionGroups, UserSliceNames } from '~/modules/user/redux/user.store';

const initialState = {
  [UserSliceNames.user]: ReduxController.createState(null),
};

export const userReducer = ReduxController.createReducer(initialState, {
  ...ReduxController.createHandlers(UserActionGroups[UserSliceNames.user], UserSliceNames.user)
});
