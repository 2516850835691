import { ReduxController } from '~/common/libs/ReduxController/ReduxController';
import { axios } from '~/app/axios/axios';

export const payStudentApplication = ({
  courseApplication,
  amount,
  successUrl,
  cancelUrl,
  isFunded,
  recommendedPayment,
}) =>
  ReduxController.createAction({
    callApi: () =>
      axios.post('/payments/session/', {
        course_application: courseApplication,
        amount,
        success_url: successUrl,
        cancel_url: cancelUrl,
        is_funded: isFunded,
        recomendation_amount: recommendedPayment,
      }),
    withGlobalLoading: true,
  });
