import { Layout } from '~/common/components/Layout/Layout';
import { EditMentorApplication } from '~/modules/applications/pages/EditMentorApplication/blocks/EditMentorApplication';

export const EditMentorApplicationPage = () => {
  return (
    <Layout>
      <EditMentorApplication />
    </Layout>
  );
};
