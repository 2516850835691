import { LogisticsInfo } from '~/modules/user/pages/Logistics/blocks/components/LogisticsInfo/LogisticsInfo';
import { useSelector } from 'react-redux';
import { selectCourses } from '~/modules/applications/redux/applications.selectors';

export const UserLogistics = () => {
  const courses = useSelector(selectCourses);
  return (
    <div className="logistics">
      <div className="inner">
        <LogisticsInfo courses={courses} />
      </div>
    </div>
  );
};
