import { useSelector } from 'react-redux';
import { selectCourses } from '~/modules/applications/redux/applications.selectors';
import { CourseBlock } from '~/modules/applications/pages/Home/blocks/components/CourseBlock/CourseBlock';
import React, { createRef, useRef, useState, useEffect } from "react";
import { Button } from '~/common/components/Button/Button';
import { Col } from 'react-bootstrap';
import { Collapse } from '~/common/components/Collapse/Collapse';
import { Link } from '~/common/components/Link/Link';

export const CoursesBlocks = (props) => {
  const courses = useSelector(selectCourses);
  // React ref to store array of refs
  const scrollRefs = useRef([]);
  // Populate scrollable refs, only create them once
  // if the selectedElements array length is expected to change there is a workaround
  scrollRefs.current = [...Array(courses.length).keys()].map(
    (_, i) => scrollRefs.current[i] ?? createRef()
  );

  // Curried handler to take index and return click handler
  const scrollSmoothHandler = (index) => () => {
    scrollRefs.current[index].current.scrollIntoView({ behavior: "smooth", block: "start"});
  };

  return (
    <div>
      {/* <Collapse title='Courses' defaultExpanded={true}> */}
          <div className="courses-blocks">
              <div className="inner">
                  {courses.map((course, i) => (
                  <div
                  key={i}
                  ref={scrollRefs.current[i]} // <-- pass scroll ref @ index i
                  // style={{paddingTop : '25px'}}
                >
                <CourseBlock course={course} key={course.id} withButtons={props.withButtons} />
                <br></br>
                </div>
                ))}
              </div>
          </div>
      {/* </Collapse> */}
    </div>
  );
};
