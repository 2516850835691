const GeneralHelpPageData = [
  {
    title: 'General Information',
    content: [
      {
        q: "How do students get grouped into teams or 'pods'?",
        a:
          'We use an algorithm \\[[1](https://journals.plos.org/plosone/article?id=10.1371/journal.pone.0158423), [2](https://elifesciences.org/articles/57892), [3](https://github.com/titipata/paper-reviewer-matcher)] to place people in complementary groups based on common interests, time availability, seniority, and in many cases shared language. This will allow students to meet online at a time and context that is most convenient for them.',
      },
      {
        q: 'What time zone will the course take place in?',
        a:
          "We offer 5 time slots to accommodate participants globally. You can see which time slot is best for you [here](https://neuromatchacademy.github.io/widgets/tz.html). After you've been matched to your pod, you'll be able to see your pods time slot in the portal.",
      },
      {
        q: "How do I know what pod I'm in and who my TA is/who my students are?",
        a:
          "After you've enrolled in the course, we complete our matching step to create our pods. You will receive an email when matching in complete. In the portal you'll be able to see your pod name, TA name and contact info or students names and contact info, your project TA, your dataset, pod language, and Zoom link.\n\n",
        img_path: "images/pods-blur.png",
      },
      {
        q: 'Will the courses be in any languages other than English?',
        a:
          'The main language of instruction will be English, and all materials will be in English. In addition, many of our students will be grouped into pods where the TA and students all share another language.\n\nLanguage pods are subject to availability and cannot be guaranteed.',
      },
      {
        q: 'How long can I access the lectures and materials after school?',
        a:
          'All of our course content is free and open access online, on GitHub, and YouTube. You can find them here: [neuromatch.io/open-education-resources/](https://neuromatch.io/open-education-resources/).',
      },
      {
        q: 'Do I need a letter of reference to apply?',
        a: 'Nope!',
      },
      {
        q: 'Can Iranian residents participate in NMA?',
        a:
          'Yes! Iranian residents can apply to be either a student or a TA. Since the founding of our courses in 2020, we have prioritized global inclusion and have received a sanctions exceptions for all our courses that allows us to run our course in Iran.',
      },
      {
        q: 'What if I know at the time of application that I have conflicting work, study, or conference commitments?',
        a:
          'Students or TAs who know in advance that they will need to miss parts of the course should refrain from applying and plan ahead for a future iteration of the course.',
      },
    ],
  },
  {
    title: 'Student Tuition Fees & Payment',
    content: [
      {
        q: 'What does it cost to be a student? Can I receive a full tuition waiver?',
        a:
          'Our tuition fee is adjusted for cost of living and if you have access to financial support from your institution. You can see the recommended cost of the course here:\n\n[https://neuromatchacademy.github.io/widgets/cola.html](https://neuromatchacademy.github.io/widgets/cola.html)\n\n-   A full tuition fee waiver can also be available for those who cannot have their fee covered by either their institution, company, or lab and cannot pay on their own. Thus, if you have been accepted into the Academy you are guaranteed to be able to participate regardless of your funding status!\n-   As a reminder, tuition fees from students allow us to fairly compensate our TAs and maintain the sustainability of our program. If your company, supervisor, or university can reimburse you, please help us support your TA by paying the personalized recommended fee in your portal account. This course is an investment in your future!',
      },
      {
        q: 'Can I directly pay by invoice via university?',
        a:
          'No. Due to the complexity of processing payment and overseas transfers, technical details of linking wire transfer payments to your account, and delays in university payment processing, we cannot accommodate requests to pay by direct invoice to your university. Please pay your fee by credit card in your student portal. You will receive a receipt. You can ask your supervisor, university, or company to reimburse you according to your local policies.',
      },
      {
        q: 'What if I drop out of the academy?',
        a:
          'Dropping out of the academy makes it harder for your pod to work well because there will only be a small number of people at the start of the academy. You will also not receive your certificate of completion. Of course we understand that unforeseen circumstances can arise and will accommodate those as needed, but please do plan to stay through to the end.',
      },
      {
        q: "What if I can't attend the whole school?",
        a:
          "Our courses' content is rigorous and cumulative which makes it difficult to keep up if you miss days. Therefore we expect students to be present for the whole program.\n\n-   In case of an emergency, students should let their TA know as soon as possible. In such cases 1-2 days absence may be permitted. Low attendance may lead to no certificate being issued after the school concludes. Please see FAQ section 'Certificates' for more information",
      },
      {
        q: "What's the refund policy if I can no longer attend the course?",
        a:
          '-   Decisions about refunds are made on a case-by-case basis and cannot be guaranteed. We hire and commit to paying TAs based on the number of students signed up for each course; therefore, in general, we do not provide refunds.\n-   For extenuating or extreme circumstances, please fill out a refund request:\n    -   [Refund Request Form](https://airtable.com/appnUefywiVyvFZAj/shrM1IFWGTGqEmyG2)\n-   If you have additional questions, please email [nma@neuromatch.io](mailto:nma@neuromatch.io)',
      },
    ],
  },
  {
    title: 'Application Portal Questions',
    content: [
      {
        q: "I have logged in but cannot see the applications, what's wrong?",
        a:
          'You have to complete your profile in the upper right before you begin. There is also an email verification that you need to complete before your account is active.',
      },
      {
        q: 'I am having trouble using the Google Login button.',
        a:
          'Some browsers report difficulty using this. Try using a private or incognito browser tab if you run into this.',
      },
      {
        q: 'I am having trouble creating an application from a mobile browser.',
        a:
          'We have not been able to test our application on mobile browsers. Please try to use a computer with a normal display instead.',
      },
      {
        q: 'I need to change my time slot or location. Can I do this?',
        a:
          'Your time slot preference and location information can be updated until the application is closed as we take this into consideration for matching students and TAs.\n\n- If your time slot preference is wrong after the field has been locked, please immediately email [nma@neuromatch.io](mailto:nma@neuromatch.io). Changing this after matching is complete is very hard.',
      },
      {
        q: 'Will extra info be given to unsuccessful candidates about what is missing from their application?',
        a: 'Unfortunately we will not be able to give personalized feedback to applicants.',
      },
      {
        q: 'How do I check if my application was submitted successfully?',
        a:
          'Log in to your portal account to check the status of your application. On the homepage, you’ll be able to see the following details: course name, application date, role (student or TA), and status. If you have successfully applied to a course, the status will be shown as “Applied”. See the example [here](https://drive.google.com/file/d/1hVBZ4d0easnhnQhZTLDF2ri08zdl_Llz/view?usp=sharing). If you wish to withdraw from the course, click on “Withdraw” for the corresponding course.\n\n',
        img_path: "images/faq_how_to_check_applied.png",
      },
    ],
  },
  {
    title: 'Student Applications',
    content: [
      {
        q: "Do I have to have a Google Scholar account to apply? (I don't have any publications yet)",
        a:
          "You don't have to have a Google Scholar account, but if you do, sharing it will be helpful for us to evaluate the courses' long-term impact and raise funding for future years. Note that you don't need to have any publications to apply to a course or to create a Google Scholar profile.",
      },
      {
        q: 'If I decline my placement this year, can you defer my application to next year?',
        a: 'No, this is not possible, you will need to reapply.',
      },
    ],
  },
  {
    title: 'TA Applications & Payment',
    content: [
      {
        q: 'How will my TA payment be taxed?',
        a:
          'You will be paid as an independent contractor from within the United States. This may have tax implications depending on where you live and other factors. You will ultimately be responsible for understanding the tax implications for your own unique situation, but we have created this survey to help you understand. This is only a demonstration form so please do not submit a response to it. If accepted as a TA, you would fill out a similar form and upload the recommended documents to give us your financial, work visa, and other relevant details: [Financial Demo Form](https://airtable.com/shrKgAKus5aGrcdmn).\n\nWe recommend you speak with a tax accountant, CPA, or office at your home university or company to get personalized details. You should ask about the requirements for being paid as an independent contractor from a United States based company.',
      },
      {
        q: 'Can I be a TA in week 1 and then a student in weeks 2 and 3? Can I be a TA and a student?',
        a:
          "No. TAs must follow the same group of students through all three weeks. Thus the simple answer is no, you can't be a TA for just a week or two.",
      },
      {
        q: "I'd like to be a TA but it looks to be merit based. Should I also apply as a student?",
        a: 'Yes, please do apply both as a TA and a student!',
      },
      {
        q: 'How much do TAs get paid?',
        a:
          'TAs pay is based on the [Neuromatch Compensation and Hiring Policy](https://docs.neuromatch.io/p/ogjO-9wZOPVo0K/Hiring-and-Compensation-Policy) that applies to all positions.\n\nCalculate your pay based on your region of residence here: [TA COLA Calculator](https://neuromatchacademy.github.io/widgets/ta_cola.html).\n\nWe have a process that will help you determine what tax forms you will need to satisfy governmental regulations for some countries although ultimately you will be responsible for determining whether your other form(s) of employment is compatible with being paid by Neuromatch. We will have the money for you if you are able to legally accept it!',
      },
    ],
  },
  {
    title: 'Certificates of completion & course credit',
    content: [
      {
        q: 'Will students receive a certificate of completion?',
        a:
          "Yes, all students who complete the full course will receive a certificate of completion for both the tutorials and projects portion of the course that is available in the Documents section of their portal account after the course is complete.\n\n- Students are allowed 2 absences and can still qualify for the certificate of completion.\n- During the course, there will be an opportunity for a student who misses more than 2 days to complete an Attendance Waiver and a certificate of completion will be awarded at the discretion of a reviewer.\n- Student can receive just a tutorials certificate, but cannot receive just a projects certificate. The tutorials are required.\n- Students are required to fill out the end of course survey to receive their certificate in the portal. This can take up to 24 hours to populate after you've submitted the survey.",
      },
      {
        q: 'Do TAs receive a certificate?',
        a:
          "Yes, all TAs will receive a certificate to show the completion of your role! It is available in the Documents section of their portal account after the course is complete.\n\n- TAs are required to fill out the end of course survey to receive their certificate in the portal. This can take up to 24 hours to populate after you've submitted the survey.",
      },
      {
        q: 'Will you provide any grades so I can get university credit for participating?',
        a:
          "No. We do not give any standardized grades, but you will receive a certificate of completion if you finish the course according to the evaluation of your TA. This certificate of completion does not automatically guarantee any sort of university credit (because we are not an officially accredited school), but you might be able to talk to your administration about that option. Every school has different policies, so you'll need to take your certificate of completion and the curriculum information to your specific institution or school to see if this is an option for you. Unfortunately, we can't help facilitate this process given the number of students and universities attended.",
      },
    ],
  },
];

export default GeneralHelpPageData;
