import { ReactComponent as GraduationCap } from '~/common/assets/img/applications/graduation_cap.svg';
import { ReactComponent as Dialogue } from '~/common/assets/img/applications/dialogue.svg';
import { ReactComponent as University } from '~/common/assets/img/applications/university.svg';
import { Tooltip } from '~/common/components/Tooltip/Tooltip';
import { Block } from '~/common/components/Block/Block';
import { applicationsRoutes } from '~/modules/applications/router/Router';
import { applicationStatuses, applicationStatusesLabels } from '~/modules/applications/models/application.model';
import { CourseApplicationBlock } from '~/modules/applications/pages/Home/blocks/components/CourseApplicationBlock/CourseApplicationBlock';
import { Markdown } from '~/common/components/Markdown/Markdown';
import { useState } from 'react';
import { Collapse } from '~/common/components/Collapse/Collapse';

export const CourseBlock = (props) => {
  const course = props.course;
  const isStudentApplicationBlocked = course.student_application_status !== applicationStatuses.can_be_sent;
  const isMentorApplicationBlocked = course.mentor_application_status !== applicationStatuses.can_be_sent;
  const isTAApplicationBlocked = course.ta_application_status !== applicationStatuses.can_be_sent;
  const today = new Date();
  const studentApplicationStartDate = new Date(course.deadlines.student_application_start_date);
  const taApplicationStartDate = new Date(course.deadlines.ta_application_start_date);
  const canStudentApply = today > studentApplicationStartDate;
  const canTAApply = today > taApplicationStartDate;

  return (
    <Block className="course-block" title={course.name}>
      <div className={`course-block__desc`}>
        <Collapse title="Description" isSml={true}>
          <Markdown>{course.description}</Markdown>
        </Collapse>
      </div>
      { props.withButtons && (
      <div className="course-block__applications">
        <CourseApplicationBlock
          to={applicationsRoutes.create_student_application + '/' + course.id}
          isBlocked={isStudentApplicationBlocked || !canStudentApply}
        >
          {(!canStudentApply && (
            <Tooltip>Applications are not open</Tooltip>
          )) || (isStudentApplicationBlocked && (
            <Tooltip>{applicationStatusesLabels[course.student_application_status]}</Tooltip>
          ))}
          <GraduationCap />
          <span className="text">Apply as student.</span>
          {course.student_application_status === 'profile_not_filled' && <p className='p-error'>Please fill out your profile and logistics before you can apply.</p>}
        </CourseApplicationBlock>

        <CourseApplicationBlock
          to={applicationsRoutes.create_ta_application + '/' + course.id}
          isBlocked={isTAApplicationBlocked || !canTAApply}
        >
          {(!canTAApply && (
            <Tooltip>Applications are not open</Tooltip>
          )) || (isTAApplicationBlocked && (
              <Tooltip>{applicationStatusesLabels[course.ta_application_status]}</Tooltip>
          ))}
          <Dialogue />
          <span className="text">Apply as teaching assistant.</span>
          {course.ta_application_status === 'profile_not_filled' && <p className="p-error">Please fill out your profile and logistics before you can apply.</p>}
        </CourseApplicationBlock>

        {/*<CourseApplicationBlock
          to={applicationsRoutes.create_mentor_application + '/' + course.id}
          isBlocked={isMentorApplicationBlocked}
        >
          {isMentorApplicationBlocked && (
            <Tooltip>{applicationStatusesLabels[course.mentor_application_status]}</Tooltip>
          )}
          <University />
          <span className="text">Volunteer as a mentor.</span>
        </CourseApplicationBlock>*/}
      </div>
      )}
    </Block>
  );
};
