import { Block } from '~/common/components/Block/Block';
import { Form } from '~/common/components/Form/Form';
import { useForm } from 'react-hook-form';
import { Button } from '~/common/components/Button/Button';
import { useGetStudentApplication } from '~/modules/applications/models/application.hooks';
import { baselinePrice, setBaselinePrice, calculateAcademicDiscount, calculateCOLAAdjustment, calculatePayment } from '~/modules/applications/models/application.model';
import { useDispatch } from 'react-redux';
import { payStudentApplication } from '~/modules/stripe/redux/stripe.actions';
import { loadStripe } from '@stripe/stripe-js';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { scrollToTop } from '~/modules/app/models/app.model';
dayjs.extend(utc);

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

export const StudentPay = ({ application, previousPage, nextPage, waiveFee }) => {
  const { handleSubmit } = useForm();
  const studentApplication = useGetStudentApplication(application.student_application)?.data;
  // const course_name = application.course_name;
  const course_price = application.course_price;
  // Set baseline price as course price 
  setBaselinePrice(course_price);

    // TODO: remove comments and course name
  // TODO: Maybe figure out a more elegant way to do this
  // if (course_name === "Computational Tools for Climate Science") {
  //   setBaselinePrice(1800);
  // }

  const dispatch = useDispatch();

  const isFunded = true

  const isAcademia = studentApplication && !!(studentApplication.is_industry ? 0 : 1)
  const academicDiscount = studentApplication && calculateAcademicDiscount(studentApplication.is_industry)
  const colaAdjustment = studentApplication && calculateCOLAAdjustment(studentApplication.cola, studentApplication.is_industry)
  const residingCountry = studentApplication && studentApplication.residing_country_during_course

  const recommendedPayment = (baselinePrice - academicDiscount - colaAdjustment).toFixed(2);
  // Stripe expects input to be in cents, ideally as an interger.
  // So we enforced this for price and recommendedPayment
  const price = Math.floor(recommendedPayment * 100);
  const isPriceZero = price <= 0 ;

  const onSubmit = data => {
    const locationUrl = window.location.href;
    dispatch(
      payStudentApplication({
        courseApplication: application.id,
        amount: price,
        successUrl: locationUrl,
        cancelUrl: locationUrl,
        isFunded,
        recommendedPayment: Math.floor(recommendedPayment * 100),
      })
    ).then(async response => {
      const sessionId = response.data?.session_id;
      const stripe = await stripePromise;
      stripe.redirectToCheckout({
        sessionId,
      });
    });
  };

  return (
    <>
      <Block title="Enrollment: Payment" className="student-payment">
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Row>
          <Button className="student-application-status__go-back" onClick={() => {previousPage()}}>
            Go Back
          </Button>
          </Form.Row>
          <Form.Row>
            Neuromatch's mission is to democratize science. One piece of that is making scientific education and networking more accessible to everybody.
            <br></br> <br></br>
            We achieve this goal by making our summer school, Neuromatch academy, online and affordable for everybody. While other summer schools cost $4000 (USD), we have managed to keep ours
            at less than half the price by using scalable online technology and relying on the help of so many wonderful volunteers. This price is further adjusted downwards based on national economic
            factors of your country. Neuromatch will also provide further cost accommodations if necessary to ensure that it fits your budget, so that anybody can join no matter their background.
            <br></br> <br></br>
            We are excited you are here to join us in our mission to democratize science, and we hope we can serve an important part in scientists like you making an impact on the world.
          </Form.Row>

          <Form.Row>
            Based on your profile information, including your country of residence during the course, we have calculated this adjusted course fee.
            This course fee is used exclusively to pay our Teaching Assistants. These Teaching Assistants are academics, usually students and postdocs, that help you and your fellow ‘pod-mates’ by leading group discussions, helping with course content, and ensuring that everybody has the best experience possible.
          </Form.Row>
          <center>
          <Form.Row>&#10004; Apply my institution discount</Form.Row>
          <Form.Row>&#10004; Apply my cost of living adjustment</Form.Row>
          </center>
          <br></br>
          <Form.Row className="student-application-status__discount-table">
            <center>
            <table   className="student-application-status__discount-table" cellPadding="0" cellSpacing="0">
              <tbody>
              <tr>
                <td>Baseline Price</td>
                <td className="alignright">  $ {baselinePrice}</td>
              </tr>
              {isAcademia && (<tr>
                <td>Academic Discount</td>
                <td className="alignright">- $ {academicDiscount}</td>
              </tr>)}
              <tr>
                <td>Cost of Living Adjustment ({residingCountry})</td>
                <td className="alignright">- $ {colaAdjustment}</td>
              </tr>
              <tr className="total">
                <td className="alignright" width="80%"><b>Your Total</b></td>
                <td className="alignright"><b>$ {recommendedPayment} (USD)</b></td>
              </tr>
              </tbody>
            </table>
              </center>
            </Form.Row>
          { !isPriceZero && (<div>
          <Form.Row className="student-application-status__pay-button">
            <Button
              type="submit"
            className="student-application-status__pay-button">
              Pay now
            </Button>
          </Form.Row>
          <Form.Row className="student-application-status__buttons">
            <Button className="student-application-status__buttons" onClick={() => {nextPage(); scrollToTop()}}>
              I can't comfortably afford this price
            </Button>
          </Form.Row>
          </div>)}
          {isPriceZero && (<Form.Row className="student-application-status__pay-button">
            <Button className="student-application-status__pay-button" onClick={() => {waiveFee(); scrollToTop()}}>
              Waive fee and enroll
            </Button>
          </Form.Row>)}
        </Form>
      </Block>
    </>
  );
};
