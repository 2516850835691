import { Layout } from '~/common/components/Layout/Layout';
import { EditTAApplication } from '~/modules/applications/pages/EditTAApplication/blocks/EditTAApplication';

export const EditTAApplicationPage = () => {
  return (
    <Layout>
      <EditTAApplication />
    </Layout>
  );
};
