import { ReactComponent as GraduationCap } from '~/common/assets/img/applications/graduation_cap.svg';
import { Form } from '~/common/components/Form/Form';
import { Block } from '~/common/components/Block/Block';
import { Link } from '~/common/components/Link/Link';
import { Button } from '~/common/components/Button/Button';
import { Checkbox } from '~/common/components/Checkbox/Checkbox';
import { useForm } from 'react-hook-form';
import dayjs from 'dayjs';
import { useGetCourse} from '~/modules/applications/models/application.hooks';
import { useDispatch } from 'react-redux';
import { widthdrawStudentApplication } from '~/modules/applications/redux/applications.actions';
import { history } from '~/app/history/history';
import { applicationsRoutes } from '~/modules/applications/router/Router';
import utc from 'dayjs/plugin/utc';
import { scrollToTop } from '~/modules/app/models/app.model';
import { useSnackbar} from '~/modules/app/models/app.hooks';
dayjs.extend(utc);

const formNames = {
  accepted_document: 'accepted_document',
}

export const StudentWishToEnroll = ({ application, nextPage }) => {
  const { register, watch, setError, errors, clearErrors} = useForm();
  const acceptedDocument = watch(formNames.accepted_document);
  const course = useGetCourse(application.course);
  const dispatch = useDispatch();
  const { setSnackbar } = useSnackbar();
  if (!course) {
    return null;
  }

  const acceptingOfferDate = dayjs(application.accepting_offer_end_date).utc().format('MMMM DD');
  const startCourseDate = dayjs(course.deadlines.start_date).utc().format('MMMM DD');
  const endCourseDate = dayjs(course.deadlines.end_date).utc().format('MMMM DD');
  // year is used in the text to dynamically display the year
  const d = new Date();
  let year = d.getFullYear();

  const onContinue = () => {
    if (!acceptedDocument) {
      setSnackbar('Please confirm that you have read the rules and responsibilities document');
      setError(formNames.accepted_document, {
        type: "manual",
        message: "required",
      });
      return;
    }
    nextPage();
    scrollToTop();
  }

  const onDecline = e => {
    e?.preventDefault();
    const decline = window.confirm('Are you sure?');
    if (decline) {
      dispatch(widthdrawStudentApplication(application.student_application)).then(() => {
        history.push(applicationsRoutes.homepage);
      });
    }
  };

  return (
    <Block className="student-application-status" title="Enrollment">
      <div className="student-application-status__img mt-20">
        <GraduationCap />
      </div>
      <Form className="student-application-status__info">
        <div className="student-application-status__text">
          <Form.Row>
            Congratulations! After reviewing your application, Neuromatch Academy would like to offer you a position as
            an interactive student in {application.course_name} from {startCourseDate} - {endCourseDate}!
          </Form.Row>
          <Form.Row>
            To accept our offer and officially enroll in the course, you will need to
            <ul>
              <li>agree to the roles & responsibilities</li>
              <li>fill out the supplemental course enrollment form</li>
              <li>pay</li>
            </ul>

              You will be guided through these steps here. Please do this by{' '}
            {acceptingOfferDate} (last time zone on earth (UTC-12)).
          </Form.Row>
          <Form.Row>
            First, please review the{' '}
            <Link
              to="https://github.com/NeuromatchAcademy/precourse/blob/main/enrollmentforms/rolesandresponsibilities.md"
              isCrossDomain
              newTab
            >
              roles and responsibilities
            </Link>{' '}
            document.
          </Form.Row>
          <Form.Row>
            <Checkbox
              name={formNames.accepted_document}
              innerRef={register}
              text="I have read the rules and responsibilities document"
              onChange={option => {
                clearErrors(formNames.accepted_document);
              }}
              error={errors.accepted_document?.message}
            />
          </Form.Row>
          <Form.Row>Do you wish to enroll in {application.course_name} for the summer of {year}? </Form.Row>
          <Form.Row className="student-application-status__buttons">
            <Button className="student-application-status__buttons" onClick={() => onContinue()}>
              Yes, continue with enrollment
            </Button>
            </Form.Row>
          <Form.Row className="student-application-status__buttons">
            <Button onClick={onDecline} className="student-application-status__buttons">
              No I decline the offer
            </Button>
          </Form.Row>
        </div>
      </Form>
    </Block>
  );
};
