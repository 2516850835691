import { Layout } from '~/common/components/Layout/Layout';
import { useTitle } from '~/modules/app/models/app.hooks';
import { PasswordForm } from '~/common/components/PasswordForm/PasswordForm';
import { useState } from 'react';
import { verifyPass } from '~/modules/user/redux/user.actions';
import { useDispatch } from 'react-redux';
import { userRoutes } from '~/modules/user/router/Router';
import { history } from '~/app/history/history';
import { setErrorsFunction } from '~/common/libs/FormHelpers/FormHelpers';
import { setSnackbar } from '~/modules/app/redux/app.actions';
import { useForm } from 'react-hook-form';


export const VerifyPasswordPage = () => {
  useTitle('Verify Password');
  const { setError } = useForm();
  const dispatch = useDispatch();
  const [password, setPassword] = useState("");
  const setErrors = setErrorsFunction(setError);
  const text = 'You should only download content from the portal if you can\'t see/access the course content/notebooks via the normal channels (i.e Colab/Kaggle).'
  const onSubmit = event => {
    event.preventDefault(); 
    dispatch(verifyPass({"password": password}))
      .then(() => {
        dispatch(setSnackbar('For students with issues viewing course content only! Please don\'t share this password', 'Notification', true, 7000));
        history.push(userRoutes.media);
      })
      .catch(e => {
        dispatch(setSnackbar('For students with issues viewing course content only!', 'Notification', true, 7000));
        const error = e.response.data;
        setErrors(error);
      });
  };
  return (
    <Layout>
      <PasswordForm title={"Course Content"} text={text} onSubmit={onSubmit} password={password} setPassword={setPassword} />
    </Layout>
  );
};
