import { AppActionGroups, AppSliceNames } from '~/modules/app/redux/app.store';
import { ReduxController } from '~/common/libs/ReduxController/ReduxController';

const initialState = {
  [AppSliceNames.isReady]: false,
  [AppSliceNames.isLoading]: false,
  [AppSliceNames.snackbarNotifications]: [],
  [AppSliceNames.canRequest]: false,
  [AppSliceNames.initialData]: ReduxController.createState(null),
};

export const appReducer = ReduxController.createReducer(initialState, {
  [AppActionGroups[AppSliceNames.isReady]]: (state = initialState, action) => {
    return { ...state, [AppSliceNames.isReady]: action.payload };
  },
  [AppActionGroups[AppSliceNames.isLoading]]: (state = initialState, action) => {
    return { ...state, [AppSliceNames.isLoading]: action.payload };
  },
  [AppActionGroups[AppSliceNames.snackbarNotifications]]: (state = initialState, action) => {
    return { ...state, [AppSliceNames.snackbarNotifications]: action.payload };
  },
  [AppActionGroups.SET_SNACKBAR]: (state = initialState, action) => {
    const snackbar = action.payload;
    const snackbars = [...state[AppSliceNames.snackbarNotifications], snackbar];
    return { ...state, [AppSliceNames.snackbarNotifications]: snackbars };
  },
  [AppActionGroups.DELETE_SNACKBAR]: (state = initialState, action) => {
    const snackbar = action.payload;
    const snackbars = state[AppSliceNames.snackbarNotifications].filter(sn => sn.id !== snackbar.id);
    return { ...state, [AppSliceNames.snackbarNotifications]: snackbars };
  },
  [AppActionGroups[AppSliceNames.canRequest]]: (state = initialState, action) => {
    return { ...state, [AppSliceNames.canRequest]: action.payload };
  },
  ...ReduxController.createHandlers(AppActionGroups[AppSliceNames.initialData], AppSliceNames.initialData),
});

export const reducer = (state = initialState, action) => {
  let snackbar, snackbars;
  switch (action.type) {
    case AppActionGroups[AppSliceNames.isReady]:
      return { ...state, [AppSliceNames.isReady]: action.payload };
    case AppActionGroups[AppSliceNames.isLoading]:
      return { ...state, [AppSliceNames.isLoading]: action.payload };
    case AppActionGroups[AppSliceNames.snackbarNotifications]:
      return { ...state, [AppSliceNames.snackbarNotifications]: action.payload };
    case AppActionGroups.SET_SNACKBAR:
      snackbar = action.payload;
      snackbars = [...state[AppSliceNames.snackbarNotifications], snackbar];
      return { ...state, [AppSliceNames.snackbarNotifications]: snackbars };
    case AppActionGroups.DELETE_SNACKBAR:
      snackbar = action.payload;
      snackbars = state[AppSliceNames.snackbarNotifications].filter(sn => sn.id !== snackbar.id);
      return { ...state, [AppSliceNames.snackbarNotifications]: snackbars };
    case AppActionGroups[AppSliceNames.canRequest]:
      return { ...state, [AppSliceNames.canRequest]: action.payload };
    default:
      return { ...state };
  }
};
