import { Markdown } from '~/common/components/Markdown/Markdown';
import { Collapse } from '~/common/components/Collapse/Collapse';
import GeneralHelpPageData from '~/modules/applications/data/Help/GeneralHelpPageData';

export const Help = () => {
  return (
    <div className="help narrow mt-20">
      {GeneralHelpPageData.map((section, index) => (
        <Collapse key={index} title={section.title} defaultExpanded={section.defaultExpanded}>
          {section.content.map(({ a, q, img_path }, idx) => (
            <div key={idx}>
              <Markdown key={idx}>{`**${q}**`}</Markdown>
              <Markdown>{`${a}`}</Markdown>
              {img_path ? <img src={img_path} alt="help img" width="100%" /> : ""} 
              <br />
            </div>
          ))}
        </Collapse>
      ))}
    </div>
  );
};
