import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
    // fbLogin,
    getCertificate,
    getMyCertificates,
    getORCID,
    getORCIDEducation,
    getSocialAccounts,
    getReceipts,
    githubLogin,
    googleLogin,
    orcidLogin,
    // update,
    // getRedirectLink,
    wantsToEnroll,
    wantsToEnrollTA,
    whoami,
} from '~/modules/user/redux/user.actions'
import { selectUser } from '~/modules/user/redux/user.selectors'
import { UserWrapper } from '~/modules/user/models/user.model'
import { setSnackbar } from '~/modules/app/redux/app.actions'
import { useAppReady, useSnackbar } from '~/modules/app/models/app.hooks'

export const useWhoami = () => {
    const [loaded, setLoaded] = useState(false)
    const dispatch = useDispatch()
    useEffect(() => {
        if (!loaded) {
            dispatch(whoami())
            setLoaded(true)
        }
    }, [loaded, dispatch, setLoaded])
}

export const useIsLoggedIn = () => {
    const user = useSelector(selectUser)
    return !!user
}

export const useUser = () => {
    return useSelector(selectUser)
}

export const useGetORCID = () => {
    const dispatch = useDispatch();
    const isLoggedIn = useSelector(state => state.USER.user.isLoaded);
    const [ORCID, setORCID] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (isLoggedIn) {
            setLoading(true);

            dispatch(getORCID())
                .then(response => {
                    setORCID(response.data.orcid);
                })
                .catch(e => {
                    console.error(e);
                    setError(e);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [dispatch, isLoggedIn]);

    return { ORCID, loading, error };
}

export const useGetORCIDEducation = () => {
    const dispatch = useDispatch()

    return useCallback(() => dispatch(getORCIDEducation()), [dispatch])
}

export const useGetSocialAccounts = () => {
    const dispatch = useDispatch();
    const isLoggedIn = useSelector(state => state.USER.user.isLoaded);
    const [socialAccounts, setSocialAccounts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (isLoggedIn) {
            setLoading(true);

            dispatch(getSocialAccounts())
                .then(response => {
                    setSocialAccounts(response.data.results);
                })
                .catch(e => {
                    console.error(e);
                    setError(e);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [dispatch, isLoggedIn]);

    return { socialAccounts, loading, error };
}

const useHandleErrorCb = () => {
    const dispatch = useDispatch()
    const cb = useCallback(
        e => {
            const error = e?.response?.data?.non_field_errors
            if (error?.length) {
                const [errorMessage] = error
                dispatch(setSnackbar(errorMessage?.message || 'Error'))
            }
        },
        [dispatch]
    )
    return cb
}

export const useUserWrapper = () => {
    const user = useSelector(selectUser)
    if (!user) {
        return null
    }
    return new UserWrapper(user)
}


export const useGithubResponse = (isConnect = false) => {
    const dispatch = useDispatch()
    const errorHandler = useHandleErrorCb()
    const githubCallback = useCallback(
        response => {
            const code = response?.code
            if (code) {
                dispatch(githubLogin({ code }, isConnect)).catch(errorHandler)
            }
        },
        [dispatch, isConnect, errorHandler]
    )
    return githubCallback
}

export const useGoogleResponse = (isConnect = false) => {
    const dispatch = useDispatch()
    const errorHandler = useHandleErrorCb()
    const googleCallback = useCallback(
        response => {
            const accessToken = response?.accessToken
            if (accessToken) {
                dispatch(googleLogin({ access_token: accessToken }, isConnect)).catch(errorHandler)
            }
        },
        [dispatch, isConnect, errorHandler]
    )
    return googleCallback
}

export const useORCIDResponse = (isConnect = false) => {
    const dispatch = useDispatch()
    const errorHandler = useHandleErrorCb()
    const orcidCallback = useCallback(
        response => {
            const code = response?.code
            if (code) {
                dispatch(orcidLogin({ code: code }, isConnect)).catch(errorHandler)
            }
        },
        [dispatch, isConnect, errorHandler]
    )
    return orcidCallback
}

export const useProfileNotFilledNotification = () => {
    const [notified, setNotified] = useState(false)
    const appReady = useAppReady()
    const { setSnackbar } = useSnackbar()
    const user = useUser()
    useEffect(() => {
        if (!notified && appReady && user && !user.user_profile_filled) {
            setSnackbar(
                'To unlock applications, please first fill out/update your profile and logistics information',
                'Welcome to NMA',
                true,
                7000
            )
            setNotified(true)
        }
    }, [setSnackbar, notified, appReady, user])
}

export const useWishToEnrollRequest = () => {
    const dispatch = useDispatch()
    return id => dispatch(wantsToEnroll(id))
}

export const useWishToEnrollTARequest = () => {
    const dispatch = useDispatch()
    return id => dispatch(wantsToEnrollTA(id))
}

export const useGetReceiptsRequest = () => {
    const dispatch = useDispatch()

    return useCallback(() => dispatch(getReceipts()), [dispatch])
}

export const useGetCertificatesRequest = () => {
    const dispatch = useDispatch()

    return useCallback(() => dispatch(getMyCertificates()), [dispatch])
}

export const useGetCertificateRequest = () => {
    const dispatch = useDispatch()

    return useCallback(id => dispatch(getCertificate(id)), [dispatch])
}
