import { axios } from '~/app/axios/axios'

export const githubId = process.env.REACT_APP_SOCIAL_AUTH_GITHUB_KEY
export const googleId = process.env.REACT_APP_SOCIAL_AUTH_GOOGLE_KEY
export const orcidId  = process.env.REACT_APP_SOCIAL_AUTH_ORCID_KEY
export const orcidRedirectURI = process.env.REACT_APP_SOCIAL_AUTH_ORCID_REDIRECT_URI
export const isOrcidSandbox = process.env.REACT_APP_IS_ORCID_SANDBOX === "true"

export const degrees = {
    no_degree: 'no_degree',
    secondary_school: 'secondary_school',
    undergraduate_degree: 'undergraduate_degree',
    masters_degree: 'masters_degree',
    doctoral_degree: 'doctoral_degree',
    other: 'other',
}

export const degreesOptions = [
    { label: 'No degree', value: degrees.no_degree },
    { label: 'Secondary school degree (high school or equivalent)', value: degrees.secondary_school },
    { label: "Undergraduate degree (Bachelor's or equivalent)", value: degrees.undergraduate_degree },
    { label: "Master's degree or equivalent", value: degrees.masters_degree },
    { label: 'Doctoral degree (PhD, Integrated PhD, MD-PhD, DPhil or equivalent)', value: degrees.doctoral_degree },
    { label: 'Other', value: degrees.other },
]

const currentYear = new Date().getFullYear()
const options = []
for (let i = 1950; i < currentYear + 1; i += 1) options.push(i)
export const years = options.map(option => ({ label: option, value: option }))

export const statuses = {
    undergraduate_student: 'undergraduate_student',
    masters_student: 'masters_student',
    phd_student: 'phd_student',
    postdoc: 'postdoc',
    lecturer_professor_faculty: 'lecturer_professor_faculty',
    industry: 'industry',
    other: 'other',
}

export const statusesOptions = [
    { label: 'Undergraduate student', value: statuses.undergraduate_student },
    { label: 'Masters student', value: statuses.masters_student },
    { label: 'PhD student', value: statuses.phd_student },
    { label: 'Postdoc', value: statuses.postdoc },
    { label: 'Lecturer/Professor/Faculty', value: statuses.lecturer_professor_faculty },
    { label: 'Industry', value: statuses.industry },
    { label: 'Other', value: statuses.other },
]

// export const contestedAreas = {
//     ukraine: 'Ukraine',
// }

export const heldPositionTimes = {
    zero_years: 'zero_years',
    one_year: 'one_year',
    two_years: 'two_years',
    three_years: 'three_years',
    between_four_and_five_years: 'between_four_and_five_years',
    more_than_six_years: 'more_than_six_years',
}

export const heldPositionTimesOptions = [
    { label: '0 years', value: heldPositionTimes.zero_years },
    { label: '1 year', value: heldPositionTimes.one_year },
    { label: '2 years', value: heldPositionTimes.two_years },
    { label: '3 years', value: heldPositionTimes.three_years },
    { label: '4-5 years', value: heldPositionTimes.between_four_and_five_years },
    { label: '6+ years', value: heldPositionTimes.more_than_six_years },
]

export const matchOpportunities = {
    industry_jobs: 'industry_jobs',
    academic_jobs: 'academic_jobs',
    nonprofit_or_government_jobs: 'nonprofit_or_government_jobs',
    phd_openings: 'phd_openings',
    industry_internships: 'industry_internships',
    academic_internships: 'academic_internships',
    conferences: 'conferences',
}

export const matchOpportunitiesOptions = {
    [matchOpportunities.industry_jobs]: 'Job opportunities in industry',
    [matchOpportunities.academic_jobs]: 'Job opportunities in academia',
    [matchOpportunities.nonprofit_or_government_jobs]: 'Job opportunities in nonprofit and government programs',
    [matchOpportunities.phd_openings]: 'PhD student openings',
    [matchOpportunities.industry_internships]: 'Internship openings in industry',
    [matchOpportunities.academic_internships]: 'Internship openings in academic research',
    [matchOpportunities.conferences]: 'Conferences I might like to attend',
}

export const timeSlots = {
    '1': '1',
    '2': '2',
    '3': '3',
    '4': '4',
    '5': '5',
}

// export const timeSlotsLabels = {
//     [timeSlots['1']]: 'Slot 1 (00:30 - 03:30 UTC)',
//     [timeSlots['2']]: 'Slot 2 (10:00 - 13:00 UTC)',
//     [timeSlots['3']]: 'Slot 3 (07:00 - 10:00 UTC)',
//     [timeSlots['4']]: 'Slot 4 (13:00 - 16:00 UTC)',
//     [timeSlots['5']]: 'Slot 5 (22:30 - 1:30 UTC)',
// }
export const timeSlotsLabels = {
    [timeSlots['1']]: 'Slot 1: Projects: 00:30 - 03:30 UTC, Coursework: 04:30 - 09:00 UTC',
    [timeSlots['2']]: 'Slot 2: Coursework: 04:30 - 09:00 UTC, Projects: 10:00 - 13:00 UTC',
    [timeSlots['3']]: 'Slot 3: Projects: 07:00 - 10:00 UTC, Coursework: 11:00 - 15:30 UTC',
    [timeSlots['4']]: 'Slot 4: Projects: 13:00 - 16:00 UTC, Coursework: 17:00 - 21:30 UTC',
    [timeSlots['5']]: 'Slot 5: Coursework: 17:00 - 21:30 UTC, Projects: 22:30 - 1:30 UTC',
}

export const timeSlotsOptions = [
    { label: 'Slot 1', value: timeSlots['1'] },
    { label: 'Slot 2', value: timeSlots['2'] },
    { label: 'Slot 3', value: timeSlots['3'] },
    { label: 'Slot 4', value: timeSlots['4'] },
    { label: 'Slot 5', value: timeSlots['5'] },
]

export const languages = {
    english: 'english',
    arabic: 'arabic',
    bengali: 'bengali',
    cantonese: 'cantonese',
    farsi_persian: 'farsi_persian',
    french: 'french',
    german: 'german',
    greek: 'greek',
    hebrew: 'hebrew',
    hindi: 'hindi',
    italian: 'italian',
    japanese: 'japanese',
    korean: 'korean',
    mandarin: 'mandarin',
    portugese: 'portugese',
    russian: 'russian',
    spanish: 'spanish',
    turkish: 'turkish',
    urdu: 'urdu',
}

export const languagesLabels = {
    [languages.english]: 'English',
    [languages.arabic]: 'Arabic',
    [languages.bengali]: 'Bengali',
    [languages.cantonese]: 'Cantonese',
    [languages.farsi_persian]: 'Farsi/Persian',
    [languages.french]: 'French',
    [languages.german]: 'German',
    [languages.greek]: 'Greek',
    [languages.hebrew]: 'Hebrew',
    [languages.hindi]: 'Hindi',
    [languages.italian]: 'Italian',
    [languages.japanese]: 'Japanese',
    [languages.korean]: 'Korean',
    [languages.mandarin]: 'Mandarin',
    [languages.portugese]: 'Portugese',
    [languages.russian]: 'Russian',
    [languages.spanish]: 'Spanish',
    [languages.turkish]: 'Turkish',
    [languages.urdu]: 'Urdu',
}

export const languagesOptions = [
    { label: 'English', value: languages.english },
    { label: 'Arabic', value: languages.arabic },
    { label: 'Bengali', value: languages.bengali },
    { label: 'Cantonese', value: languages.cantonese },
    { label: 'Farsi/Persian', value: languages.farsi_persian },
    { label: 'French', value: languages.french },
    { label: 'German', value: languages.german },
    { label: 'Greek', value: languages.greek },
    { label: 'Hebrew', value: languages.hebrew },
    { label: 'Hindi', value: languages.hindi },
    { label: 'Italian', value: languages.italian },
    { label: 'Japanese', value: languages.japanese },
    { label: 'Korean', value: languages.korean },
    { label: 'Mandarin', value: languages.mandarin },
    { label: 'Portugese', value: languages.portugese },
    { label: 'Russian', value: languages.russian },
    { label: 'Spanish', value: languages.spanish },
    { label: 'Turkish', value: languages.turkish },
    { label: 'Urdu', value: languages.urdu },
]

export const genders = {
    female: 'female',
    male: 'male',
    non_binary: 'non_binary',
    trans_gender: 'trans_gender',
    cis_gender: 'cis_gender',
    prefer_not: 'prefer_not',
    self_describe: 'self_describe',
}

export const genderLabels = {
    [genders.female]: 'Woman',
    [genders.male]: 'Man',
    [genders.non_binary]: 'Nonbinary / Genderqueer / Gender Fluid',
    [genders.trans_gender]: 'Transgender',
    [genders.cis_gender]: 'Cisgender',
    [genders.prefer_not]: 'Prefer not to say',
    [genders.self_describe]: 'Specify your own / self-describe',
}

export const races = {
    white: 'white',
    hispanic: 'hispanic',
    black: 'black',
    asian: 'asian',
    oceanian: 'oceanian',
    indigenous: 'indigenous',
    north_african: 'north_african',
    other: 'other',
    prefer_not: 'prefer_not',
    self_describe: 'self_describe',
}

export const racesLabels = {
    [races.white]: 'White',
    [races.hispanic]: 'Hispanic or Latin American',
    [races.black]: 'Black, African American, or Sub-Saharan African',
    [races.asian]: 'Asian',
    [races.oceanian]: 'Oceanian',
    [races.indigenous]: 'Native or Indigenous People',
    [races.north_african]: 'Middle Eastern or North African',
    [races.other]: 'Some other race, ethnicity, or origin',
    [races.prefer_not]: 'Prefer not to say',
    [races.self_describe]: 'Self-describe',
}

export const parentsCompletedCollege = {
    yes: 'yes',
    no: 'no',
    prefer_not: 'prefer_not',
}

export const parentsCompletedCollegeOptions = [
    { label: 'Yes', value: parentsCompletedCollege.yes },
    { label: 'No', value: parentsCompletedCollege.no },
    { label: 'Prefer not to say', value: parentsCompletedCollege.prefer_not },
]

export const dependants = {
    yes: 'yes',
    no: 'no',
    prefer_not: 'prefer_not',
}

export const dependantsOptions = [
    { label: 'Yes', value: dependants.yes },
    { label: 'No', value: dependants.no },
    { label: 'Prefer not to say', value: dependants.prefer_not },
]

export const socioeconomicSituations = {
    below: 'below',
    average: 'average',
    above: 'above',
    prefer_not: 'prefer_not',
}

export const socioeconomicSituationsOptions = [
    { label: 'Below Average', value: socioeconomicSituations.below },
    { label: 'Average', value: socioeconomicSituations.average },
    { label: 'Above Average', value: socioeconomicSituations.above },
    { label: 'Prefer not to say', value: socioeconomicSituations.prefer_not },
]

export const disabilities = {
    screen_reader: 'screen_reader',
    closed_captioning: 'closed_captioning',
    color_contrast: 'color_contrast',
    spotlighthing: 'spotlighthing',
    additional_time: 'additional_time',
    descriptive: 'descriptive',
    self_describe: 'self-describe',
}

export const disabilitiesOptions = {
    [disabilities.screen_reader]: 'Screen reader',
    [disabilities.closed_captioning]: 'Closed captioning',
    [disabilities.color_contrast]: 'Color contrast',
    [disabilities.spotlighthing]: 'Spotlighting speaker/video/text',
    [disabilities.additional_time]: 'Additional time',
    [disabilities.descriptive]: 'Descriptive text/audio',
    [disabilities.self_describe]: 'Specify your own / self-describe',
}

export const verificationErrors = {
    expired: 'expired',
}

export const certificateTypes = {
    completed_the_course: 'completed_the_course',
    completed_the_course_and_project: 'completed_the_course_and_project',
    ta_certificate: 'ta_certificate',
    lead_ta_certificate: 'lead_ta_certificate',
    project_ta_certificate: 'project_ta_certificate',
}

export const certificateTypesLabels = {
    [certificateTypes.completed_the_course]: 'Completed the course',
    [certificateTypes.completed_the_course_and_project]: 'Completed the course and the project',
    [certificateTypes.ta_certificate]: 'TA certificate',
    [certificateTypes.lead_ta_certificate]: 'Lead TA certificate',
    [certificateTypes.project_ta_certificate]: 'Project TA certificate',
}

export class AuthToken {
    constructor() {
        this.itemName = 'portal-token'
    }

    set = token => {
        localStorage.setItem(this.itemName, token)
        axios.defaults.headers.authorization = `Token ${token}`
    }

    delete = () => {
        localStorage.removeItem(this.itemName)
        delete axios.defaults.headers.authorization
    }

    get = () => {
        return localStorage.getItem(this.itemName)
    }
}

export class UserWrapper {
    constructor(user) {
        this.user = user
    }

    getFirstName = () => this.user.first_name

    getLastName = () => this.user.last_name

    getEmail = () => this.user.email
}
