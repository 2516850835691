import { Preferences } from '~/modules/user/pages/Applications/blocks/components/Preferences/Preferences';
import { useSelector } from 'react-redux';
import { selectCourses } from '~/modules/applications/redux/applications.selectors';
import { ApplicationsBlock } from '~/modules/applications/pages/Home/blocks/components/ApplicationsBlock/ApplicationsBlock';
import { CoursesBlocks } from '~/modules/applications/pages/Home/blocks/components/CoursesBlocks/CoursesBlocks';
import { Checklist } from '~/modules/user/pages/Applications/blocks/components/Checklist/Checklist';

export const UserApplications = () => {
  const courses = useSelector(selectCourses);
  return (
    <div className="applications">
      <div className="inner">
        <ApplicationsBlock />
        <Preferences courses={courses} />
        <Checklist></Checklist>
        <CoursesBlocks withButtons={true}/>
      </div>
    </div>
  );
};
