export const APP_STORE_KEY = 'APP'

const getActionGroupName = name => {
    return APP_STORE_KEY + '_' + name
}

export const AppSliceNames = {
    isReady: 'isReady',
    isLoading: 'isLoading',
    snackbarNotifications: 'snackbarNotifications',
    canRequest: 'canRequest',
    initialData: 'initialData',
    mediaFiles: 'mediaFiles',
}

export const AppActionGroups = {
    [AppSliceNames.isReady]: getActionGroupName(AppSliceNames.isReady),
    [AppSliceNames.isLoading]: getActionGroupName(AppSliceNames.isLoading),
    [AppSliceNames.snackbarNotifications]: getActionGroupName(AppSliceNames.snackbarNotifications),
    [AppSliceNames.canRequest]: getActionGroupName(AppSliceNames.canRequest),
    [AppSliceNames.initialData]: getActionGroupName(AppSliceNames.initialData),
    [AppSliceNames.mediaFiles]: getActionGroupName(AppSliceNames.mediaFiles),
    SET_SNACKBAR: getActionGroupName('SET_SNACKBAR'),
    DELETE_SNACKBAR: getActionGroupName('DELETE_SNACKBAR'),
}
