import { Link } from '~/common/components/Link/Link';

export const CourseApplicationBlock = ({ to, children, isBlocked }) => {
  if (isBlocked) {
    return <div className="course-block__application-item blocked">{children}</div>;
  }
  return (
    <Link className="course-block__application-item" to={to}>
      {children}
    </Link>
  );
};
