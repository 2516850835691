import { Block } from '~/common/components/Block/Block';

const mail = 'support@neuromatchacademy.org';

export const MentorDashboard = () => {
  return (
    <div className="narrow mt-20">
      <Block title="Info">
        Can't make a scheduled meeting or need additional support?{' '}
        <a className="link" href={'mailto:' + mail}>
          Contact us
        </a>
      </Block>
    </div>
  );
};
