import { useForm } from 'react-hook-form';
import { Form } from '~/common/components/Form/Form';
import { Input } from '~/common/components/Input/Input';
import React from 'react';
import { Button } from '~/common/components/Button/Button';
import { Link } from '~/common/components/Link/Link';
import { userRoutes } from '~/modules/user/router/Router';
import { useDispatch } from 'react-redux';
import { forgotPassword } from '~/modules/user/redux/user.actions';
import { setErrorsFunction } from '~/common/libs/FormHelpers/FormHelpers';
import { setSnackbar } from '~/modules/app/redux/app.actions';

const formNames = {
  email: 'email',
};

export const ForgotPassword = () => {
  const { register, handleSubmit, setError, errors } = useForm();
  const dispatch = useDispatch();
  const setErrors = setErrorsFunction(setError);
  const onSubmit = data => {
    dispatch(forgotPassword(data))
      .then(() => {
        dispatch(setSnackbar('Instructions have been sent'));
      })
      .catch(e => {
        const errors = e.response.data;
        setErrors(errors);
      });
  };
  return (
    <div className="forgot-password">
      <div className="inner">
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Row>
            <p className="forgot-password__description">Instructions will be sent to your email address</p>
          </Form.Row>
          <Form.Row>
            <Input
              type="email"
              placeholder="Enter email"
              innerRef={register({ required: true })}
              name={formNames.email}
              error={errors[formNames.email]?.message}
            />
          </Form.Row>
          <Form.Row>
            <Button variant="primary" type="submit" className="forgot-password__submit-btn">
              Submit
            </Button>
          </Form.Row>
          <Form.Row className="forgot-password__auth-links">
            <div className="forgot-password__login">
              <Link to={userRoutes.login}>Sign In</Link>
            </div>
            <div className="forgot-password__sign-up">
              <Link to={userRoutes.sign_up}>Create a new account</Link>
            </div>
          </Form.Row>
        </Form>
      </div>
    </div>
  );
};
