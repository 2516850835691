import { ReduxController } from '~/common/libs/ReduxController/ReduxController';
import { ApplicationsSliceNames, ApplicationsActionGroups } from '~/modules/applications/redux/applications.store';

const initialState = {
  [ApplicationsSliceNames.courses]: ReduxController.createState(null),
  [ApplicationsSliceNames.datasets]: {},
  [ApplicationsSliceNames.applications]: ReduxController.createState(null),
};

export const applicationsReducer = ReduxController.createReducer(initialState, {
  ...ReduxController.createHandlers(
    ApplicationsActionGroups[ApplicationsSliceNames.courses],
    ApplicationsSliceNames.courses
  ),
  ...ReduxController.createHandlers(
    ApplicationsActionGroups[ApplicationsSliceNames.applications],
    ApplicationsSliceNames.applications
  ),
  [ApplicationsActionGroups[ApplicationsSliceNames.datasets]]: (state, action) => {
    const {
      id,
      response: { data_sets },
    } = action.payload;
    const datasets = { ...state[ApplicationsSliceNames.datasets], [id]: data_sets };
    return { ...state, [ApplicationsSliceNames.datasets]: datasets };
  },
});
