import { useAppReady } from '~/modules/app/models/app.hooks';
import { Redirect, Switch, Route } from 'react-router-dom';
import { PrivateRoute, UnAuthRoute, userRoutes } from '~/modules/user/router/Router';
import { applicationsRoutes } from '~/modules/applications/router/Router';
import { HomePage } from '~/modules/applications/pages/Home/HomePage';
import { SignInPage } from '~/modules/user/pages/SignIn/SignInPage';
import { SignUpPage } from '~/modules/user/pages/SignUp/SignUpPage';
import { ForgotPasswordPage } from '~/modules/user/pages/ForgotPassword/ForgotPasswordPage';
import { ResetPasswordPage } from '~/modules/user/pages/ResetPassword/ResetPasswordPage';
import { Verification } from '~/modules/user/pages/Verification/Verification';
import { ProfilePage } from '~/modules/user/pages/Profile/ProfilePage';
import { ApplicationsPage } from '~/modules/user/pages/Applications/ApplicationsPage';
import { GithubVerificationPage } from '~/modules/user/pages/GithubVefirication/GithubVerificationPage';
import { CreateStudentApplicationPage } from '~/modules/applications/pages/CreateStudentApplication/CreateStudentApplicationPage';
import { CreateTAApplicationPage } from '~/modules/applications/pages/CreateTAApplication/CreateTAApplicationPage';
import { CreateMentorApplicationsPage } from '~/modules/applications/pages/CreateMentorApplication/CreateMentorApplicationsPage';
import { EditStudentApplicationPage } from '~/modules/applications/pages/EditStudentApplication/EditStudentApplicationPage';
import { EditTAApplicationPage } from '~/modules/applications/pages/EditTAApplication/EditTAApplicationPage';
import { EditMentorApplicationPage } from '~/modules/applications/pages/EditMentorApplication/EditMentorApplicationPage';
import { CourseDashboardPage } from '~/modules/applications/pages/CourseDashboardPage/CourseDashboardPage';
import { NewEmailVerification } from '~/modules/user/pages/NewEmailVerification/NewEmailVerification';
import { CertificatePage } from '~/modules/user/pages/Certificate/CertificatePage';
import { LogisticsPage } from '../../modules/user/pages/Logistics/LogisticsPage';
import { CourseContentPage } from '../../modules/user/pages/CourseContent/CourseContent';
import { VerifyPasswordPage } from '../../modules/user/pages/CourseContent/VerifyPassword';
import { HelpPage } from '~/modules/applications/pages/Help/HelpPage';

export const Router = () => {
  const appReady = useAppReady();
  return (
    <>
      {appReady && (
        <Switch>
          <PrivateRoute exact path={applicationsRoutes.homepage} component={HomePage} />
          <PrivateRoute exact path={userRoutes.media} component={CourseContentPage} />
          <PrivateRoute exact path={userRoutes.verify_pass} component={VerifyPasswordPage} />
          <UnAuthRoute exact path={userRoutes.login} component={SignInPage} title="Sign In" />
          <UnAuthRoute exact path={userRoutes.sign_up} component={SignUpPage} title="Sign Up" />
          <UnAuthRoute exact path={userRoutes.forgot_password} component={ForgotPasswordPage} title="Forgot Password" />
          <UnAuthRoute
            exact
            path={userRoutes.reset_password + '/:token'}
            component={ResetPasswordPage}
            title="Reset Password"
          />
          <UnAuthRoute exact path={userRoutes.verification + '/:token'} component={Verification} />
          <PrivateRoute exact path={userRoutes.account} component={ProfilePage} />
          <PrivateRoute exact path={userRoutes.logistics} component={LogisticsPage} />
          <PrivateRoute exact path={userRoutes.applications} component={ApplicationsPage} />
          <PrivateRoute
            exact
            path={applicationsRoutes.create_student_application + '/:id'}
            component={CreateStudentApplicationPage}
          />
          <PrivateRoute
            exact
            path={applicationsRoutes.create_ta_application + '/:id'}
            component={CreateTAApplicationPage}
          />
          <PrivateRoute
            exact
            path={applicationsRoutes.create_mentor_application + '/:id'}
            component={CreateMentorApplicationsPage}
          />
          <PrivateRoute
            exact
            path={applicationsRoutes.edit_student_application + '/:courseId/:applicationId'}
            component={EditStudentApplicationPage}
          />
          <PrivateRoute
            exact
            path={applicationsRoutes.edit_ta_application + '/:courseId/:applicationId'}
            component={EditTAApplicationPage}
          />
          <PrivateRoute
            exact
            path={applicationsRoutes.edit_mentor_application + '/:courseId/:applicationId'}
            component={EditMentorApplicationPage}
          />
          <PrivateRoute exact path={applicationsRoutes.course + '/:id'} component={CourseDashboardPage} />
          <Route exact path={userRoutes.github_verification + '/:token'} component={GithubVerificationPage} />
          <Route exact path={userRoutes.new_email_verification + '/:token'} component={NewEmailVerification} />
          <Route exact path={userRoutes.certificate + '/:id'} component={CertificatePage} />
          <PrivateRoute exact path={applicationsRoutes.helppage} component={HelpPage} />
          <Redirect to={applicationsRoutes.homepage} />
        </Switch>
      )}
    </>
  );
};
