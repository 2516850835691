import { useParams } from 'react-router-dom';
import { useGetApplicationRequest, useGetPod, useGetCourse } from '~/modules/applications/models/application.hooks';
import { applicationTypes } from '~/modules/applications/models/application.model';
import { TAPodInfo } from '~/modules/applications/pages/CourseDashboardPage/blocks/components/TAPodInfo/TAPodInfo';
import { TAIntro } from '~/modules/applications/pages/CourseDashboardPage/blocks/components/TAIntro/TAIntro';
import { TASuccess } from '~/modules/applications/pages/CourseDashboardPage/blocks/components/TASuccess/TASuccess';
import { useEffect, useState } from 'react';

export const TAApplicationStatus = ({application, course}) => {

  const hasEnrolled = application.wants_to_enroll
  return (
    <>
      <div className="narrow mt-20 ta-dashboard">
          {!hasEnrolled ? (<TAIntro application={application} />) :
              (<TASuccess application={application} />)
          }
      </div>
    </>
  );
};
