import { combineReducers } from 'redux';
import thunk from 'redux-thunk';
import { configureStore } from '@reduxjs/toolkit';
import { USER_STORE_KEY } from '~/modules/user/redux/user.store';
import { userReducer } from '~/modules/user/redux/user.reducer';
import { APP_STORE_KEY } from '~/modules/app/redux/app.store';
import { appReducer } from '~/modules/app/redux/app.reducer';
import { APPLICATIONS_STORE_KEY } from '~/modules/applications/redux/applications.store';
import { applicationsReducer } from '~/modules/applications/redux/applications.reducer';

const rootReducers = {
  [USER_STORE_KEY]: userReducer,
  [APP_STORE_KEY]: appReducer,
  [APPLICATIONS_STORE_KEY]: applicationsReducer,
};

const reducer = combineReducers(rootReducers);

export const store = configureStore({
  reducer,
  middleware: getDefaultMiddleware => getDefaultMiddleware().concat(thunk),
  devTools: process.env.NODE_ENV !== 'production',
});
