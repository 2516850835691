import { useEffect, useRef } from 'react';

export const Block = ({ title, children, className = '' }) => {
  const titleWrapperRef = useRef();
  const titleRef = useRef();
  const innerRef = useRef();
  const outerRef = useRef();
  useEffect(() => {
    const offset = titleRef?.current?.offsetHeight;
    const offsetForInner = offset && Math.ceil(offset / 2);
    if (titleRef?.current?.offsetHeight) {
      titleWrapperRef.current.style.height = offset + 'px';
      innerRef.current.style.paddingTop = (offsetForInner + 20) + 'px';
    }
    const outerMargin = window.getComputedStyle(outerRef.current)?.marginTop?.split('px')[0];
    if (outerMargin < offsetForInner) {
      outerRef.current.style.marginTop = (offsetForInner + 1) +'px';
    }
  });
  return (
    <div className={'block ' + className} ref={outerRef}>
      <div className="block__title-wrapper" ref={titleWrapperRef}>
        <span className="block__title" ref={titleRef}>
          {title}
        </span>
      </div>
      <div className="inner" ref={innerRef}>
        {children}
      </div>
    </div>
  );
};
