import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

const defaultTabs = ['Settings', 'Documents'];

export const ProfileTabs = ({ tabs = defaultTabs, children }) => {
  return (
    <Tabs>
      <TabList>
        {tabs.map(t => (
          <Tab key={t}>{t}</Tab>
        ))}
      </TabList>
      {Array.isArray(children) ? (
        <>
          {children.map(c => (
            <TabPanel>{c}</TabPanel>
          ))}
        </>
      ) : (
        <>{children}</>
      )}
    </Tabs>
  );
};
