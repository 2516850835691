import { Button } from '~/common/components/Button/Button';
import { Block } from '~/common/components/Block/Block';
import { Input } from '~/common/components/Input/Input';
import { Form } from '~/common/components/Form/Form';
import { Textarea } from '~/common/components/Textarea/Textarea';
import { useLoadApplicationsRequest, useWaiveFeeRequest } from '~/modules/applications/models/application.hooks';
import { useForm } from 'react-hook-form';
import { useOnError } from '~/modules/app/models/app.hooks';
import { setErrorsFunction } from '~/common/libs/FormHelpers/FormHelpers';
import { useDispatch } from 'react-redux';
import { payStudentApplication } from '~/modules/stripe/redux/stripe.actions';
import { baselinePrice, calculateAcademicDiscount, calculateCOLAAdjustment, calculateSelfFundedDiscount, calculateAffordabilityDiscount, calculatePayment } from '~/modules/applications/models/application.model';
import { useGetStudentApplication } from '~/modules/applications/models/application.hooks';
import { loadStripe } from '@stripe/stripe-js';
import { useRef} from "react";

const customFeeFormName = 'custom_fee';
const reasonsFormName = 'waive_fee_reasons';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

export const WaiveFeeModal = ({application, previousPage}) => {

  const loadApplications = useLoadApplicationsRequest();
  const waiveFee = useWaiveFeeRequest();
  const { register, handleSubmit, watch, setError, errors } = useForm();
  const setErrors = setErrorsFunction(setError);
  const onError = useOnError(setErrors);
  const dispatch = useDispatch();

  const customFee = watch(customFeeFormName);

  const studentApplication = useGetStudentApplication(application.student_application)?.data;

  const isFunded = false
  const academicDiscount = studentApplication && calculateAcademicDiscount(studentApplication.is_industry)
  const colaAdjustment = studentApplication && calculateCOLAAdjustment(studentApplication.cola, studentApplication.is_industry)
  const selfFundedDiscount = studentApplication && calculateSelfFundedDiscount(isFunded, studentApplication.cola, studentApplication.is_industry)
  const affordabilityDiscount = studentApplication && calculateAffordabilityDiscount(isFunded, studentApplication.cola, studentApplication.is_industry)
  const recommendedPayment = (baselinePrice - academicDiscount - colaAdjustment - selfFundedDiscount - affordabilityDiscount).toFixed(2)

  const isPriceZero = Math.floor(recommendedPayment * 100) <= 0;
  const waiveReason = isPriceZero ? '$0 USD Course Fee' : ''
  const formRef = useRef();

  const onWaive = data => {
    waiveFee(application.student_application, data[reasonsFormName])
      .then(() => {
        loadApplications();
      })
      .catch(onError);
  };

  const onPay = data => {
    const locationUrl = window.location.href;
    const price = Math.floor(customFee * 100);
    dispatch(
      payStudentApplication({
        courseApplication: application.id,
        amount: price,
        successUrl: locationUrl,
        cancelUrl: locationUrl,
        isFunded,
        recommendedPayment: Math.floor(recommendedPayment * 100),
      })
    ).then(async response => {
      const sessionId = response.data?.session_id;
      const stripe = await stripePromise;
      stripe.redirectToCheckout({
        sessionId,
      });
    });
  };

  return (
     <Block className="student-application-status" title="Enrollment: Waive Fee">
        <Form ref={formRef}>
          <Form.Row>
          <Button className="student-application-status__go-back" onClick={() => {previousPage()}}>
            Go Back
          </Button>
          </Form.Row>
          {!isPriceZero &&<div>
          <Form.Row>
            Neuromatch’s goal is to be affordable to everybody, but every dollar helps our TAs and other science programs.
              <br></br><br></br>
              Your recommended payment after discounts is $ {recommendedPayment} but you can decide on a cost that you can afford. If you can’t afford even $2, do not worry, you can enroll without paying below.
          </Form.Row>
          <Form.Row>
             Enter the custom fee you'd like to pay (in USD):{' '}
                  <Input type="number"
                         min={2}
                         // value={320}
                         // onChange={(e) => setInput(e.target.value)}
                         name={customFeeFormName}
                         placeholder={recommendedPayment}
                         innerRef={register}
                         step={1} />{' '}
            </Form.Row>
          <Form.Row className="student-application-status__pay-button">
            <Button  onClick={onPay}
            className="student-application-status__pay-button">
              Pay now
            </Button>
          </Form.Row> </div>}
          <Form.Row>
            If you want to support Neuromatch, there are many other ways you can help us on our mission to increase access to science.
            <br></br> <br></br>
            Please consider one of the following:
             <ul>
              <li>Share your excitement for our course on social media </li>
              <li>Share the course with colleagues or classmates </li>
              <li>Volunteer at Neuromatch </li>
              <li>Suggest colleagues or classmates to volunteer </li>
              <li>Ask your school or institution about providing credit for this course and helping us give more credit around the world </li>
            </ul>

              Please, explain the situation that prevents you from being able to pay using the field below.

          </Form.Row>
          <Form.Row className="waive-fee__footer">
            <div className="waive-fee__textarea-wrapper">
              <Textarea
                placeholder="...reasons..."
                defaultValue={waiveReason}
                rows={3}
                className="waive-fee__textarea"
                name={reasonsFormName}
                innerRef={register({ required: "Required field",
                    minLength: {
                        value:   200 * !isPriceZero,
                        message: "Mininimum length is 200 characters"
                 }})}
                error={errors[reasonsFormName]?.message}
              />
            </div>
            </Form.Row>
            <Form.Row className="student-application-status__buttons">
              <Button className="student-application-status__buttons" onClick={handleSubmit(onWaive)}>
                Enroll without paying
              </Button>
          </Form.Row>
        </Form>
    </Block>
  );
};
