import { Link } from '~/common/components/Link/Link';
import Logo from '~/common/assets/img/header/neuromatch-academy-big-logo.svg';
import SmallLogo from '~/common/assets/img/header/Logo_white.svg';
import { useUserWrapper } from '~/modules/user/models/user.hooks';
import { useDispatch } from 'react-redux';
import { logout } from '~/modules/user/redux/user.actions';
import { userRoutes } from '~/modules/user/router/Router';
import { applicationsRoutes } from '~/modules/applications/router/Router';
import { LinkWithSubmenu } from '~/common/components/Layout/components/LinkWithSubMenu/LinkWithSubmenu';

export const Header = () => {
  const userWrapper = useUserWrapper();
  const dispatch = useDispatch();
  const logoutCallback = e => {
    e?.preventDefault();
    dispatch(logout());
  };
  const windowWidth = window.innerWidth;
  return (
    <>
      <header className="header">
        <div className="inner">
          <div className="header__logo">
            <Link to={applicationsRoutes.homepage}>
              <img src={windowWidth > 399 ? Logo : SmallLogo} alt="" />
            </Link>
          </div>
          <nav className="header__nav">
            {userWrapper && (
              <ul className="header__list">
                <li>
                  <LinkWithSubmenu text={<>Welcome, {userWrapper.getFirstName()}</>}>
                    <Link to={applicationsRoutes.homepage}>Home</Link>
                    <Link to={userRoutes.account}>Profile</Link>
                    <Link to={userRoutes.logistics}>Logistics</Link>
                    <Link to={userRoutes.applications}>Applications</Link>
                    <Link to={userRoutes.media}>Content</Link>
                    <Link to={applicationsRoutes.helppage}>Help</Link>
                    <Link to="#" onClick={logoutCallback}>Logout</Link>
                  </LinkWithSubmenu>
                </li>
              </ul>
            )}
          </nav>
        </div>
      </header>
    </>
  );
};
