import { githubId, googleId, orcidId, orcidRedirectURI, isOrcidSandbox } from '~/modules/user/models/user.model';
import LoginGithub from 'react-github-login';
import { GoogleLogin } from 'react-google-login';
import React, { useCallback } from 'react';
import { useGithubResponse, useGoogleResponse, useORCIDResponse } from '~/modules/user/models/user.hooks';
import OrcidLogin from 'react-orcid-login';
import { disconnectSocialAccount } from '~/modules/user/redux/user.actions';
import { useDispatch } from 'react-redux';
import { useGetSocialAccounts } from '~/modules/user/models/user.hooks';

export const SocialServices = ({
  className = '',
  isLogin = true,
  withText = true,
  getTextButtonFn,
  isConnect = false
}) => {
  const githubResponse = useGithubResponse(isConnect);
  const googleResponse = useGoogleResponse(isConnect);
  const orcidResponse = useORCIDResponse(isConnect);

  const getTextButton = useCallback(
    service => {
      return isLogin ? 'Sign in with ' + service : 'Sign up with ' + service;
    },
    [isLogin]
  );

  const dispatch = useDispatch();
  const { socialAccounts, loading, error } = useGetSocialAccounts();

  const connectedSocialAccount = {
    orcid: false,
    github: false,
    google: false,
  };
  if (isConnect) {
    if (socialAccounts) {
      socialAccounts.forEach(account => {
        connectedSocialAccount[account.provider] = true;
      })
    }
  }

  const handleDisconnect = (socialAccount) => {
    const doDisconnect = window.confirm(`Are you sure you want to remove ${socialAccount} from your account?\n\n You will not be able to create an account with the same email and you will not be able to sign-in with ${socialAccount} until you reconnect from your profile.`);
    if (doDisconnect) {
      const acc = socialAccounts.find(account => account.provider === socialAccount)
      dispatch(disconnectSocialAccount(acc.id, acc.provider));
    }
  };
  // const queryParams = new URL(window.location.href).searchParams;
  // const isGithub = !!queryParams.get('github');
  return (
    <div className={'social-services ' + className}>
      {withText && <div className="text">Or use one of these services:</div>}
      <div className="buttons">
        {connectedSocialAccount.orcid ? (
          <button className="social-services__orcid" onClick={() => handleDisconnect('orcid')}>Disconnect from ORCID</button>
        ):(
          <OrcidLogin
            clientId={orcidId}
            buttonText={getTextButtonFn ? getTextButtonFn('ORCID') : getTextButton('ORCID')}
            onSuccess={orcidResponse}
            onFailure={orcidResponse}
            scope="/authenticate /read-limited /activities/update"
            redirectUri={orcidRedirectURI}
            className="social-services__orcid"
            isSandbox={isOrcidSandbox}
          />
        )}
        {connectedSocialAccount.github ? (
          <button className="social-services__github" onClick={() => handleDisconnect('github')}>Disconnect from github</button>
        ):(
          <LoginGithub
            clientId={githubId}
            className="social-services__github"
            onSuccess={githubResponse}
            onFailure={githubResponse}
            scope="user"
            redirectUri=""
            buttonText={getTextButtonFn ? getTextButtonFn('Github') : getTextButton('Github')}
          />
        )}
        {connectedSocialAccount.google ? (
          <button className="social-services__google_disconnect" onClick={() => handleDisconnect('google')}>Disconnect from google</button>
        ):(
          <GoogleLogin
            clientId={googleId}
            buttonText={getTextButtonFn ? getTextButtonFn('Google') : getTextButton('Google')}
            onSuccess={googleResponse}
            onFailure={googleResponse}
            cookiePolicy={'single_host_origin'}
            className="social-services__google"
          />
        )}
      </div>
    </div>
  );
};
