import { Table } from '~/common/components/Table/Table';
import { useEffect, useState } from 'react';
import { useGetORCID, useGetCertificatesRequest, useGetReceiptsRequest } from '~/modules/user/models/user.hooks';
import dayjs from 'dayjs';
import { Link } from '~/common/components/Link/Link';
import utc from 'dayjs/plugin/utc';
import { certificateTypesLabels } from '~/modules/user/models/user.model';
import { postCertToORCID, deleteCertFromORCID } from '~/modules/user/redux/user.actions';
import { userRoutes } from '~/modules/user/router/Router';
import { useDispatch } from 'react-redux';
dayjs.extend(utc);

export const Documents = (props) => {
  const [receipts, setReceipts] = useState(null);
  const [certificates, setCertificates] = useState(null);
  const getReceipts = useGetReceiptsRequest();
  const getCertificates = useGetCertificatesRequest();
  const { ORCID, Loading , Error } = useGetORCID();
  const certRecordMap = props.certRecordMap;
  const dispatch = useDispatch();


  useEffect(() => {
    getReceipts().then(response => {
      setReceipts(response.data?.results || []);
    });
    getCertificates().then(response => {
      setCertificates(response.data?.results || []);
    });
  }, [getReceipts, getCertificates]);
  
  const handleRemove = (data) => {
    const doDisconnect = window.confirm(`Are you sure you want to remove the certificate from your ORCID record?`);
    if (doDisconnect) {
      dispatch(deleteCertFromORCID(data)).then(resp => {
        props.setDataChanged(prev => !prev);
      });
    }
  };

  const handlePublish = (data) => {
    dispatch(postCertToORCID(data)).then(resp => {
      props.setDataChanged(prev => !prev);
    });
  };

  const receiptsRows = (receipts || []).map(receipt => {
    const date = dayjs(receipt.created).utc().format('MMMM DD, YYYY');
    const amount = receipt.amount + '$';
    const link = (
      <Link to={receipt.receipt_url} newTab isCrossDomain>
        Receipt
      </Link>
    );
    return [date, amount, link];
  });


  const certificatesRows = (certificates || []).map(certificate => {
    const date = dayjs(certificate.created).utc().format('MMMM DD, YYYY');
    const type = certificateTypesLabels[certificate.certificate_type];
    const cert_id = certificate.id;
    const record_id = certRecordMap[cert_id];
    // Check whether cert date is after the requirment to complete 
    // survey was implemented
    const start_date = new Date("01/01/2024");
    const created_date = new Date(certificate.created);

    let link = ""; 
    let publish_link = "";
    if (!ORCID) {
      publish_link = ("Please connect to ORCID")
    } else if (!certificate.completed_survey && created_date > start_date) {
      publish_link = ("");
    } else if (record_id) {
      publish_link = (
        <button className='btn-orcid-publish' onClick={() => handleRemove({record_id: record_id})}>Remove Record from ORCID</button>
      )
    } else {
      publish_link = (
        <button className='btn-orcid-publish' onClick={() => handlePublish({"cert_id": cert_id})}>Publish Record to ORCID</button>
      )
    }
    // Check whether cert date is after the requirment to complete 
    // survey was implemented
    if (created_date > start_date) {
      // check if survery completed
      if (certificate.completed_survey) {
        link = (
          <Link to={userRoutes.certificate + '/' + certificate.id} newTab>
            Certificate
          </Link>
        );
      // Display instruction text if survey incomplete
      } else {
        link = ("Please complete your end of course survey and wait up to 48hrs to recieve your certificate."
        );
      }
    // cert was generated before the requirement to fill out survey
    } else {
      link = (
        <Link to={userRoutes.certificate + '/' + certificate.id} newTab>
          Certificate
        </Link>
      );
    }
    return [date, type, link, publish_link];
  });

  const receiptsAreLoaded = !!receipts;
  const receiptsExist = receipts?.length > 0;

  const certificatedAreLoaded = !!certificates;
  const certificatesExist = certificates?.length > 0;

  return (
    <div className="documents">
      <div className="documents__receipts">
        {receiptsAreLoaded && (
          <>
            {receiptsExist ? (
              <>
                <div className="title">Receipts</div>
                <div className="receipts">
                  <Table
                    headCells={['Date', 'Amount', 'Link']}
                    rows={receiptsRows}
                    className="documents__table"
                  />
                </div>
              </>
            ) : (
              <>No receipts</>
            )}
          </>
        )}
      </div>
      <div className="documents__certificates">
        {certificatedAreLoaded && (
          <>
            {certificatesExist ? (
              <>
                <div className="title">Certificates</div>
                <div className="documents">
                  <Table headCells={['Date', 'Type', 'Link', 'Publish']} rows={certificatesRows} className="documents__table" />
                </div>
              </>
            ) : (
              <>No certificates</>
            )}
          </>
        )}
      </div>
    </div>
  );
};
