import { Form } from '~/common/components/Form/Form';
import { Select } from '~/common/components/Select/Select';
import { useForm } from 'react-hook-form';
import { Button } from '~/common/components/Button/Button';
import { useEffect } from 'react';
import { Input } from '~/common/components/Input/Input';
import { Checkbox } from '~/common/components/Checkbox/Checkbox';
import { Textarea } from '~/common/components/Textarea/Textarea';
import { setErrorsFunction } from '~/common/libs/FormHelpers/FormHelpers';
import { AsyncSelect } from '~/common/components/Select/AsyncSelect';
import { Markdown } from '~/common/components/Markdown/Markdown';
import { Tooltip } from '~/common/components/Tooltip/Tooltip';
import { applicationsRoutes } from '~/modules/applications/router/Router';
import { userRoutes } from '~/modules/user/router/Router';
import { useHistory } from "react-router-dom";
import { useSelector } from 'react-redux';
import { selectApplications } from '~/modules/applications/redux/applications.selectors';
import {
  statusesOptions,
  heldPositionTimesOptions,
  timeSlots,
  timeSlotsLabels,
  timeSlotsOptions,
  languages,
  languagesOptions,
  languagesLabels,
  statuses,
  contestedAreas,
  gendersOptions,
  races,
  racesLabels,
  parentsCompletedCollegeOptions,
  socioeconomicSituationsOptions,
  genders,
  genderLabels,
  dependantsOptions,
  disabilitiesOptions,
  disabilities,
} from '~/modules/user/models/user.model';
import { useUser } from '~/modules/user/models/user.hooks';
import { useDispatch } from 'react-redux';
import {
  createGeneralInfo,
  createLogistic,
  createDemographic,
  searchInstitutes,
  update,
  updateGeneralInfo,
  updateLogistic,
  updateDemographic
} from '~/modules/user/redux/user.actions';
import { Link } from '~/common/components/Link/Link';
import { Collapse } from '~/common/components/Collapse/Collapse';
import { loadCourses } from '~/modules/applications/redux/applications.actions';
import { useLoadCountriesRequest, useOnError, useSnackbar, useRef} from '~/modules/app/models/app.hooks';
import { noValueCodes, scrollToFirstError } from '~/modules/app/models/app.model';

import { applicationStatuses, applicationStatusesLabels, yesOrNo } from '~/modules/applications/models/application.model';

const logisticsFormNames = {
  preferred_time_slot: 'preferred_time_slot',
  second_preferred_time_slot: 'second_preferred_time_slot',
  possible_time_slots: 'possible_time_slots',
  preferred_language: 'preferred_language',
  possible_languages: 'possible_languages',
  fill_in_languages: 'fill_in_languages',
  comfort_english: 'comfort_english',
};

Object.entries(logisticsFormNames).forEach(([key, value]) => {
  logisticsFormNames[key] = 'logistic.' + value;
});

const checkboxLanguages = { ...languages };
delete checkboxLanguages[languages.english];

export const LogisticsInfo = ({ courses }) => {
  const history = useHistory();
  const user = useUser();
  const defaultValues = { ...user };
  const { register, handleSubmit, setValue, errors, setError, trigger, watch } = useForm({ defaultValues });
  const dispatch = useDispatch();
  const setErrors = setErrorsFunction(setError);
  const { setSnackbar } = useSnackbar();
  const onError = useOnError(setErrors);

  // Course statuses are established during model serialization, considering the course deadlines
  // Logic checks whether all course application deadlines have ended
  const applications_status = courses.flatMap((course) => { return [course.student_application_status, course.ta_application_status]}) 
  let countApplicationsClosed = applications_status.filter( status => status == applicationStatuses.deadline_ended).length
  const isDeadLineEnded = applications_status.length == countApplicationsClosed

  const applications = useSelector(selectApplications);

  const applicationOptions = applications.flatMap((apl) => {
    return { label : apl.course_name + ' ' + apl.application_type , value : apl.course_name + ' ' + apl.application_type }
  })

  const onSubmit = data => {
    // TODO: Refactor this submit
    if (!user.logistic) {
      const requests = [];
      if (!user.logistic) {
        const logistics = { ...data.logistic, user: user.id };
        requests.push(dispatch(createLogistic(logistics)));
      } else {
        requests.push(dispatch(updateLogistic(data.logistic, user.logistic.id)));
      }

      // prefixes for setting errors to form
      const prefixesForErrors = ['logistic.'];

      let isSuccessUpdate = true;

      Promise.allSettled(requests).then(results => {
        // update courses
        dispatch(loadCourses());
        let allErrors = {};
        results.forEach((response, index) => {
          if (response.status === 'rejected') {
            const errors = response?.reason?.response?.data;
            isSuccessUpdate = false;
            if (errors) {
              const errorsWithPrefix = {};
              Object.entries(errors).forEach(([key, value]) => {
                errorsWithPrefix[prefixesForErrors[index] + key] = value;
              });
              allErrors = { ...allErrors, ...errorsWithPrefix };
              setErrors(errorsWithPrefix);
            }
          }
        });
        if (isSuccessUpdate) {
          // history.push(applicationsRoutes.homepage);
          history.push(userRoutes.applications);
          setSnackbar('Logistics updated');
        } else {
          scrollToFirstError(allErrors);
          const missingField = Object.values(allErrors).find(([value]) => {
            return value?.code === noValueCodes.null || value?.code === noValueCodes.blank;
          });
          if (missingField) {
            setSnackbar('Required fields missing');
          } else {
            if (!data.logistic.comfort_english) {
              setSnackbar('You must be comfortable in English to apply.');
            } else {
              setSnackbar('An error appeared');
            }
          }
        }
      });
    } else {
      //history.push(applicationsRoutes.homepage);
      // dispatch(update(data))
      // catch not checked comfort_english and display snackbar
      if (!data.logistic.comfort_english) {
        setSnackbar('You must be comfortable in English to apply.');
        return;
      }
      dispatch(updateLogistic(data.logistic, user.logistic.id))
        .then(() => {
          // history.push(applicationsRoutes.homepage);
          history.push(userRoutes.applications);
          setSnackbar('Logistics updated');
        })
        .catch(onError);
    }
  };

  const setValueWithTrigger = (name, value) => {
    setValue(name, value);
    trigger(name);
  };

  // registering selects
  useEffect(() => {
    register({ name: logisticsFormNames.preferred_time_slot });
    register({ name: logisticsFormNames.second_preferred_time_slot });
    register({ name: logisticsFormNames.preferred_language });
    register({ name: logisticsFormNames.comfort_english });
  }, [register]);

  return (
    <div className="logistics-info">
      <Form className="logistics-info__form" onSubmit={handleSubmit(onSubmit)}>

        <Collapse title="Logistics" defaultExpanded={true}>
{/*           // <Markdown>
          // {       "`PLEASE NOTE THAT YOU CAN CHANGE THE FOLLOWING LOGISTICS QUESTIONS UNTIL THE APPLICATION DEADLINE, BUT IT WILL BE FINAL AFTER.` " }
          // </Markdown> */}
          <Markdown>
            {
            "During our courses, small groups (which we call **pods**) of around 10-20 students learn together, guided by a teaching assistant. To form these small groups, we use the [neuromatch algorithm](https://elifesciences.org/labs/5ed408f4/neuromatch-algorithms-to-match-scientists), which matches students based on time zone preferences, common interests, and preferred language. **We will use the information you input below to match you with your group. The matching is impossible to change later, so please select your answers carefully.** " +
            "  \n" +
            "  \n" +
            "**Time slots**: " +
            "  \n" +
            "  \n" +
            "Our courses are distributed around the globe which means that participants can’t be awake at the same time. Therefore we have created different time slots to make it easier to learn during a time that works best for you.  You’ll be able to choose from five different time slots, each of which is around 8 hours in duration per day of the course (split into time to work on coursework and time to work on a research project with your group)."  +
            "  \n" +
            "  \n" +
            "Time slots are one of the most important matching constraints! They determine when you will be available online during the course to interact and learn together with your podmates.  Selection of your time slot can be confusing and will be impossible to change later so **please choose only those time slots in which you are confident that you won't have any issues with being online for the course.** We strongly recommend you spend some time with the tool below, which allows you to choose your local timezone and see the time slot options in your local time. "
            }
          </Markdown>
          <Form.Row>

            <Form.Label> </Form.Label>
            <div className='timeslot-widget'>
            <iframe title="timeslot-widget"
                    src="https://neuromatchacademy.github.io/widgets/tz.html"
                    frameborder="0"
                    width="100%"
                    height="100%"
                    style={{background: "transparent", border: "1px solid rbg(204,204,204)", borderRadius: "1%"}}
            ></iframe>
            </div>
          </Form.Row>

          <Form.Row className="preferences">
            {isDeadLineEnded && (
              <Tooltip  place="left">{applicationStatusesLabels.deadline_ended}</Tooltip>
            )}
            <Select
              label={
                <>
                  What is your most preferred time slot? We will try our best to match you to this time slot. Time slots can be found{' '}
                  <Link to="https://neuromatchacademy.github.io/widgets/tz.html" isCrossDomain newTab>
                    here
                  </Link>
                  {', '}or you can use the above widget.
                </>
              }
              // isClearable
              name={logisticsFormNames.preferred_time_slot}
              defaultValue={user?.logistic?.preferred_time_slot}
              options={timeSlotsOptions}
              onChange={option => {
                setValueWithTrigger(logisticsFormNames.preferred_time_slot, option?.value ?? option);
              }}
              error={errors?.logistic?.preferred_time_slot?.message}
              isDisabled={isDeadLineEnded}
            />
          </Form.Row>
          <Form.Row className="preferences">
            {isDeadLineEnded && (
              <Tooltip  place="left">{applicationStatusesLabels.deadline_ended}</Tooltip>
            )}
            <Select
              label={
                <>
                  What is your second preferred time slot?
                  {' '}  If you really can’t make another slot work, pick the same slot as your first preference.
                </>
              }
              name={logisticsFormNames.second_preferred_time_slot}
              defaultValue={user?.logistic?.second_preferred_time_slot}
              options={timeSlotsOptions}
              onChange={option => {
                setValueWithTrigger(logisticsFormNames.second_preferred_time_slot, option?.value ?? option);
              }}
              error={errors?.logistic?.second_preferred_time_slot?.message}
              isDisabled={isDeadLineEnded}
            />
          </Form.Row>
          <Markdown>
            {
            "**Languages**: " +
            "  \n" +
            "  \n"
            }
            </Markdown>
          <Form.Row className="preferences">
            {isDeadLineEnded && (
              <Tooltip  place="left">{applicationStatusesLabels.deadline_ended}</Tooltip>
            )}
            <Select
              label=<div>
                      <p>Select your preferred language to complete the course in (the language that will be spoken in your pod with your peers and TA).</p>
                      <p>Important things to note before making a selection:</p>
                      <ul>
                        <li>All course material (videos, code) is in English, but some pods use a different language for conversations between students and the TA.</li>
                        <li>If you’d like to be placed in an English speaking pod, please choose English.</li>
                        <li>If you are MORE comfortable speaking in a non-English language than English, please select it here and we will try to place you in a pod that uses that language instead of English.</li>
                        <li>Please DO NOT select an alternative language to practice speaking that language, as you will be unable to switch to English if conversations are too fast for you to follow.</li>
                        <li>We cannot guarantee admission to language pods; if you cannot be matched to a pod in your preferred language we will place you in an English-speaking pod for that course.</li>
                        <li>Choice of language will not affect your chance of being accepted to any course.</li>
                      </ul>
                    </div>
              name={logisticsFormNames.preferred_language}
              // isClearable
              defaultValue={user?.logistic?.preferred_language}
              options={languagesOptions}
              onChange={option => {
                setValueWithTrigger(logisticsFormNames.preferred_language, option?.value ?? option);
              }}
              error={errors?.logistic?.preferred_language?.message}
              isDisabled={isDeadLineEnded}
            />
          </Form.Row>

          <Form.Label>Please select additional languages that you are comfortable having scientific and educational conversations in. Note that you may be matched into pods speaking these languages.</Form.Label>
          <Form.Row className="general-info__radio-btns preferences">
            {isDeadLineEnded && (
              <Tooltip  place="left">{applicationStatusesLabels.deadline_ended}</Tooltip>
            )}
            {Object.keys(checkboxLanguages).map(lang => (
              <Checkbox
                className="general-info__checkbox"
                innerRef={register}
                name={logisticsFormNames.possible_languages}
                value={lang}
                text={languagesLabels[lang]}
                key={lang}
                // defaultChecked={user?.logistic?.possible_languages.includes(lang)}
                disabled={isDeadLineEnded}
              />
            ))}
          </Form.Row>
          <Form.Row>
            <Input
              label="Are there any languages besides those listed that you would want to use in a course?"
              name={logisticsFormNames.fill_in_languages}
              innerRef={register}
              error={errors?.logistic?.fill_in_languages?.message}
            />
          </Form.Row>
          <Form.Row>
            <Form.Label></Form.Label>
            <Checkbox name={logisticsFormNames.comfort_english}
            innerRef={register}
            text="I am comfortable learning and/or teaching in English. I understand that I may be placed in an English speaking pod if I cannot be matched to a pod in the above languages."
            error={errors?.logistic?.comfort_english?.message} />
          </Form.Row>
        </Collapse>
        <Form.Row>
          <Button type="submit" style={{marginTop: '200px'}} onClick={() => trigger()}>
            Submit
          </Button>
        </Form.Row>
     </Form>
    </div>
  );
};
