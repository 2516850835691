import { CoursesBlocks } from '~/modules/applications/pages/Home/blocks/components/CoursesBlocks/CoursesBlocks';
import { ApplicationsBlock } from '~/modules/applications/pages/Home/blocks/components/ApplicationsBlock/ApplicationsBlock';
import { Markdown } from '~/common/components/Markdown/Markdown';
import { Collapse } from '~/common/components/Collapse/Collapse';
import { Link } from '~/common/components/Link/Link';
import { Button } from '~/common/components/Button/Button';
import { useHistory } from 'react-router-dom';
import Logo from '~/common/assets/img/header/logo-black.png';

export const Home = () => {
  const history = useHistory();
  const handleClick = () => {history.push('/applications')}
  return (
    <div className="home narrow mt-20">
      {/*<ApplicationsBlock />*/}
      <img className='dual-logo' src={ Logo } alt='logo'/>
      <Collapse title="General information" defaultExpanded={false}>
        <Markdown>
          {
            "**What is this program?**" +
            "  \n" +
            "  \n" +
            "We seek to democratize science by making science education inclusive and globally accessible. Our goal is to empower people from different backgrounds by providing access to education, networking, mentorship, and research opportunities." +
            "  \n" +
            "  \n" +
            "In our programs, students are matched into small groups and work together online, with an instructor, through interactive problems, group projects, and professional development experiences." +
            "  \n" +
            "  \n" +
            "The courses run in parallel, so you can only be a part of one course per year. You can, however, submit up to three total applications. You will be asked to provide a preferred order of acceptance for all applications you submit. You will not be able to enroll in a lower preference if accepted to a higher preference as they take place at the same time." +
            "  \n" +
            "  \n" +
            "You can read more about our individual courses and their goals in the sections below.          " +
            "  \n" +
            "  \n" +
            "  \n" +
            "**Who is Neuromatch, Inc.?**" +
            "  \n" +
            "  \n" +
            "Neuromatch, Inc. is volunteer-lead organization, run by hundreds of science enthusiasts from all over the world. From students to faculty to industry professionals, our volunteers are invested in creating globally-accessible science education and building inclusive communities for scientists to learn, grow, network, and discover. If you would like to volunteer for our Academy, visit this page: [https://neuromatch.io/volunteer](https://neuromatch.io/volunteer)." +
            "  \n" +
            "  \n" +
            "  \n" +
            "**Why should you apply to our courses?**" +
            "  \n" +
            "  \n" +
            "Our courses all provide a dynamic learning experience in which you collaborate with a global community of experts and peers, hone your problem-solving skills, complete mentored research projects, and build a supportive network that celebrates inclusivity and accessibility in science education. In the end, you’ll receive a course certificate, will have completed a piece of your own poster-worthy research project, and will leave with a new, strong network of peers.           " +
            " \n" +
            "Apply to unlock your potential and propel your career forward." +
            "  \n" +
            "  \n"
          }
        </Markdown>
      </Collapse>
            {/*<Collapse title="What it means to be a student?">
        <Markdown>
          {
            "**This is an application for being a student at the Computational Tools for Climate Science  course in 2023.  If you have difficulty understanding some of it, consider using Google translate or another service.**" +
            "  \n" +
            "  \n" +
            "Course content is in English, limited amount of translations are/will be available for video content for certain languages. If you are in a language-specific pod, your TA will provide you the aid to overcome the language barrier. Due to technical difficulties it is not possible to provide the entire course translated, so some English proficiency is needed & encouraged." +

            "(highlighted as purple) Please note that enrolled students are expected to dedicate full-time effort, at least 8 hours a day and 5 days a week, to this 2-week intensive course. According to the experience of past students in Neuromatch Academy, it is very difficult  to complete this course while maintaining a full-time job." + "**What do we expect from students during the program?**" + "This is a tutorial-based teaching and community-building endeavor, with everything taught in Google Colab or Kaggle and Python. You don’t have to install any software, and everything will be taught through a combination of brief lectures, hands-on coding exercises, and professional development support." + "You will be placed in a pod of ~15 students and a teaching assistant . Each pod will be split into subgroups for research projects. Throughout the whole 2-week course, you will work on a research project related to one of eight research themes (descriptions:https://neuromatch.io/climate-science ) and be advised by an additional specialist project teaching assistant ." +
            "**Expertise required?**" +

            "All students need to have basic coding proficiency in Python, and should, for example, be able to write a small Python script/notebook. Those familiar with another coding language are highly encouraged to complete our asynchronous Python course based on content developed by Project Pythia [https://foundations.projectpythia.org/landing-page.html] before the beginning of course." +

            "Even though our course teaches cutting-edge computational tools in climate science, each topic is self-contained and no prior knowledge or experience in climate science is required. Our course requires only a fundamental science background in topics of math, statistics, physics and chemistry. If you do not have these skills or knowledge before the course, there are *lots* of options for catching up before you begin!  We will provide a detailed list of topics and recommended resources to help you get the background you need to succeed and get the most out of the course [a link that describes these in detail]" +

            "**How will selection occur?**" +
            "We strive to obtain representation in our course from every region on the globe. We will be making our decision based mainly on your likelihood  to complete the course, and how we can maximize the gender, geographic and economic diversity of the applicants. We want to emphasize that your level of proficiency in math, physics and chemistry is NOT a criteria for selection as long as you have the foundational knowledge mentioned above."
          }
        </Markdown>
      </Collapse>*/}

      <Collapse title="Course Information" defaultExpanded={false}>
        <Markdown>
            {
            "**Current course offerings**"
        }
        </Markdown>
        <CoursesBlocks withButtons={false}/>
        <Markdown>
            {
            " \n" +
            "  \n" +
            "  \n" +
            "**How is each course structured?**" +
            "  \n" +
            "  \n" +
            " Each course takes place entirely online.  Students work in small learning groups, or pods, made up of ~15 students and teaching assistants. These groups are formed using the [neuromatch algorithm](https://elifesciences.org/labs/5ed408f4/neuromatch-algorithms-to-match-scientists), which matches students based on common interests, timezone, and preferred language. Our courses are delivered around the globe with many timeslot options to fit your schedule. " +
            "  \n" +
            "  \n" +
            "Each day, students will spend around 5 hours covering curriculum: a short keynote talk, followed by ~4.5 hours (with breaks) working on a series of python tutorials, guided by mini-lectures, where they implement analyses or models relevant to the day’s topic. Finally, students will spend ~3 hours working on research projects and professional development activities with input from several project and professional mentors." +
            "  \n" +
            "  \n" +
            "  \n" +
            "**Diversity and Inclusivity**" +
            "  \n" +
            "  \n" +
            "Diversity is integral to our mission of providing excellent educational, training, and professional development opportunities to students worldwide. By nurturing a diverse community, we aim to broaden and deepen the educational experiences we provide to students, and to enhance the scholarly environment for teaching assistants, mentors, instructors, and organizers. We  thus strive to advance inclusive excellence in our student, teaching assistant, instructor, mentor, and organizer populations."+
            "  \n" +
            "  \n" +
            "We actively encourage everyone to apply: applicants will receive consideration for inclusion in the our programs without regard to race, color, religion, sex, sexual orientation, gender identity, disability, age*, protected veteran status, or other protected categories covered by relevant nondiscrimination policies or laws. We will collect voluntary data on the race or gender of its students, employees, contractors and applicants to gauge the effectiveness of its efforts. We will report only summary statistics and not individual data points wherever we use this data." +
            "  \n" +
            "  \n" +
            "*Due to legal and liability restrictions on enrolling minors, we unfortunately cannot accommodate students or teaching assistants who are under the age of 18." +
            "  \n"
        }
        </Markdown>
      </Collapse>
      <Collapse title="Information for prospective students" defaultExpanded={false}>
        <Markdown>
            {
            "**What do we expect from students during the course?**" +
            "  \n" +
            "  \n" +
            "Enrolled students are expected to dedicate full-time effort, at least 8 hours a day and 5 days a week, to these intensive courses for their duration (2-3 weeks depending on the course). According to the experience of past students in Neuromatch Academy, it is very difficult  to complete this course while maintaining a full-time job.  " +
            "  \n" +
            "  \n" +
            "This is a tutorial-based teaching and community-building endeavor, with everything taught in Google Colab or Kaggle and Python. You don’t have to install any software, and everything will be taught through a combination of brief lectures, hands-on coding exercises, and professional development support.   " +
            "  \n" +
            "  \n" +
            "You will be placed in a **pod** of ~15 students and a teaching assistant . Each pod will be split into subgroups for research projects. Throughout the whole course, you will work on a research project and be advised by project specific teaching assistants/mentors.  More information about types of projects in each course can be found under the course-specific description. " +
            "  \n" +
            "  \n" +
            "Students who complete the course and/or the project component will receive a personalized certificate of completion. Please note that missing several days (2+ days) of the course may result in no certificate being granted. If you know of existing conflicts and will not be able to attend the entire course with no more than 1-2 days of absence, you should not enroll this year.  " +
            "  \n" +
            "  \n" +
            "  \n" +
            "**What does it cost?**"+
            "  \n" +
            "  \n" +
            "Traditional summer schools can be expensive (~$5,000 for a three-week course), but we promise to be always affordable while still ensuring our teaching assistants get paid. To do this, we charge a much lower, regionally adjusted fee, and provide fee waivers for students that need them without any impact on their admission. " +
            "  \n" +
            "  \n" +
            "While we are supported by generous donations from a variety of foundations and industry partners, we strive to make the program sustainable through tuition fees, which are used to pay our teaching assistants. We do not want fees to be a barrier for anybody, so these fees can be waived, but if you can pay even part of your fee, this is helpful. There are even opportunities to pay more than your fee if you would like to help subsidize fee waivers for other students with less financial means than yourself. " +
            "  \n" +
            "  \n" +
            "To estimate your course fees based on your region, use our [COLA Calculator](https://neuromatchacademy.github.io/widgets/cola.html)." +
            "  \n" +
            "  \n" +
            "  \n" +
            "**What expertise is required?**" +
            "  \n" +
            "  \n" +
            "Generally our programs are currently made for people that have some foundational knowledge in the following topics: " +
            "  \n" +
            " * Coding (a basic understanding of Python is typically required; we offer a python pre-course to help with this) " +
            "  \n" +
            " * Mathematics" +
            "  \n" +
            " * Statistics" +
            "  \n" +
            "  \n" +
            "Specific information on course requirements can be found in the course descriptions above" +
            "  \n" +
            "  \n" +
            "  \n" +
            "**How will selection occur?**" +
            "  \n" +
            "  \n" +
            "We strive to obtain representation in our course from every region on the globe. We will be making our decision based mainly on your likelihood  to complete the course, and how we can maximize the gender, geographic and economic diversity of the applicants." +
            "  \n" +
            "  \n" +
            "  \n" +
            "**What is the timeline?**" +
            "  \n" +
            "  \n" +
            "Applications open in late March or early April each year and close towards the end of April. Generally we take about one month to get back to prospective students with acceptance emails at which point you will have a few weeks to decide if you will enroll in the course. Courses tend to start July and last several weeks. You can find specific deadlines and dates in the course descriptions above." +
            "\n"
        }
        </Markdown>
      </Collapse>
      <Collapse title="Information for prospective teaching assistants" defaultExpanded={false}>
          <Markdown>
            {
              "**What are the responsibilities of teaching assistants?**" +
              "  \n" +
              "  \n" +
              "During the course, students will work on tutorials for 3.5h each day and on group research projects for another 2-3h. To guide them through both activities, we will have two types of teaching assistants: regular and project teaching assistants. Regular teaching assistants form a “pod” with a single group of students and guide them through daily tutorials and assist in their research projects. In contrast, project teaching assistants  are assigned to several groups, but only assist with research projects and not with tutorials. The hourly commitment and stipend are the same for both roles. You can indicate your preference for being either a regular teaching assistant or a project teaching assistant. We can't guarantee your preferred choice, but we will try to accommodate. " +
              "  \n" +
              "  \n" +
              "**Regular** teaching assistants would be responsible for a pod of students (~15),  and will be in direct video contact during the 4.5 hours (including 1h break plus 2x15' discussion) for lecture-tutorials sessions, one session a day. Additionally, every day they will dedicate 1.5 hours to help students with their projects. In summary: during the course, this will be a high effort, requiring approximately 6 synchronous hours with your pod and 2+ hours for preparation (total of 8+hour daily commitment), 5 days a week.  " +
              "  \n" +
              "  \n" +
              "**Project** teaching assistants are typically senior graduate students or postdocs with experience in the course topic, who guide and help several project groups (~7). A project group typically consists of ~6 students.  Project teaching assistants specialize in at least one of the data sets or projects that students can work on.  You need to select your preferred project topic in the application (project topics are listed for each course in the descriptions above). Project teaching assistants typically meet with each of their groups every 1-2 days for 1-2h at a time. In summary: during the course, this will be a high effort, requiring approximately 6 synchronous hours with your project groups and 2+ hours for preparation (total of 8+hour daily commitment), 5 days a week. " +
              "  \n" +
              "  \n" +
              "We will also need **lead teaching assistants** who have additional leadership responsibilities. Ideally lead teaching assistants have been a teaching assistant with us previously. Responsibilities include advising teaching assistants, managing teaching assistant absences, and guiding less experienced teaching assistants on content. If you’re interested in being a Lead teaching assistant, please indicate that in the application form " +
              "  \n" +
              "  \n" +
              "All teaching assistants will undergo teaching assistant training. You will receive material to prepare beforehand and you will attend two sessions (~2 hours each) before the course. The material will consist of videos about different topics related to all teaching assistants and also specific to your role as well as a period of live instruction for you to ask questions." +
              "  \n" +
              "  \n" +
              "  \n" +
              "**What do we expect from teaching assistants?**" +
              "  \n" +
              "  \n" +
              "Teaching Assistants are expected to guide the students through the program by facilitating their curriculum-based learning experience. As such, teaching assistants are problem-based learning facilitators, more than teachers (read more about  problem-based learning (PBL) philosophy in [Yew & Goh, 2016](https://www.sciencedirect.com/science/article/pii/S2452301116300062))." +
              "  \n" +
              "  \n" +
              "Main responsibilities are:" +
              "  \n" +
              " * Provide support, empathy, and inspiration " +
              "  \n" +
              " * Facilitate thinking, growth, and engagement" +
              "  \n" +
              " * Guide students to answers rather than directly giving them answers " +
              "  \n" +
              "  \n" +
              "  \n" +
              "**What do we offer teaching assistants?**" +
              "  \n" +
              "  \n" +
              "We will provide a stipend (amounts below) for those teaching assistants legally allowed to accept it (depending on tax or visa details). This is a lump payment for the course as well as any training prior to the course. In accordance with our organization wide [Hiring and Compensation Policy](https://docs.neuromatch.io/p/ogjO-9wZOPVo0K/Hiring-and-Compensation-Policy), TA compensation rates are adjusted based on local cost of living." +
              "  \n" +
              " * You can see your payment amound [here](https://neuromatchacademy.github.io/widgets/ta_cola.html)." +
              "  \n" +
              "  \n" +
              "We will also provide a teaching letter of support for all teaching assistants who want one, which may be particularly useful in the academic job market. To request a letter, please email recommendations@neuromatch.io after the course." +
              "  \n" +
              "  \n" +
              "Teaching assistants will receive training material on the content of the different tutorials, and will have the opportunity to ask content questions of experts during the course. As such, teaching assistants get a unique opportunity to deepen their knowledge and ask questions directly to the world expert lecturers.   " +
              "  \n" +
              "  \n" +
              "Teaching assistants help students become better, independent researchers, arming them with the skills and knowledge needed to succeed. This is a great opportunity to make an impactful difference in not just your students’ lives, but also in worldwide scientific education going forward! " +
              "  \n" +
              "  \n" +
              "  \n" +
              "**Can I get paid?**" +
              "  \n" +
              "  \n" +
              "We will provide a stipend (amounts above) for those teaching assistants legally allowed to accept it (depending on tax or visa details). Teaching assistants are recruited as independent contractors, therefore it is your responsibility to assure that you are allowed to invoice our company as an independent contractor to receive the stipend amount above.  Here is an example of the form from last year: [https://airtable.com/shrKgAKus5aGrcdmn](https://airtable.com/shrKgAKus5aGrcdmn). If you are an international student, the international center of your university can be a valuable resource to gain further information." +
              "  \n" +
              "  \n" +
              "  \n" +
              "**What expertise is required?**" +
              "  \n" +
              "  \n" +
              "All teaching assistants need to have: "+
              "  \n" +
              " * A fundamental background in the course topic (computational neuroscience, deep learning, NeuroAI, or climate science)"+
              "  \n" +
              " * Proficiency in Python"+
              "  \n" +
              "  \n" +
              "Given the breadth of topics being taught, we absolutely do not expect teaching assistants to be familiar with all sub disciplines of the course topic. Teaching assistants will participate in training sessions where they will receive curriculum materials, tutorials and the solution materials of the tutorials. We are looking for teaching assistants’ willingness to acquire the required knowledge so that they can then deliver it, demonstrating a solid understanding of the material chosen to present. Other relevant requisites are:" +
              "  \n" +
              "  \n" +
              " * Completed Undergrad" +
              "  \n" +
              " * Accepting terms of agreement" +
              "  \n" +
              " * Accepting time commitment" +
              "  \n" +
              " * Strong verbal and written communication skills in English" +
              "  \n"
            }
            </Markdown>
      </Collapse>
      <Button onClick={handleClick} className='apply-btn'>Apply Now</Button>
    </div>
  );
};
