import { Layout } from '~/common/components/Layout/Layout';
import { useTitle } from '~/modules/app/models/app.hooks';
import { Home } from '~/modules/applications/pages/Home/blocks/Home';

export const HomePage = () => {
  useTitle('Portal');
  return (
    <Layout>
      <Home />
    </Layout>
  );
};
