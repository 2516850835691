import { NewLayout } from '~/common/components/NewLayout/NewLayout';
import { CourseDashboard } from '~/modules/applications/pages/CourseDashboardPage/blocks/CourseDashboard';

export const CourseDashboardPage = () => {
  return (
    <NewLayout>
      <CourseDashboard />
    </NewLayout>
  );
};
