import { Table } from '~/common/components/Table/Table';
import { timeSlotsLabels } from '~/modules/user/models/user.model';
import { StudentInPodInfo } from '~/modules/applications/pages/CourseDashboardPage/blocks/components/StudentInPodInfo/StudentInPodInfo';
import { Block } from '~/common/components/Block/Block';
import { Checkbox } from '~/common/components/Checkbox/Checkbox';
import { Input } from '~/common/components/Input/Input';
import { useCreateOrUpdateCertificate, useCreateOrUpdateAttendance, useGetPodRequest } from '~/modules/applications/models/application.hooks';
import  React, { useState }  from 'react'
import { useSnackbar } from '~/modules/app/models/app.hooks';

export const TAPodInfo = ({ pod, setPod, application, course }) => {
  const { students } = pod;
  const { megapod_pods } = pod;
  const createOrUpdateCertificate = useCreateOrUpdateCertificate();
  const createOrUpdateAttendance = useCreateOrUpdateAttendance();
  const { setSnackbar } = useSnackbar();

  const getPod = useGetPodRequest();
  const attendanceHeader = ["Name", "Type", "Percent Attended"];

  // grab the year month and day for the course start and end dates
  // keep time in start_date so date move starts on the right day
  // remove it from the end_date so we cut off at the right time
  const startDate = course.deadlines.start_date;
  const endDate = course.deadlines.end_date.slice(0, 10);
  // loop through from start to end, incrementing a date object
  // to collect days of the course, excluding weekends
  const listDate = [];
  const dateMove = new Date(startDate);
  let strDate = startDate;

  while (strDate < endDate) {
    strDate = dateMove.toISOString().slice(0, 10);
    // only add to list if not sunday or saturday
    if (dateMove.getDay() !== 0 && dateMove.getDay() !== 6) {
      // format date to reflect what shows up in the course attendance
      let day = dateMove.toLocaleString('en-US', {day: 'numeric'})
      let month = dateMove.toLocaleString('en-US', {month: 'short'});
      let formattedDate = day + " " + month;
      listDate.push(formattedDate);
    }
    dateMove.setDate(dateMove.getDate() + 1);
  };
  const courseDays = listDate;

  const onSuccess = () => {
    getPod(pod.id).then(response => {
      setPod(response.data);
    });
  };

  function getAttendance() {
    let attendObj = {};
    students.map((student) => {

      let daysAttended = [];
      const attendanceArray = Object.keys(student.attendance).map(function(k){return student.attendance[k]});

      attendanceArray.forEach((attendance) => {
        daysAttended.push(attendance.day);
      });

      let studentAttendance = {};
      for (let i = 0; i < courseDays.length; i++) {
        if (daysAttended.includes(courseDays[i])) {
          let tempDay = attendanceArray[daysAttended.indexOf(courseDays[i])];
          studentAttendance[i] = {id: tempDay.id, day: courseDays[i], percentage: tempDay.percentage, project_percentage: tempDay.project_percentage};
        } else {
          studentAttendance[i] = {day: courseDays[i], percentage: "", project_percentage: ""};
        }
      }
      attendObj[student.id] = studentAttendance;
    });
    return attendObj;
  }

  function getAttendanceRows(student, type){
    // Calculate percentage attended for each student
    let percentage_key = (type === "tutorials") ? 'percentage' : 'project_percentage';
    let percent = 0, days = 0;
    for (let i = 0; i < courseDays.length; i++) {
      if (!isNaN(attendance[student.id][i][percentage_key])  && typeof(attendance[student.id][i][percentage_key]) == 'number') {
        days++;
        percent += attendance[student.id][i][percentage_key]
      }
    }
    percent = (percent / days) * 100;

    let attendanceValues = [];

    for (let i = 0; i < courseDays.length; i++) {
      const onAttendanceChange = e => {
        setAttendance((prevAttendance)=> {
          let newAttendance = e.target.value
          if (newAttendance > 1) {
            newAttendance = 1;
            setSnackbar("Please choose a value between 1 and 0");
          }
          else if (newAttendance < 0) {
            newAttendance = 0;
            setSnackbar("Please choose a value between 1 and 0");
          }
          let updateTutorial =  (type === "tutorials") ? newAttendance : attendance[student.id][i]['percentage'];
          let updateProject = (type === "project") ? newAttendance : attendance[student.id][i]['project_percentage'];
          createOrUpdateAttendance(attendance[student.id][i]['id'], {
            id: attendance[student.id][i]['id'],
            day: attendance[student.id][i]['day'],
            percentage: updateTutorial,
            project_percentage: updateProject
          })
            .then(onSuccess)
            .catch(e => {
              console.error(e?.response);
            });
          return {...prevAttendance, [student.id]: {...prevAttendance[student.id], [i]: {...prevAttendance[student.id][i], percentage: parseFloat(updateTutorial), project_percentage:  parseFloat(updateProject)}}};
        });

      }
      attendanceValues.push(<Input type={"number"} key={attendance[student.id][i]+type}  value={attendance[student.id][i][percentage_key]} style={{width:"40px", height:"40px", padding:"0",
                                                  margin:"0", textAlign:"center"}} onChange={onAttendanceChange}/>);
    }
    return [student.full_name, type,percent.toString().substring(0,4) + "%", ...attendanceValues]

  }
  const [attendance, setAttendance] = useState(() => getAttendance());

  const megapod_ta_rows = megapod_pods.map(pod => {
        return [pod.name, pod.ta_name, pod.ta_email, pod.language, pod.time_slot]
  });

  const rows = students.map(student => {

    const onCourseCheckboxChange = e => {
      const confirm = window.confirm('Are you sure?');
      if (confirm) {
        createOrUpdateCertificate({
          pod: pod.id,
          completedCourse: !student.completed_course,
          completedProject: student.completed_project,
          course: application.course,
          user: student.id,
        })
          .then(onSuccess)
          .catch(e => {
            console.error(e?.response?.data);
          });
      }
    };
    const courseCheckbox = <Checkbox checked={student.completed_course} onChange={onCourseCheckboxChange} />;

    const onProjectCheckboxChange = e => {
      const confirm = window.confirm('Are you sure?');
      if (confirm) {
        createOrUpdateCertificate({
          pod: pod.id,
          completedCourse: student.completed_course,
          completedProject: !student.completed_project,
          course: application.course,
          user: student.id,
        })
          .then(onSuccess)
          .catch(e => {
            console.error(e?.response?.data);
          });
      }
    };

    const projectCheckbox = <Checkbox checked={student.completed_project} onChange={onProjectCheckboxChange} />;
    return [student.full_name, courseCheckbox, projectCheckbox];
  });


  const attendanceRows = students.map(student =>  [getAttendanceRows(student, 'tutorials'), getAttendanceRows(student, 'project')]).flat();
  // Array.prototype.push.apply(attendanceRows, students.map(student =>  getAttendanceRows(student, 'project'))); 

  return (
    <>
        <Block title={application.course_name} className="ta-role-info">
            <table   className="role-table" cellPadding="0" cellSpacing="0">
              <thead>
                  <tr>
                      <th scope="col">Possible Role</th>
                      <th scope="col" className="alignright">Your Role</th>
                  </tr>
              </thead>
              <tbody>
              <tr>
                <td>Lead Regular TA</td>
                <td className="alignright">  {(application.selected_as_lead_ta && application.ta_for=='regular_TA') ? <div>&#10004;</div> : ''} </td>
              </tr>
              <tr>
                <td>Lead Project TA</td>
                <td className="alignright">{(application.selected_as_lead_ta && application.ta_for=='project_TA') ? <div>&#10004;</div> : ''}</td>
              </tr>
              <tr>
                <td>Regular TA</td>
                <td className="alignright">{(!application.selected_as_lead_ta && application.ta_for=='regular_TA') ? <div>&#10004;</div> : ''}</td>
              </tr>
              <tr>
                <td>Project TA</td>
                <td className="alignright">{(!application.selected_as_lead_ta && application.ta_for=='project_TA') ? <div>&#10004;</div> : ''}</td>
              </tr>
              </tbody>
            </table>
      </Block>
      <Block title="Your pod info" className="ta-pod-info">
        <Table
          rows={[
            ['Your pod id', pod.id],
            ['Your pod name', pod.name],
            ['Your pod time slot', timeSlotsLabels[pod.time_slot]],
            ['Your pod language', pod.language],
          ]}
        />
        {students && (
          <div className="ta-pod-info__students">
            {students?.map(student => {
              return (
                <StudentInPodInfo fullName={student.full_name} email={student.email} photo={student.profile_image} />
              );
            })}
          </div>
        )}
      </Block>
      <Block title="Your pod project info" className="ta-project-info">
        <Table
          rows={[
            ['Your pod dataset', pod.dataset],
            ['Your pod second dataset', pod?.second_dataset],
            ['Your project TA name', pod.project_ta_info?.full_name],
            ['Your project TA email', pod.project_ta_info?.email],
          ]}
        />
      </Block>

      <Block title="Your pod Zoom info" className="ta-zoom-info">
        <Table
          rows={[
            ['Meeting Link', pod?.zoom?.meeting_link],
            ['Claim host key', pod?.zoom?.claim_host_key],
            ['Login email', pod?.zoom?.login_email],
            ['Email password', pod?.zoom?.email_password],
            ['Transcripts', pod?.zoom?.transcripts],
          ]}
        />
      </Block>

    <Block title="Your Megapod Info" className="ta-megapod-info">
        <p>
            Megapod name: {pod.megapod_name}
        </p>
        <p>
            Lead TA: {pod.lead_ta_name} [email: {pod.lead_ta_email}]
        </p>
        <Table headCells={['Name', 'TA name', 'TA email', 'Language', 'Time slot']} rows={megapod_ta_rows} />
      </Block>

      <Block title="Student Attendance" className="ta-attendance">
        <p>
            <h5>Please use this table to collect attendance. </h5>
            Use a value between 1 and 0 where 1 would mean that the student was present, 0 would mean that the student was not present,
            and 0.5 would mean that the student was present for half the day.
        </p>
        <Table headCells={attendanceHeader.concat(courseDays)} rows={attendanceRows} sticky={true}/>
      </Block>

      <Block title="Student Completion" className="student-completion">
        <p>
          Once the course has concluded, please indicate if you believe each student has successfully completed the
          course. If the student participated in a project, also indicate if they successfully completed that project.
        </p>
        <Table headCells={['Name', 'Course', 'Project']} rows={rows} />
      </Block>
    </>
  );
};
