import { createSelector } from 'reselect';
import { APP_STORE_KEY, AppSliceNames } from '~/modules/app/redux/app.store';

const selectAppState = state => state[APP_STORE_KEY];

export const selectAppReady = createSelector(selectAppState, store => store[AppSliceNames.isReady]);

export const selectAppIsLoading = createSelector(selectAppState, store => store[AppSliceNames.isLoading]);

export const selectSnackbarNotifications = createSelector(
  selectAppState,
  store => store[AppSliceNames.snackbarNotifications]
);

export const selectCanRequest = createSelector(selectAppState, store => store[AppSliceNames.canRequest]);

export const selectLoginPageDescription = createSelector(
  selectAppState,
  store => store[AppSliceNames.initialData].value?.login_page_description
);

export const selectRegisterPageDescription = createSelector(
  selectAppState,
  store => store[AppSliceNames.initialData].value?.registration_page_description
);
