import { Form } from '~/common/components/Form/Form';
import { Select } from '~/common/components/Select/Select';
import { useForm } from 'react-hook-form';
import { Button } from '~/common/components/Button/Button';
import { useEffect, useState } from 'react';
import { Input } from '~/common/components/Input/Input';
import { Checkbox } from '~/common/components/Checkbox/Checkbox';
import { Textarea } from '~/common/components/Textarea/Textarea';
import { setErrorsFunction } from '~/common/libs/FormHelpers/FormHelpers';
import { AsyncSelect } from '~/common/components/Select/AsyncSelect';
import { Markdown } from '~/common/components/Markdown/Markdown';
import { Tooltip } from '~/common/components/Tooltip/Tooltip';
import { Collapse } from '~/common/components/Collapse/Collapse';
import { applicationsRoutes } from '~/modules/applications/router/Router';
import { userRoutes } from '~/modules/user/router/Router';
import { useHistory } from "react-router-dom";
import { useSelector } from 'react-redux';
import { selectApplications } from '~/modules/applications/redux/applications.selectors';
import {
  degrees,
  degreesOptions,
  years,
  statusesOptions,
  heldPositionTimesOptions,
  matchOpportunities,
  matchOpportunitiesOptions,
  timeSlots,
  timeSlotsLabels,
  timeSlotsOptions,
  languages,
  languagesOptions,
  languagesLabels,
  statuses,
  contestedAreas,
  gendersOptions,
  races,
  racesLabels,
  parentsCompletedCollegeOptions,
  socioeconomicSituationsOptions,
  genders,
  genderLabels,
  dependantsOptions,
  disabilitiesOptions,
  disabilities,
} from '~/modules/user/models/user.model';
import { useUser } from '~/modules/user/models/user.hooks';
import { useDispatch } from 'react-redux';
import {
  createGeneralInfo,
  createLogistic,
  createDemographic,
  searchInstitutes,
  update,
  updateGeneralInfo,
  updateLogistic,
  updateDemographic
} from '~/modules/user/redux/user.actions';
import { Link } from '~/common/components/Link/Link';
import { loadCourses } from '~/modules/applications/redux/applications.actions';
import { useLoadCountriesRequest, useOnError, useSnackbar, useRef} from '~/modules/app/models/app.hooks';
import { noValueCodes, scrollToFirstError } from '~/modules/app/models/app.model';

import { applicationStatuses, applicationStatusesLabels, yesOrNo } from '~/modules/applications/models/application.model';

const generalInfoFormNames = {
  highest_degree: 'highest_degree',
  other_highest_degree: 'other_highest_degree',
  highest_degree_year_earned: 'highest_degree_year_earned',
  institution: 'institution',
  institution_country: 'institution_country',
  us_state: 'us_state',
  contested_region: 'contested_region',
  status: 'status',
  other_status: 'other_status',
  held_position_time: 'held_position_time',
  adult_age: 'adult_age',
  google_scholar_url: 'google_scholar_url',
  github_url: 'github_url',
  text_about_research_and_interests: 'text_about_research_and_interests',
  opportunities_to_match_with: 'opportunities_to_match_with',
};

Object.entries(generalInfoFormNames).forEach(([key, value]) => {
  generalInfoFormNames[key] = 'general_info.' + value;
});

const logisticsFormNames = {
  preferred_time_slot: 'preferred_time_slot',
  second_preferred_time_slot: 'second_preferred_time_slot',
  possible_time_slots: 'possible_time_slots',
  preferred_language: 'preferred_language',
  possible_languages: 'possible_languages',
  fill_in_languages: 'fill_in_languages',
  comfort_english: 'comfort_english',
  preferred_application_1: 'preferred_application_1',
  preferred_application_2: 'preferred_application_2',
  preferred_application_3: 'preferred_application_3',
  preferred_application_4: 'preferred_application_4',
  preferred_application_5: 'preferred_application_5',
  preferred_application_6: 'preferred_application_6',
};

const applicationPreferencesForm = [
  { label :  'Preference 1' ,  name : logisticsFormNames.preferred_application_1 } ,
  { label :  'Preference 2' ,  name : logisticsFormNames.preferred_application_2 } ,
  { label :  'Preference 3' ,  name : logisticsFormNames.preferred_application_3 } ,
  { label :  'Preference 4' ,  name : logisticsFormNames.preferred_application_4 } ,
  { label :  'Preference 5' ,  name : logisticsFormNames.preferred_application_5 } ,
  { label :  'Preference 6' ,  name : logisticsFormNames.preferred_application_6 } ,
]

Object.entries(logisticsFormNames).forEach(([key, value]) => {
  logisticsFormNames[key] = 'logistic.' + value;
});


const demographicsFormNames = {
  gender: 'gender',
  self_describe_gender: 'self_describe_gender',
  race_and_ethnicity: 'race_and_ethnicity',
  self_describe_race: 'self_describe_race',
  parents_completed_college: 'parents_completed_college',
  dependents_at_home: 'dependents_at_home',
  socioeconomic_situation: 'socioeconomic_situation',
  access_support: 'access_support',
  self_describe_disability: 'self_describe_disability',
};

Object.entries(demographicsFormNames).forEach(([key, value]) => {
  demographicsFormNames[key] = 'demographic.' + value;
});

const checkboxLanguages = { ...languages };
delete checkboxLanguages[languages.english];


export const GeneralInfo = ({ courses }) => {
  const history = useHistory();
  const user = useUser();
  const defaultValues = { ...user };
  const { register, handleSubmit, setValue, errors, setError, trigger, watch } = useForm({ defaultValues });
  const dispatch = useDispatch();
  const setErrors = setErrorsFunction(setError);
  const { setSnackbar } = useSnackbar();
  const onError = useOnError(setErrors);

  const applications_status = courses.flatMap((course) => { return [course.student_application_status, course.ta_application_status]})
  const isDeadLineEnded = applications_status.includes(applicationStatuses.deadline_ended) || applications_status.includes(applicationStatuses.enrolled_deadline_ended);
  const isEnrolledDeadLineEnded = applications_status.includes(applicationStatuses.enrolled_deadline_ended);

  const applications = useSelector(selectApplications);

  const applicationOptions = applications.flatMap((apl) => {
    return { label : apl.course_name + ' ' + apl.application_type , value : apl.course_name + ' ' + apl.application_type }
  })

  // from react-us-states
  function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
  var _statesUs = _interopRequireDefault(require("states-us"));
  var useStates = function useStates() {
    return _statesUs.default;
  };
  var states = useStates().map(state => (
    {
      label: state.abbreviation + " | " + state.name,
      value: state.abbreviation + " | " + state.name
    }
  ));

  const [customErrors, setCustomErrors] = useState({});

  useEffect(() => {
    scrollToFirstError(customErrors);
  }, [customErrors]);

  const validateData = (data) => {
    // ensure that year input is there if they have a degree
    // display errors
    let hasErrors = false;
    if (data.general_info.highest_degree !== degrees.no_degree) {
      if (data.general_info.highest_degree_year_earned === 0) {
        setCustomErrors(errors => (
          {
            general_info:
            {
              ...errors.general_info,
              highest_degree_year_earned: 'Please select the year you earned the above degree.'
            }
          }
        ));
        hasErrors = true;
      }
    } else {
      // if no degree option is selected, set year to 0
      data.general_info.highest_degree_year_earned = 0;
    }
    if (data.general_info.institution_country === "United States") {
      if (data.general_info.us_state === null) {
        setCustomErrors(errors => (
          {
            general_info:
            {
              ...errors.general_info,
              us_state: 'Please select the current US state in which you reside.'
            }
          }
        ));
        hasErrors = true;
      }
    } else {
      data.general_info.us_state = null;
    }
    // control submit flow
    if (!hasErrors) {
      return true;
    } else {
      return false;
    }
  }

  // check on change if year is selected to dynamically remove errors
  const checkCustomErrors = () => {
    const temp_data = watch();
    let updatedErrors = {};
    if (temp_data.general_info.highest_degree !== degrees.no_degree) {
      if (temp_data.general_info.highest_degree_year_earned === 0) {
        updatedErrors = {
          general_info: {
            ...updatedErrors.general_info,
            highest_degree_year_earned: customErrors.general_info.highest_degree_year_earned
          }
        };
      }
    }
    if (temp_data.general_info.institution_country === "United States") {
      if (temp_data.general_info.us_state === null) {
        updatedErrors = {
          general_info: {
            ...updatedErrors.general_info,
            us_state: customErrors.general_info.us_state
          }
        };
      }
    }
    setCustomErrors(updatedErrors);
  }

  // TODO: Allow null and validate on frontend
  const validateOtherFields = data => {
    const placeholderText = 'specify here...';
    if (!isSelfDescribeRace) {
      data.demographic.self_describe_race = placeholderText;
    }
    if (!isSelfDescribeGender) {
      data.demographic.self_describe_gender = placeholderText;
    }
    if (!isSelfDescribeDisability) {
      data.demographic.self_describe_disability = placeholderText;
    }
  }

  const onSubmit = data => {
    if (!validateData(data)) {
      setSnackbar('Required fields missing');
      return
    }
    validateOtherFields(data);
    // if (!user.demographic || !user.logistic || !user.general_info) {
    if (!user.demographic || !user.general_info) {
      const requests = [];

      if (!user.demographic) {
        const demographics = { ...data.demographic, user: user.id };
        requests.push(dispatch(createDemographic(demographics)));
      } else {
        requests.push(dispatch(updateDemographic(data.demographic, user.demographic.id)));
      }

      // if (!user.logistic) {
      //   const logistics = { ...data.logistic, user: user.id };
      //   requests.push(dispatch(createLogistic(logistics)));
      // } else {
      //   requests.push(dispatch(updateLogistic(data.logistic, user.logistic.id)));
      // }

      if (!user.general_info) {
        const generalInfo = { ...data.general_info, user: user.id };
        requests.push(dispatch(createGeneralInfo(generalInfo)));
      } else {
        requests.push(dispatch(updateGeneralInfo(data.general_info, user.general_info.id)));
      }

      // prefixes for setting errors to form - removed logistics
      const prefixesForErrors = ['demographic.', 'general_info.'];

      let isSuccessUpdate = true;

      Promise.allSettled(requests).then(results => {
        // update courses
        dispatch(loadCourses());
        let allErrors = {};
        results.forEach((response, index) => {
          if (response.status === 'rejected') {
            const errors = response?.reason?.response?.data;
            isSuccessUpdate = false;
            if (errors) {
              const errorsWithPrefix = {};
              Object.entries(errors).forEach(([key, value]) => {
                errorsWithPrefix[prefixesForErrors[index] + key] = value;
              });
              allErrors = { ...allErrors, ...errorsWithPrefix };
              setErrors(errorsWithPrefix);
            }
          }
        });
        if (isSuccessUpdate) {
          // history.push(applicationsRoutes.homepage);
          if (user.logistic) {
            history.push(userRoutes.applications);
          } else {
            history.push(userRoutes.logistics);
          }
          setSnackbar('Profile has been updated');
        } else {
          scrollToFirstError(allErrors);
          const missingField = Object.values(allErrors).find(([value]) => {
            return value?.code === noValueCodes.null || value?.code === noValueCodes.blank;
          });
          if (missingField) {
            setSnackbar('Required fields missing');
          } else {
            setSnackbar('An error appeared');
          }
        }
      });
    } else {
      //history.push(applicationsRoutes.homepage);
      dispatch(update(data))
        .then(() => {
          // history.push(applicationsRoutes.homepage);
          if (user.logistic) {
            history.push(userRoutes.applications);
          } else {
            history.push(userRoutes.logistics);
          }
          setSnackbar('Profile has been updated');
        })
        .catch(onError);
    }
  };

  const highest_degree = watch(generalInfoFormNames.highest_degree);
  const status = watch(generalInfoFormNames.status);
  const country = watch(generalInfoFormNames.institution_country);

  const setValueWithTrigger = (name, value) => {
    setValue(name, value);
    trigger(name);
  };

  const loadInstitutesOptions = (inputValue, callback) => {
    dispatch(searchInstitutes(inputValue)).then(response => {
      const institutes = response.data?.results;
      const options = institutes.map(institute => ({
        label: institute.name,
        value: institute.name,
        country: institute.country,
      }));
      callback(options);
    });
  };

  // registering selects
  useEffect(() => {
    register({ name: generalInfoFormNames.highest_degree});
    register({ name: generalInfoFormNames.highest_degree_year_earned});
    register({ name: generalInfoFormNames.institution });
    register({ name: generalInfoFormNames.institution_country });
    register({ name: generalInfoFormNames.us_state });
    register({ name: generalInfoFormNames.contested_region });
    register({ name: generalInfoFormNames.status });
    register({ name: generalInfoFormNames.held_position_time });
    register({ name: generalInfoFormNames.opportunities_to_match_with});
    register({ name: logisticsFormNames.preferred_time_slot });
    register({ name: logisticsFormNames.second_preferred_time_slot });
    register({ name: logisticsFormNames.preferred_language });
    register({ name: logisticsFormNames.comfort_english });
    register({ name: logisticsFormNames.preferred_application_1 });
    register({ name: logisticsFormNames.preferred_application_2 });
    register({ name: logisticsFormNames.preferred_application_3 });
    register({ name: logisticsFormNames.preferred_application_4 });
    register({ name: logisticsFormNames.preferred_application_5 });
    register({ name: logisticsFormNames.preferred_application_6 });
    register({ name: demographicsFormNames.gender });
    register({ name: demographicsFormNames.race_and_ethnicity });
    register({ name: demographicsFormNames.parents_completed_college });
    register({ name: demographicsFormNames.socioeconomic_situation });
    register({ name: demographicsFormNames.dependents_at_home});
    register({ name: demographicsFormNames.access_support});
  }, [register]);

  const loadCountriesOptions = useLoadCountriesRequest();

  // Demographic
  const race = watch(demographicsFormNames.race_and_ethnicity);
  const isSelfDescribeRace = race?.includes(races.self_describe);
  const gender = watch(demographicsFormNames.gender);
  const isSelfDescribeGender = gender?.includes(genders.self_describe);
  const diability = watch(demographicsFormNames.access_support);
  const isSelfDescribeDisability = diability?.includes(disabilities.self_describe);

  return (
    <div className="general-info">
      <Form className="general-info__form" onSubmit={handleSubmit(onSubmit)}>
        <Collapse title="General Info" defaultExpanded={true}>
          {/*<Form.Title>General Info</Form.Title>*/}
        <Form.Row>
          <Form.Label>Age</Form.Label>
          <Checkbox name={generalInfoFormNames.adult_age}
          innerRef={register}
          text="I am 18 years or older"
          error={errors?.general_info?.adult_age?.message} />
        </Form.Row>
        <Form.Row>
          <Select
            label="Highest degree earned"
            name={generalInfoFormNames.highest_degree}
            defaultValue={user?.general_info?.highest_degree}
            options={degreesOptions}
            // isClearable
            onChange={option => {
              setValueWithTrigger(generalInfoFormNames.highest_degree, option?.value ?? option);
            }}
            error={errors?.general_info?.highest_degree?.message}
          />
        </Form.Row>
        {highest_degree === degrees.other && (
          <Form.Row>
            <Input
              name={generalInfoFormNames.other_highest_degree}
              label="If other, please specify degree type, number of years of course, and type of course (distance learning, part-time, etc):"
              innerRef={register}
              placeholder=""
              error={errors?.general_info?.other_highest_degree?.message}
            />
          </Form.Row>
        )}
        {highest_degree !== degrees.no_degree && (
        <Form.Row>
          <Select
            label="Year you earned the highest degree above"
            name={generalInfoFormNames.highest_degree_year_earned}
            defaultValue={
              user?.general_info?.highest_degree_year_earned !== 0 ?
              user?.general_info?.highest_degree_year_earned : null
            }
            options={years}
            onChange={option => {
              setValueWithTrigger(generalInfoFormNames.highest_degree_year_earned, option?.value ?? option);
              checkCustomErrors();
            }}
            error={errors?.general_info?.highest_degree_year_earned?.message || customErrors?.general_info?.highest_degree_year_earned}
          />
        </Form.Row>
        )}
        <Form.Row>
          <Select
            label="Current Status"
            name={generalInfoFormNames.status}
            defaultValue={user?.general_info?.status}
            options={statusesOptions}
            // isClearable
            onChange={option => {
              setValueWithTrigger(generalInfoFormNames.status, option?.value ?? option);
            }}
            error={errors?.general_info?.status?.message}
          />
        </Form.Row>
        {status === statuses.other && (
          <Form.Row>
            <Input
              name={generalInfoFormNames.other_status}
              label="If other, please specify:"
              innerRef={register}
              placeholder="Status"
              error={errors?.general_info?.other_status?.message}
            />
          </Form.Row>
        )}
        <Form.Row>
          <Select
            label="How long have you held this position?"
            name={generalInfoFormNames.held_position_time}
            defaultValue={user?.general_info?.held_position_time}
            options={heldPositionTimesOptions}
            onChange={option => {
              setValueWithTrigger(generalInfoFormNames.held_position_time, option?.value ?? option);
            }}
            error={errors?.general_info?.held_position_time?.message}
          />
        </Form.Row>
        <Form.Row>
          <AsyncSelect
            loadOptions={loadInstitutesOptions}
            label="Current Institution/Organization"
            name={generalInfoFormNames.institution}
            defaultValue={user?.general_info?.institution}
            isClearable
            isCreatable
            options={[]}
            onChange={option => {
              option?.country && setValueWithTrigger(generalInfoFormNames.institution_country, option?.country);
              setValueWithTrigger(generalInfoFormNames.institution, option?.value ?? option);
            }}
            error={errors?.general_info?.institution?.message}
          />
        </Form.Row>
        <Form.Row>
          {/*<Input*/}
          {/*  name={generalInfoFormNames.institution_country}*/}
          {/*  label="Institution Country"*/}
          {/*  innerRef={register}*/}
          {/*  error={errors?.general_info?.institution_country?.message}*/}
          {/*/>*/}

          <AsyncSelect
            loadOptions={loadCountriesOptions}
            label="Current Institution/Organization Country/Region"
            name={generalInfoFormNames.institution_country}
            defaultValue={user?.general_info?.institution_country}
            value={country}
            isClearable
            //isCreatable
            options={[]}
            onChange={option => {
              setValueWithTrigger(generalInfoFormNames.institution_country, option?.value ?? option);
            }}
            error={errors?.general_info?.institution_country?.message}
          />
        </Form.Row>
        {country === "United States" && (
          <Form.Row>
            <Select
              label="Current US State"
              name={generalInfoFormNames.us_state}
              defaultValue={user?.general_info?.us_state}
              options={states}
              onChange={option => {
                setValueWithTrigger(generalInfoFormNames.us_state, option?.value ?? option);
                checkCustomErrors();
              }}
              error={errors?.general_info?.us_state?.message || customErrors?.general_info?.us_state}
            />
          </Form.Row>
        )}
        <Form.Row>
          <Input
            name={generalInfoFormNames.google_scholar_url}
            innerRef={register}
            label="URL to a list of your publications (such as Google scholar), if you have one"
            error={errors?.general_info?.google_scholar_url?.message}
          />
        </Form.Row>
        <Form.Row>
          <Input
            name={generalInfoFormNames.github_url}
            innerRef={register}
            label="URL to your code repositories (such as Github), if you have one"
            error={errors?.general_info?.github_url?.message}
          />
        </Form.Row>
        <Form.Row>
          <Textarea
            name={generalInfoFormNames.text_about_research_and_interests}
            innerRef={register}
            error={errors?.general_info?.text_about_research_and_interests?.message}
            label="To better understand your topics of interest, cut and paste one or more abstracts from your work. If you have no publications with abstracts, select an abstract from a paper that represents a research topic you might want to work on in the future. Otherwise, you can write you own text describing your research interests (100-1000 words). These responses are used for matching you to interesting peers and opportunities. They do not influence acceptance of your application."
          />
        </Form.Row>
        <Form.Row>
          <Form.Label>Please match me with the following opportunities over the next year. (Note, Neuromatch will send at most one email a month for these opportunities and you can change these options at any point) </Form.Label>
          {Object.entries(matchOpportunities).map(([key, value]) => (
            <Checkbox
              innerRef={register}
              name={generalInfoFormNames.opportunities_to_match_with}
              value={value}
              defaultValue={generalInfoFormNames.opportunities_to_match_with}
              text={matchOpportunitiesOptions[value]}
              key={key}
            />
          ))}
        </Form.Row>
        </Collapse>
        {/*<Form.Title>Logistics</Form.Title>

        <Markdown>
        {
                "`PLEASE NOTE THAT YOU CAN CHANGE THIS UNTIL THE APPLICATION DEADLINE, BUT IT WILL BE FINAL AFTER.` " +
                "  \n" +
                "  \n" +
                "Please list the applications you will submit in order of preference. " +
                "You will not be able to enroll in a lower preference if accepted to a higher preference as they take place at the same time."
        }
        </Markdown>

        {applicationPreferencesForm.map((course, index) => (
          <Form.Row className="preferences">
            {isDeadLineEnded && (
            <Tooltip  place="left">{applicationStatusesLabels.deadline_ended}</Tooltip>
            )}
            { index  >= applicationOptions.length && (
            <Tooltip  place="left">{applicationStatusesLabels.not_applied}</Tooltip>
            )}
            {  index  < Math.max(applicationOptions.length, 1) && (<Select
              label={
                course.label + ":"
              }
              // isClearable
              name={course.name}
              defaultValue={user?.logistic?.[course.name] }
              options={applicationOptions}
              onChange={option => {
                setValueWithTrigger(logisticsFormNames[course.name], option?.value ?? option);
              }}
              error={errors?.logistic?.[course.name]?.message}
              isDisabled={isDeadLineEnded | index  >= applicationOptions.length }
            /> )}
          </Form.Row>
            )
        )}
        <Markdown>
        {       "`PLEASE NOTE THAT YOU CAN CHANGE THE FOLLOWING LOGISTICS QUESTIONS UNTIL THE ENROLLMENT DEADLINE, BUT IT WILL BE FINAL AFTER.` " }
        </Markdown>
        <Form.Row>
          <Form.Label>Time slots are one of the most important matching constraints! They determine when you will be available online for the duration of the course to interact and learn together with your podmates. It is a lot of work for our volunteer matching team to re-match applicants if you choose the wrong time slots! So please choose only those time slots in which you are confident that you won't have any issues with being online for the course. Selection of the time slot can be confusing and may be impossible to change later. We strongly recommend you spend some time with this tool to determine and select your comfortable time slots in the questions below.</Form.Label>
          <iframe title="timeslot-widget"
                  src="https://neuromatchacademy.github.io/widgets/tz.html"
                  frameborder="0"
                  width="100%"
                  height="550"
                  style={{background: "transparent", border: "1px solid rbg(204,204,204)", borderRadius: "1%"}}
          ></iframe>
        </Form.Row>

        <Form.Row className="preferences">
          {isEnrolledDeadLineEnded && (
            <Tooltip  place="left">{applicationStatusesLabels.enrolled_deadline_ended}</Tooltip>
          )}
          <Select
            label={
              <>
                What is your most preferred time slot? We will try our best to match you to this time slot. Timezones can be found{' '}
                <Link to="https://neuromatchacademy.github.io/widgets/tz.html" isCrossDomain newTab>
                  here
                </Link>
                {', '}or you can use the above widget.
              </>
            }
            // isClearable
            name={logisticsFormNames.preferred_time_slot}
            defaultValue={user?.logistic?.preferred_time_slot}
            options={timeSlotsOptions}
            onChange={option => {
              setValueWithTrigger(logisticsFormNames.preferred_time_slot, option?.value ?? option);
            }}
            error={errors?.logistic?.preferred_time_slot?.message}
            isDisabled={isEnrolledDeadLineEnded}
          />
        </Form.Row>
        <Form.Row className="preferences">
          {isEnrolledDeadLineEnded && (
            <Tooltip  place="left">{applicationStatusesLabels.enrolled_deadline_ended}</Tooltip>
          )}
          <Select
            label={
              <>
                What is your second preferred time slot?
                {' '}  If you really can’t make another slot work, pick the same slot as your first preference.
              </>
            }
            name={logisticsFormNames.second_preferred_time_slot}
            defaultValue={user?.logistic?.second_preferred_time_slot}
            options={timeSlotsOptions}
            onChange={option => {
              setValueWithTrigger(logisticsFormNames.second_preferred_time_slot, option?.value ?? option);
            }}
            error={errors?.logistic?.second_preferred_time_slot?.message}
            isDisabled={isEnrolledDeadLineEnded}
          />
        </Form.Row>
        <Form.Row className="preferences">
          {isEnrolledDeadLineEnded && (
            <Tooltip  place="left">{applicationStatusesLabels.enrolled_deadline_ended}</Tooltip>
          )}
          <Select
            label=<div>
                    <p>What is your preferred language to talk with your classmates and teaching assistant in?</p>
                    <p>Important things to note before making a selection:</p>
                    <ul>
                      <li>All course material (videos, code) is in English, but some pods use a different language for conversations between students and the TA.</li>
                      <li>If you’d like to be placed in an English speaking pod, please choose English.</li>
                      <li>If you are MORE comfortable speaking in a non-English language than English, please select it here and we will try to place you in a pod that uses that language instead of English.</li>
                      <li>Please DO NOT select an alternative language to practice speaking that language, as you will be unable to switch to English if conversations are too fast for you to follow.</li>
                      <li>We cannot guarantee admission to language pods; if you cannot be matched to a pod in your preferred language we will place you in an English-speaking pod for that course.</li>
                      <li>Choice of language will not affect your chance of being accepted to any course.</li>
                    </ul>
                  </div>
            name={logisticsFormNames.preferred_language}
            // isClearable
            defaultValue={user?.logistic?.preferred_language}
            options={languagesOptions}
            onChange={option => {
              setValueWithTrigger(logisticsFormNames.preferred_language, option?.value ?? option);
            }}
            error={errors?.logistic?.preferred_language?.message}
            isDisabled={isEnrolledDeadLineEnded}
          />
        </Form.Row>

        <Form.Label>If there are other languages that you would be more comfortable with than English, please select them here as an alternative.</Form.Label>
        <Form.Row className="general-info__radio-btns preferences">
          {isEnrolledDeadLineEnded && (
            <Tooltip  place="left">{applicationStatusesLabels.enrolled_deadline_ended}</Tooltip>
          )}
          {Object.keys(checkboxLanguages).map(lang => (
            <Checkbox
              className="general-info__checkbox"
              innerRef={register}
              name={logisticsFormNames.possible_languages}
              value={lang}
              text={languagesLabels[lang]}
              key={lang}
              // defaultChecked={user?.logistic?.possible_languages.includes(lang)}
              disabled={isEnrolledDeadLineEnded}
            />
          ))}
        </Form.Row>
        <Form.Row>
          <Input
            label="Are there any languages besides those listed that you would want to use in a course?"
            name={logisticsFormNames.fill_in_languages}
            innerRef={register}
            error={errors?.logistic?.fill_in_languages?.message}
          />
        </Form.Row>
        <Form.Row>
          <Form.Label>I am comfortable learning and/or teaching in English. I understand that I may be placed in an English speaking pod if I cannot be matched to a pod in the above languages.</Form.Label>
          <Checkbox name={logisticsFormNames.comfort_english}
          innerRef={register}
          text="I am comfortable speaking English"
          error={errors?.logistic?.comfort_english?.message} />
        </Form.Row>
        */}
        <Collapse title='Demographics' defaultExpanded={true}>
          {/*<Form.Title>Demographics</Form.Title>*/}
        <Form.Row>
          <Form.Label>
            Your personal demographic information on this page will be used only for evaluating our effectiveness at
            establishing inclusive outreach and recruitment practices, and will not be used to make decisions about
            whether to accept or decline your application. It will not be made available to other students, or your teaching assistant
            and only a small group of authorized Neuromatch and Climatematch staff will have access.
          </Form.Label>
          <Form.Label>Gender (check all that apply)</Form.Label>
          {Object.entries(genders).map(([key, value]) => (
            <Checkbox
              innerRef={register}
              name={demographicsFormNames.gender}
              value={value}
              defaultValue={demographicsFormNames.gender}
              text={genderLabels[value]}
              key={key}
            />
          ))}
          <div className="error">{errors?.demographic?.gender?.message}</div>
        </Form.Row>
        {isSelfDescribeGender && (
          <Form.Row>
            <Input
              label="If self-describe, please specify:"
              name={demographicsFormNames.self_describe_gender}
              defaultValue={demographicsFormNames.self_describe_gender}
              innerRef={register}
              error={errors?.demographic?.self_describe_gender?.message}
            />
          </Form.Row>
        )}
        <Form.Row>
          <Form.Label>Race and Ethnicity (check all that apply)</Form.Label>
          {Object.entries(races).map(([key, value]) => (
            <Checkbox
              innerRef={register}
              name={demographicsFormNames.race_and_ethnicity}
              value={value}
              defaultValue={demographicsFormNames.race_and_ethnicity}
              text={racesLabels[value]}
              key={key}
            />
          ))}
          <div className="error">{errors?.demographic?.race_and_ethnicity?.message}</div>
        </Form.Row>
        {isSelfDescribeRace && (
          <Form.Row>
            <Input
              label="If self-describe, please specify:"
              name={demographicsFormNames.self_describe_race}
              innerRef={register}
              error={errors?.demographic?.self_describe_race?.message}
            />
          </Form.Row>
        )}
        <Form.Row>
          <Select
            label="Did your parents complete a college degree?"
            options={parentsCompletedCollegeOptions}
            defaultValue={user?.demographic?.parents_completed_college}
            name={demographicsFormNames.parents_completed_college}
            onChange={option => {
              setValueWithTrigger(demographicsFormNames.parents_completed_college, option?.value ?? option);
            }}
            error={errors?.demographic?.parents_completed_college?.message}
          />
        </Form.Row>
        <Form.Row>
          <Select
            label="Do you have dependents at home?"
            options={dependantsOptions}
            defaultValue={user?.demographic?.dependents_at_home}
            name={demographicsFormNames.dependents_at_home}
            onChange={option => {
              setValueWithTrigger(demographicsFormNames.dependents_at_home, option?.value ?? option);
            }}
            error={errors?.demographic?.dependents_at_home?.message}
          />
        </Form.Row>
        <Form.Row>
          <Select
            options={socioeconomicSituationsOptions}
            name={demographicsFormNames.socioeconomic_situation}
            defaultValue={user?.demographic?.socioeconomic_situation}
            label="Compared to others in your region, how would you best describe socioeconomic situation?"
            onChange={option => {
              setValueWithTrigger(demographicsFormNames.socioeconomic_situation, option?.value ?? option);
            }}
            error={errors?.demographic?.socioeconomic_situation?.message}
          />
        </Form.Row>
        <Form.Row>
          <Form.Label>We try to provide support to make our courses more accessible. Do you have any access requirements you would like us to be aware of? Or is there any type of support that could improve the course for you?</Form.Label>
          {Object.entries(disabilities).map(([key, value]) => (
            <Checkbox
              innerRef={register}
              name={demographicsFormNames.access_support}
              value={value}
              defaultValue={demographicsFormNames.access_support}
              text={disabilitiesOptions[value]}
              key={key}
            />
          ))}
        </Form.Row>
        {/*<Form.Row>*/}
        {/*  <Select*/}
        {/*    options={disabilitiesOptions}*/}
        {/*    label='We try to provide support to make our courses more accessible. Do you have any access requirements you would like us to be aware of? Or is there any type of support that could improve the course for you?'*/}
        {/*    name={demographicsFormNames.has_disabilities_or_physical_problems}*/}
        {/*    defaultValue={user?.demographic?.has_disabilities_or_physical_problems}*/}
        {/*    isClearable*/}
        {/*    onChange={option => {*/}
        {/*      setValueWithTrigger(demographicsFormNames.has_disabilities_or_physical_problems, option?.value ?? option);*/}
        {/*    }}*/}
        {/*    error={errors?.demographic?.has_disabilities_or_physical_problems?.message}*/}
        {/*  />*/}
        {/*</Form.Row>*/}
        {isSelfDescribeDisability && (
          <Form.Row>
            <Input
              label="If self-describe, please specify:"
              name={demographicsFormNames.self_describe_disability}
              innerRef={register}
              error={errors?.demographic?.self_describe_disability?.message}
            />
          </Form.Row>
        )}
        </Collapse>
        <Form.Row>
          <Button type="submit" onClick={() => trigger()}>
           Submit
          </Button>
        </Form.Row>
      </Form>
    </div>
  );
};
