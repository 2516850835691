import UserPlaceholder from '~/common/assets/img/user/user-placeholder.svg';

export const StudentInPodInfo = ({ email, fullName, photo }) => {
  return (
    <div className="student-in-pod-info">
      <div className="student-in-pod-info__img">
        <img src={photo || UserPlaceholder} alt="" />
      </div>
      <a className="link student-in-pod-info__link" href={`mailto:${email}`}>
        {fullName}
      </a>
    </div>
  );
};
