import { Layout } from '~/common/components/Layout/Layout';
import { useTitle } from '~/modules/app/models/app.hooks';
import { Help } from '~/modules/applications/pages/Help/blocks/Help';

export const HelpPage = () => {
  useTitle('Help');
  return (
    <Layout>
      <Help />
    </Layout>
  );
};