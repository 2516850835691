import { useDispatch, useSelector } from 'react-redux'
import { selectAppReady, selectCanRequest } from '~/modules/app/redux/app.selectors'
import { useCallback, useEffect, useState } from 'react'
import { history } from '~/app/history/history'
import { userRoutes } from '~/modules/user/router/Router';
import {
    checkIsTokenExpired,
    getInitialData,
    resendEmail,
    searchCountries,
    setSnackbar,
} from '~/modules/app/redux/app.actions'
import { useLocation } from 'react-router-dom'
import { noValueCodes, scrollToFirstError, scrollToTop } from '~/modules/app/models/app.model'
import { flattenObj } from '~/common/libs/FormHelpers/FormHelpers'
import { getMediaFiles } from '../redux/app.actions'

export const useAppReady = () => {
    return useSelector(selectAppReady)
}

export const useTitle = title => {
    useEffect(() => {
        document.title = title
    }, [title])
}

export const useCanRequest = () => {
    return useSelector(selectCanRequest)
}

export const useSetQueryURIParams = () => {
    const setParams = ({ key, value }) => {
        history.push(`?${key}=${value}`)
    }

    return { setParams }
}

export const useLoadCountriesRequest = () => {
    const dispatch = useDispatch()

    const loadCountriesOptions = useCallback(
        (inputValue, callback) => {
            dispatch(searchCountries(inputValue)).then(response => {
                const countries = response.data?.results
                const options = countries.map(country => ({
                    label: country.name,
                    value: country.name,
                }))
                callback(options)
            })
        },
        [dispatch]
    )

    return loadCountriesOptions
}

export const useGetMediaFiles = () => {
    const dispatch = useDispatch()
    const [fetched, setFetched] = useState(false)
    const [mediaFiles, setMediaFiles] = useState()
    useEffect(() => {
        if (!fetched) {
            dispatch(getMediaFiles()).then(response => {
                setMediaFiles(response.data)
                setFetched(true)
            }).catch(e => {
              history.push(userRoutes.verify_pass)
        })
        }
    }, [dispatch, fetched])
    return mediaFiles
}

export const useLoadInitialData = () => {
    const [fetched, setFetched] = useState(false)
    const dispatch = useDispatch()
    useEffect(() => {
        if (!fetched) {
            dispatch(getInitialData()).then(() => {
                setFetched(true)
            })
        }
    }, [dispatch, fetched])
}

export const useSnackbar = () => {
    const dispatch = useDispatch()
    const setSnackbarCb = useCallback(
        (text, title, withAutoClose = true, delayToClose = 5000) => {
            dispatch(setSnackbar(text, title, withAutoClose, delayToClose))
        },
        [dispatch]
    )
    return {
        setSnackbar: setSnackbarCb,
    }
}

export const useOnRouteChange = () => {
    const location = useLocation()
    useEffect(() => {
        scrollToTop()
    }, [location])
}

export const useResendEmail = () => {
    const dispatch = useDispatch()
    return useCallback((token, type) => dispatch(resendEmail(token, type)), [dispatch])
}

export const useIsTokenExpired = () => {
    const dispatch = useDispatch()
    return useCallback((token, type) => dispatch(checkIsTokenExpired(token, type)), [dispatch])
}

export const useOnError = setErrors => {
    const { setSnackbar } = useSnackbar()
    return useCallback(
        e => {
            const errors = e.response.data
            setErrors(errors)
            scrollToFirstError(errors)
            const flattenedErrors = flattenObj(errors)
            const missingField = Object.values(flattenedErrors).find(([value]) => {
                return (
                    value?.code === noValueCodes.null ||
                    value?.code === noValueCodes.blank ||
                    value?.code === noValueCodes.required
                )
            })
            if (missingField) {
                setSnackbar('Required fields missing')
            } else {
                setSnackbar('An error appeared')
            }
        },
        [setErrors, setSnackbar]
    )
}
