import { useCheckAccessToDashboard, useValidCourseTypeInUrl } from '~/modules/applications/models/application.hooks';
import { getUrlSearchParams } from '~/modules/app/models/app.model';
import { applicationTypes } from '~/modules/applications/models/application.model';
import { StudentDashboard } from '~/modules/applications/pages/CourseDashboardPage/blocks/components/StudentDashboard/StudentDashboard';
import { MentorDashboard } from '~/modules/applications/pages/CourseDashboardPage/blocks/components/MentorDashboard/MentorDashboard';
import { TADashboard } from '~/modules/applications/pages/CourseDashboardPage/blocks/components/TADashboard/TADashboard';

export const CourseDashboard = () => {
  const { type } = getUrlSearchParams();
  const isCourseTypeValid = useValidCourseTypeInUrl();
  useCheckAccessToDashboard();

  if (!isCourseTypeValid) {
    return null;
  }

  return (
    <div className="course-dashboard narrow">
      {type === applicationTypes.student && <StudentDashboard />}
      {type === applicationTypes.mentor && <MentorDashboard />}
      {type === applicationTypes.ta && <TADashboard />}
    </div>
  );
};
