export const RadioButton = ({ onChange, className, innerRef, label, name, value, defaultChecked }) => {
  return (
    <div className={'form-check ' + (className ? className : '')}>
      <input
        type="radio"
        className="form-check-input"
        ref={innerRef}
        // id={value}
        name={name}
        value={value}
        defaultChecked={defaultChecked}
        onChange={onChange}
      />
      {label && (
        <label title="" className="form-check-label">
          {label}
        </label>
      )}
    </div>
  );
};
