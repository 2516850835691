const defaultRows = [
  [<>1</>, <>Mark</>, <>Otto</>],
  [<>1</>, <>Mark</>, <>Otto</>],
  [<>1</>, <>Mark</>, <>Otto</>],
];

// errors example
/*
const defaultErrors = {
  '1': 'Required',
  '3': 'Required',
};

 */

export const Table = ({
  headCells = [],
  className = '',
  rows = defaultRows,
  errors = {},
  sticky = false }) => {
  var stickyStyle = {};
  if (sticky === true) {
    stickyStyle = {position: 'sticky', left: '0', zIndex: '1', backgroundColor:'rgba(240, 240, 240, 1)'};
  };
  return (
    <div className="table-wrapper">
      <table className={'table table-striped ' + className}>
        <thead style={{position: 'sticky', top: '0', zIndex: '0'}}>
          <tr>
            {headCells.map(cell => (
              <th scope="col">{cell}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rows.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {row.map((cell, index) => (
                <>
                  {index === 0 ? (
                    <>
                      <th style={stickyStyle} scope="row">
                      {cell}
                      {errors[rowIndex.toString()] && (
                        <span className="invalid-feedback">{errors[rowIndex.toString()]}</span>
                      )}
                      </th>
                    </>
                  ) : (
                    <td>{cell}</td>
                  )}
                </>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
