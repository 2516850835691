import { Button } from '~/common/components/Button/Button';

export const InputFile = ({ name, className = '', text, innerRef, accept, onChange }) => {
  return (
    <label htmlFor="file" className={'input-file ' + className}>
      <input type="file" name={name} id="file" ref={innerRef} accept={accept} onChange={onChange} />
      <Button variant="primary">{text}</Button>
    </label>
  );
};
