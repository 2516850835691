import { Redirect, Route } from 'react-router-dom';
import { useIsLoggedIn } from '~/modules/user/models/user.hooks';
import { applicationsRoutes } from '~/modules/applications/router/Router';
import {
    // extractDataParam,
    extractId,
    getRedirectLink,
    // submitEvaluationData
} from '../redux/user.actions'
import { useDispatch } from 'react-redux';

export const userRoutes = {
  sign_up: '/sign-up',
  login: '/sign-in',
  forgot_password: '/forgot-password',
  reset_password: '/reset-password',
  verification: '/verification',
  account: '/account',
  logistics: '/logistics',
  applications: '/applications',
  github_verification: '/github-verification',
  new_email_verification: '/new-email-verification',
  certificate: '/certificate',
  media: '/media',
  verify_pass: '/verify-content-pass'
};

export const PrivateRoute = ({ component: Component, ...rest }) => {
  const isLoggedIn = useIsLoggedIn();
  return (
    <Route
      {...rest}
      render={props => {
        if (!isLoggedIn) {
          return <Redirect to={{ pathname: userRoutes.login, state: { from: props.location } }} />;
        }
        return <Component {...props} key={props.location.pathname} />;
      }}
    />
  );
};

export const UnAuthRoute = ({ component: Component, ...rest }) => {
  const isLoggedIn = useIsLoggedIn();
  const dispatch = useDispatch();
  return (
    <Route
      {...rest}
      render={props => {
        if (isLoggedIn) {
          const uid = extractId(props)
          if (uid) {
              // const data = extractDataParam(props)
              // if (data !== undefined) {
              //     setTimeout(() => { dispatch(submitEvaluationData(data)); }, 2000);
              // }
            setTimeout(() => { dispatch(getRedirectLink([], uid)); }, 2000);
          }
          return <Redirect to={{ pathname: applicationsRoutes.homepage, state: { from: props.location } }} />;
        }
        return <Component {...props} key={props.location.pathname} />;
      }}
    />
  );
};
