import { Block } from '~/common/components/Block/Block';
import { Button } from '~/common/components/Button/Button';
import { Markdown } from '~/common/components/Markdown/Markdown';
import { scrollToTop } from '~/modules/app/models/app.model';

export const ApplicationIntro = ({ title, text, description, nextPage }) => {
  return (
    <Block className="application-intro" title={title}>
      {/*<div className="application-intro__desc">*/}
      {/*  <Markdown>{description}</Markdown>*/}
      {/*</div>*/}
      <div className="application-intro__text">
        <Markdown>{text}</Markdown>
      </div>
      <div className="application-intro__next-page">
        <Button
          onClick={() => {
            nextPage();
            scrollToTop();
          }}
        >
          Next Page
        </Button>
      </div>
    </Block>
  );
};
