import { Layout } from '~/common/components/Layout/Layout';
import { ResetPassword } from '~/modules/user/pages/ResetPassword/blocks/ResetPassword';

export const ResetPasswordPage = () => {
  return (
    <Layout>
      <ResetPassword />
    </Layout>
  )
}
