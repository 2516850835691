import { Form } from '~/common/components/Form/Form';
import { useForm } from 'react-hook-form';
import { Checkbox } from '~/common/components/Checkbox/Checkbox';
import { Block } from '~/common/components/Block/Block';
import { Button } from '~/common/components/Button/Button';
import { Select } from '~/common/components/Select/Select';
import {
  getDatasetsOptions,
  yearsOfCourseResearchOptions,
  yesOrNoOptions,
} from '~/modules/applications/models/application.model';
import { setErrorsFunction } from '~/common/libs/FormHelpers/FormHelpers';
import {
  useCreateMentorApplicationRequest,
  useGetCourseDataSets,
  useLoadApplicationsRequest,
  useUpdateMentorApplicationRequest,
} from '~/modules/applications/models/application.hooks';
import { useEffect } from 'react';
import { RadioButton } from '~/common/components/RadioButton/RadioButton';
import { Table } from '~/common/components/Table/Table';
import { AsyncSelect } from '~/common/components/Select/AsyncSelect';
import { useLoadCountriesRequest, useOnError, useSnackbar } from '~/modules/app/models/app.hooks';
import dayjs from 'dayjs';
import { history } from '~/app/history/history';
import { applicationsRoutes } from '~/modules/applications/router/Router';
import { scrollToTop } from '~/modules/app/models/app.model';
import { Link } from '~/common/components/Link/Link';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

const formNames = {
  participated_in_nma_last_year: 'participated_in_nma_last_year',
  residing_country_during_course: 'residing_country_during_course',
  comfortable_teaching_in_english: 'comfortable_teaching_in_english',
  groups_mentor_during_count: 'groups_mentor_during_count',
  hours_meetings_with_ta: 'hours_meetings_with_ta',
  proposed_project_ideas_for_students: 'proposed_project_ideas_for_students',
  years_of_course_work_research: 'years_of_course_work_research',
  comfortable_data_set: 'comfortable_data_set',
  alternative_data_set: 'alternative_data_set',
  linear_regression: 'linear_regression',
  generalized_linear_models: 'generalized_linear_models',
  dimensionality_reduction: 'dimensionality_reduction',
  linear_dynamical_systems: 'linear_dynamical_systems',
  decision_making: 'decision_making',
  optimal_control: 'optimal_control',
  reinforcement_learning: 'reinforcement_learning',
  single_neuron_models: 'single_neuron_models',
  neuronal_network_dynamics: 'neuronal_network_dynamics',
  network_causality: 'network_causality',
  bayesian_statistics: 'bayesian_statistics',
  convolutional_neural_networks: 'convolutional_neural_networks',
  autoencoders: 'autoencoders',
  stochastic_gradient_descent: 'stochastic_gradient_descent',
  automatic_differentiation: 'automatic_differentiation',
  multilayer_perceptrons: 'multilayer_perceptrons',
  adam: 'adam',
  dropout: 'dropout',
  convnets: 'convnets',
  alexnet: 'alexnet',
  lstm: 'lstm',
  deep_q_learning: 'deep_q_learning',
  self_supervised_learning: 'self_supervised_learning',
  causality: 'causality',
  agree_to_abide_code_of_conduct: 'agree_to_abide_code_of_conduct',
  agree_terms_and_conditions: 'agree_terms_and_conditions',
};

const computationalNeuroscienceTopicsTableFillData = [
  { label: 'Linear regression', formName: formNames.linear_regression },
  { label: 'Generalized linear models', formName: formNames.generalized_linear_models },
  { label: 'Dimensionality reduction', formName: formNames.dimensionality_reduction },
  { label: 'Bayesian statistics', formName: formNames.bayesian_statistics },
  { label: 'Linear dynamical systems', formName: formNames.linear_dynamical_systems },
  { label: 'Decision making', formName: formNames.decision_making },
  { label: 'Optimal control', formName: formNames.optimal_control },
  { label: 'Reinforcement learning', formName: formNames.reinforcement_learning },
  { label: 'Single neuron models', formName: formNames.single_neuron_models },
  { label: 'Neuronal networks dynamics', formName: formNames.neuronal_network_dynamics },
  { label: 'Network causality', formName: formNames.network_causality },
  { label: 'Convolutional neural networks', formName: formNames.convolutional_neural_networks },
  { label: 'Autoencoders', formName: formNames.autoencoders },
];

const deepLearningConceptsFillData = [
  { label: 'Stochastic gradient descent', formName: formNames.stochastic_gradient_descent },
  { label: 'Automatic differentiation', formName: formNames.automatic_differentiation },
  { label: 'Multilayer perceptrons', formName: formNames.multilayer_perceptrons },
  { label: 'ADAM', formName: formNames.adam },
  { label: 'Dropout', formName: formNames.dropout },
  { label: 'Convnets', formName: formNames.convnets },
  { label: 'Alexnet', formName: formNames.alexnet },
  { label: 'LSTM', formName: formNames.lstm },
  { label: 'Deep Q-learning', formName: formNames.deep_q_learning },
  { label: 'Self-supervised learning', formName: formNames.self_supervised_learning },
  { label: 'Causality', formName: formNames.causality },
];

export const CreateMentorApplicationForm = ({
  defaultValues,
  isUpdate = false,
  previousPage,
  course,
  applicationId,
}) => {
  const { register, handleSubmit, setError, trigger, setValue, errors } = useForm({ defaultValues });
  const setErrors = setErrorsFunction(setError);
  const datasets = useGetCourseDataSets(course.id);
  const datasetsOptions = getDatasetsOptions(datasets);
  const createMentorApplication = useCreateMentorApplicationRequest();
  const updateMentorApplication = useUpdateMentorApplicationRequest();
  const { setSnackbar } = useSnackbar();
  const loadApplications = useLoadApplicationsRequest();

  const setValueWithTrigger = (name, value) => {
    setValue(name, value);
    trigger(name);
  };

  const endDate = dayjs(course.deadlines?.end_date);
  const mentorApplicationEndDate = dayjs(course.deadlines?.mentor_application_end_date)
    .utc()
    .format('MMMM DD, YYYY HH:mm');

  useEffect(() => {
    register({ name: formNames.comfortable_teaching_in_english });
    register({ name: formNames.proposed_project_ideas_for_students });
    register({ name: formNames.years_of_course_work_research });
    register({ name: formNames.comfortable_data_set });
    register({ name: formNames.alternative_data_set });
    register({ name: formNames.residing_country_during_course });
  }, [register]);

  const onError = useOnError(setErrors);

  const onSubmit = data => {
    if (isUpdate) {
      updateMentorApplication(applicationId, data)
        .then(() => {
          setSnackbar('Application is updated');
          loadApplications();
        })
        .catch(onError);
    } else {
      createMentorApplication(course.id, data)
        .then(() => {
          setSnackbar('Application is submitted');
          history.push(applicationsRoutes.homepage);
        })
        .catch(onError);
    }
  };

  const fillDataCallback = row => {
    return [
      row.label,
      <RadioButton innerRef={register} name={row.formName} value="1" />,
      <RadioButton innerRef={register} name={row.formName} value="2" />,
      <RadioButton innerRef={register} name={row.formName} value="3" />,
      <RadioButton innerRef={register} name={row.formName} value="4" />,
      <RadioButton innerRef={register} name={row.formName} value="5" />,
    ];
  };

  const computationalNeuroscienceTopicsTableRows = computationalNeuroscienceTopicsTableFillData.map(fillDataCallback);

  const deepLearningConceptsTableRows = deepLearningConceptsFillData.map(fillDataCallback);

  const computationalErrors = {};
  const deepLearningErrors = {};

  Object.entries(errors).forEach(([key, value]) => {
    const computationalErrorIndex = computationalNeuroscienceTopicsTableFillData.findIndex(row => row.formName === key);
    const deepLearningErrorIndex = deepLearningConceptsFillData.findIndex(row => row.formName === key);
    if (computationalErrorIndex > -1) {
      computationalErrors[computationalErrorIndex] = value.message;
    }
    if (deepLearningErrorIndex > -1) {
      deepLearningErrors[deepLearningErrorIndex] = value.message;
    }
  });

  const loadCountriesOptions = useLoadCountriesRequest();

  return (
    <Block title={'Mentor Application - ' + course.name} className="create-mentor-application-form">
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Row>
          <Checkbox
            name={formNames.participated_in_nma_last_year}
            innerRef={register}
            text="I participated in the NMA last year"
          />
        </Form.Row>

        <Form.Row>
          <AsyncSelect
            name={formNames.residing_country_during_course}
            label={
              <>Where will you be residing during the course itself ({endDate.utc().format('MMMM YYYY')})?</>
            }
            onChange={option => {
              setValueWithTrigger(formNames.residing_country_during_course, option?.value ?? option);
            }}
            loadOptions={loadCountriesOptions}
            //isCreatable
            error={errors.residing_country_during_course?.message}
            defaultValue={defaultValues?.residing_country_during_course}
          />
        </Form.Row>

        <Form.Row>
          <Select
            label="We will prioritize accomodating your preferred language(s), but if that is not possible, would you be comfortable teaching in English"
            name={formNames.comfortable_teaching_in_english}
            options={yesOrNoOptions}
            onChange={option => {
              setValueWithTrigger(formNames.comfortable_teaching_in_english, option?.value ?? option);
            }}
            error={errors.comfortable_teaching_in_english?.message}
            defaultValue={defaultValues?.comfortable_teaching_in_english}
          />
        </Form.Row>

        <Form.Row>
          <Form.Label>
            How many groups (3-6 students / group) would you like to mentor during the course? Mentoring one group
            requires approx 5 meetings of one-hour each, and we will consolidate your meetings with all groups into
            single sessions.{' '}
            {errors.groups_mentor_during_count && (
              <span className="error">{errors.groups_mentor_during_count.message}</span>
            )}
          </Form.Label>
          <RadioButton name={formNames.groups_mentor_during_count} innerRef={register} label="1" value="1" />
          <RadioButton name={formNames.groups_mentor_during_count} innerRef={register} label="2" value="2" />
          <RadioButton name={formNames.groups_mentor_during_count} innerRef={register} label="3" value="3" />
          <RadioButton name={formNames.groups_mentor_during_count} innerRef={register} label="4" value="4" />
        </Form.Row>

        <Form.Row>
          <Form.Label>
            How many one-hour mentoring meetings with the TAs can you commit after the course ends? This is one of their
            rewards for doing the hard work of NMA.{' '}
            {errors.hours_meetings_with_ta && <span className="error">{errors.hours_meetings_with_ta.message}</span>}
          </Form.Label>
          <RadioButton name={formNames.hours_meetings_with_ta} innerRef={register} label="0" value="zero" />
          <RadioButton
            name={formNames.hours_meetings_with_ta}
            innerRef={register}
            label="1-2"
            value="between_one_and_two"
          />
          <RadioButton name={formNames.hours_meetings_with_ta} innerRef={register} label="3+" value="more_than_three" />
        </Form.Row>

        <Form.Row>
          <Select
            name={formNames.proposed_project_ideas_for_students}
            options={yesOrNoOptions}
            onChange={option => {
              setValueWithTrigger(formNames.proposed_project_ideas_for_students, option?.value ?? option);
            }}
            label="Would you like to propose ideas for students to choose from? If so, we will contact you later about this."
            error={errors.proposed_project_ideas_for_students?.message}
            defaultValue={defaultValues?.proposed_project_ideas_for_students}
          />
        </Form.Row>

        <Form.Row>
          <Select
            options={yearsOfCourseResearchOptions}
            name={formNames.years_of_course_work_research}
            label="How many years of course work and/or research have you done in computational neuroscience and/or machine learning?"
            onChange={option => {
              setValueWithTrigger(formNames.years_of_course_work_research, option?.value ?? option);
            }}
            error={errors.years_of_course_work_research?.message}
            defaultValue={defaultValues?.years_of_course_work_research}
          />
        </Form.Row>

        <Form.Row>
          <Select
            options={datasetsOptions}
            name={formNames.comfortable_data_set}
            label="Which type(s) of data are you most comfortable working with?"
            onChange={option => {
              setValueWithTrigger(formNames.comfortable_data_set, option?.value ?? option);
            }}
            error={errors.comfortable_data_set?.message}
            defaultValue={defaultValues?.comfortable_data_set}
          />
        </Form.Row>

        <Form.Row>
          <Select
            options={datasetsOptions}
            name={formNames.alternative_data_set}
            label="Would you be able to work with another data set as well?"
            onChange={option => {
              setValueWithTrigger(formNames.alternative_data_set, option?.value ?? option);
            }}
            error={errors.alternative_data_set?.message}
            defaultValue={defaultValues?.alternative_data_set}
          />
        </Form.Row>

        <Form.Row>
          <Form.Label>
            What is your level of comfort talking about these computational neuroscience topics? (1 = no experience of
            comfort, 5 = expert and has taught)
          </Form.Label>
          <Table
            headCells={['Topic', 1, 2, 3, 4, 5]}
            rows={computationalNeuroscienceTopicsTableRows}
            errors={computationalErrors}
          />
        </Form.Row>

        <Form.Row>
          <Form.Label>
            What is your level of comfort talking about these deep learning concepts? (1 = no experience of comfort, 5 =
            expert and has taught)
          </Form.Label>
          <Table
            headCells={['Topic', 1, 2, 3, 4, 5]}
            rows={deepLearningConceptsTableRows}
            errors={deepLearningErrors}
          />
        </Form.Row>

        <Form.Row>
          <Checkbox
            name={formNames.agree_to_abide_code_of_conduct}
            innerRef={register({ required: true })}
            text={
              <>
                (required) I agree to abide by the{' '}
                <Link
                  to="https://github.com/NeuromatchAcademy/precourse/blob/master/CODE_OF_CONDUCT.md"
                  isCrossDomain
                  newTab
                >
                  Code of Conduct
                </Link>
              </>
            }
            error={errors[formNames.agree_to_abide_code_of_conduct]?.type}
          />
        </Form.Row>

        <Form.Row>
          <Checkbox
            name={formNames.agree_terms_and_conditions}
            innerRef={register({ required: true })}
            text={
              <>
                (required) I agree to the terms and conditions
                <ul>
                  <li>Your anonymized data may be shared with funding agencies</li>
                  <li>We may contact you by email to update you on your application</li>
                </ul>
              </>
            }
            error={errors[formNames.agree_terms_and_conditions]?.type}
          />
        </Form.Row>

        <Form.Row>APPLICATION DEADLINE: {mentorApplicationEndDate} (UTC)</Form.Row>

        <Form.Row className="create-mentor-application-form__buttons">
          {isUpdate ? (
            <>
              <Button type="submit">Update application</Button>
            </>
          ) : (
            <>
              <Button
                onClick={() => {
                  previousPage();
                  scrollToTop();
                }}
              >
                Previous Page
              </Button>
              <Button type="submit">Submit application</Button>
            </>
          )}
        </Form.Row>
      </Form>
    </Block>
  );
};
