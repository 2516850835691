import { Button } from "~/common/components/Button/Button";
import { Input } from "~/common/components/Input/Input";
import { Form } from "~/common/components/Form/Form";

export const PasswordForm = ({title, text, onSubmit, password, setPassword}) => {
  return (
    <div className="password-container">
      <h1>{title}</h1>
      <p>{text}</p>
      <Form onSubmit={onSubmit} className="password-form">
        <h5>Please enter the password here:</h5>
        <Input type="password" value={password} onChange={e => setPassword(e.target.value)} />
        <Button type="submit" children="Submit" className="password-btn"/>
      </Form>
    </div>
  );
};
