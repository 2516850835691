import { Layout } from '~/common/components/Layout/Layout';
import { CreateTAApplication } from '~/modules/applications/pages/CreateTAApplication/blocks/CreateTAApplication';

export const CreateTAApplicationPage = () => {
  return (
    <Layout>
      <CreateTAApplication />
    </Layout>
  );
};
