import { Block } from '~/common/components/Block/Block';
import { Form } from '~/common/components/Form/Form';
import { Controller, useForm } from 'react-hook-form';
import { useRef } from "react";
import { Button } from '~/common/components/Button/Button';
import { Link } from '~/common/components/Link/Link';
import { useEffect } from 'react';
import { Select } from '~/common/components/Select/Select';
import { Textarea } from '~/common/components/Textarea/Textarea';
import { useGetStudentApplication } from '~/modules/applications/models/application.hooks';
import { baselinePrice, calculateAcademicDiscount, calculateCOLAAdjustment, calculateSelfFundedDiscount} from '~/modules/applications/models/application.model';
import { useDispatch } from 'react-redux';
import { payStudentApplication } from '~/modules/stripe/redux/stripe.actions';
import { loadStripe } from '@stripe/stripe-js';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { scrollToTop } from '~/modules/app/models/app.model';
import {
  funding,
  fundingOptions
} from '~/modules/applications/models/application.model';

dayjs.extend(utc);

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const formNames = {
    external_funding : 'external_funding',
    self_funding_explanation: 'self_funding_explanation',
    agree: 'agree'
}

export const StudentPaySelfFundedDiscount = ({ application, previousPage, nextPage, defaultValues}) => {
  const { register, handleSubmit, trigger, setValue, formState: { errors }, watch, control } = useForm({ mode: 'onBlur', defaultValues });
  const fundValue = watch(formNames.external_funding);
  const studentApplication = useGetStudentApplication(application.student_application)?.data;

  useEffect(() => {
    register({ name: formNames.external_funding});
    register({ name: formNames.self_funding_explanation});
  }, [register]);

  const dispatch = useDispatch();

  const isFunded = !(fundValue === funding.self_funded || fundValue === funding.yes_denied)

  const isAcademia = studentApplication && !!(studentApplication.is_industry ? 0 : 1)
  const academicDiscount = studentApplication && calculateAcademicDiscount(studentApplication.is_industry)
  const colaAdjustment = studentApplication && calculateCOLAAdjustment(studentApplication.cola, studentApplication.is_industry)
  const residingCountry = studentApplication && studentApplication.residing_country_during_course
  const selfFundedDiscount = studentApplication && calculateSelfFundedDiscount(isFunded, studentApplication.cola, studentApplication.is_industry)
  const recommendedPayment = (baselinePrice - academicDiscount - colaAdjustment - selfFundedDiscount).toFixed(2);

  const formRef = useRef();

  const onContinue = () => {
      nextPage()
      scrollToTop()
  }

  const onSubmit = data => {
    const locationUrl = window.location.href;

    const price = Math.floor(recommendedPayment * 100);
    dispatch(
      payStudentApplication({
        courseApplication: application.id,
        amount: price,
        successUrl: locationUrl,
        cancelUrl: locationUrl,
        isFunded,
        recommendedPayment: Math.floor(recommendedPayment * 100),
      })
    ).then(async response => {
      const sessionId = response.data?.session_id;
      const stripe = await stripePromise;
      stripe.redirectToCheckout({
        sessionId,
      });
    });
  };

  return (
    <>
      <Block title="Enrollment: Funding" className="student-payment">
        <Form ref={formRef}>
          <Form.Row>
          <Button className="student-application-status__go-back" onClick={() => {previousPage()}}>
            Go Back
          </Button>
          </Form.Row>
            <Form.Row>
            Universities, labs, and companies generally have funds set aside to help pay for ongoing training and development. While it may not always be clear that these programs exist, and students may not feel entitled to ask, these organizations are familiar with such requests and are prepared to fund great training programs as they make both you and your organization stronger.
              <br></br><br></br>
            We encourage you to ask your organization for help in funding your training program, and we have provided an example message that you could send to your supervisor, department head, or manager to start the conversation:             <Link
              to="https://github.com/NeuromatchAcademy/precourse/blob/main/enrollmentforms/funding_template.md"
              isCrossDomain
              newTab
            >
              example message
            </Link>
            </Form.Row>
          <Form.Row>
                If you can only be partially reimbursed, select “Yes but the request was denied” and then click “I can’t comfortably afford this price”.
          </Form.Row>
          <Form.Row>
            <Controller
                name={formNames.external_funding}
                control={control}
                rules={{ required: true }}
                render={({ onChange, value, name, ref }) => (
                    <Select
                        inputRef={ref}
                        label="Have you asked your manager, PI, and/or department about funding?"
                        options={fundingOptions}
                        value={fundingOptions.find(c => c.value === value)}
                        onChange={val => onChange(val.value)}
                    />
                )}
              />
              {errors[formNames.external_funding] && errors[formNames.external_funding]?.type === "required" ? (
                <div className="error">
                    {"Must select one option"}
                </div>
              ) : null}
           </Form.Row>
          {(fundValue === funding.self_funded || fundValue === funding.yes_denied)  && (<Form.Row>
          <Textarea
            name={formNames.self_funding_explanation}
             rows={4}
            innerRef={register({ required: "Required field",
                    minLength: {
                        value: 200,
                        message: "Mininimum length is 200 characters"
            }}
            )}
            label="If you are not able to get complete funding, please explain your situation in more detail"
            error={errors[formNames.self_funding_explanation]?.message}
          />
        </Form.Row>)}
          <Form.Row className="student-application-status__discount-table">
            <center>
            <table   className="student-application-status__discount-table" cellPadding="0" cellSpacing="0">
              <tbody>
              <tr>
                <td>Baseline Price</td>
                <td className="alignright">  $ {baselinePrice}</td>
              </tr>
              {isAcademia && (<tr>
                <td>Academic Discount</td>
                <td className="alignright">- $ {academicDiscount}</td>
              </tr>)}
              <tr>
                <td>Cost of Living Adjustment ({residingCountry})</td>
                <td className="alignright">- $ {colaAdjustment}</td>
              </tr>
              {(fundValue === funding.self_funded || fundValue === funding.yes_denied)  && (<tr>
                <td>Self-funded discount</td>
                <td className="alignright">- $ {selfFundedDiscount}</td>
              </tr>)}
              <tr className="total">
                <td className="alignright" width="80%"><b>Your Total</b></td>
                <td className="alignright"><b>$ {recommendedPayment} (USD)</b></td>
              </tr>
              </tbody>
            </table>
              </center>
            </Form.Row>
          <Form.Row className="student-application-status__pay-button">
            <Button
              type="submit"
              onClick={handleSubmit(onSubmit)}
            className="student-application-status__pay-button">
              Pay now
            </Button>
          </Form.Row>
          {(fundValue === funding.self_funded || fundValue === funding.yes_denied) && (<Form.Row className="student-application-status__buttons">
            <Button className="student-application-status__buttons" type="submit" onClick={handleSubmit(onContinue)}>
              I can't comfortably afford this price
            </Button>
          </Form.Row>)}
        </Form>
      </Block>
    </>
  );
};
