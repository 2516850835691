import { default as BootstrapModal } from 'react-bootstrap/Modal';

export const Modal = ({ show, children, close, headerText, className }) => {
  return (
    <BootstrapModal show={show} onHide={close} className={className}>
      <BootstrapModal.Header closeButton>
        <BootstrapModal.Title>{headerText}</BootstrapModal.Title>
      </BootstrapModal.Header>
      {children}
    </BootstrapModal>
  );
};

Modal.Body = BootstrapModal.Body;

Modal.Footer = BootstrapModal.Footer;
