import { useParams } from 'react-router-dom';
import { CreateStudentApplicationForm } from '~/modules/applications/components/CreateStudentApplicationForm/CreateStudentApplicationForm';
import {
  useGetStudentApplication,
  useGetCourse,
  useCheckAccessToEdit,
} from '~/modules/applications/models/application.hooks';
import { applicationTypes } from '~/modules/applications/models/application.model';

export const EditStudentApplication = () => {
  const { applicationId, courseId } = useParams();
  const course = useGetCourse(courseId);
  const application = useGetStudentApplication(applicationId);
  useCheckAccessToEdit(applicationTypes.student);
  const formattedApplication = application && { ...application.data };
  if (formattedApplication) {
    Object.entries(formattedApplication).forEach(([key, value]) => {
      if (typeof value === 'number') {
        formattedApplication[key] = value.toString();
      }
    });
  }
  return (
    <div className="narrow mt-20">
      {application && course && (
        <CreateStudentApplicationForm
          isUpdate
          course={course}
          defaultValues={formattedApplication}
          applicationId={applicationId}
        />
      )}
    </div>
  );
};
