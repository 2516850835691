import { Layout } from '~/common/components/Layout/Layout';
import { useTitle } from '~/modules/app/models/app.hooks';
import { UserApplications } from '~/modules/user/pages/Applications/blocks/Applications';

export const ApplicationsPage = () => {
  useTitle('Applications');
  return (
    <Layout>
      <UserApplications />
    </Layout>
  );
};
