import { Block } from '~/common/components/Block/Block';
import { useGetCourse, useGetPodWithStatus } from '~/modules/applications/models/application.hooks';
import { Table } from '~/common/components/Table/Table';
import { timeSlotsLabels } from '~/modules/user/models/user.model';
import dayjs from 'dayjs';
import { applicationRequestStatuses } from '~/modules/applications/models/application.model';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

export const StudentPodInfo = ({ application }) => {
  const podId = application.pod;
  const { pod, isLoaded } = useGetPodWithStatus(podId);
  const course = useGetCourse(application.course);
  const prePodEndTime = course?.deadlines.pod_realise_date;
  const formattedPrePodEndTime = prePodEndTime ? dayjs(prePodEndTime).utc().format('MM-DD-YYYY HH:mm') : null;
  return (
    <>
      {application.status !== applicationRequestStatuses.accepted && (application.wants_to_enroll) && (
        <div className="narrow mt-20">
          <h3>{application.course_name}</h3>

          <Block title="Your pod info">

            {isLoaded && (
                <>
                {(!podId || !pod) && (
                  <>Congrats, you are officially enrolled as a student in {application.course_name}! Your pod info (TA and timezone) will be released here a few weeks before the start of the course.</>
                )}
                {pod && (
                  <Table
                    headCells={[]}
                    rows={[
                      ['TA name', pod?.ta_info?.full_name],
                      ['TA email', pod?.ta_info?.email],
                      ['Your pod id', pod.id],
                      ['Your pod name', pod.name],
                      ['Your pod time slot', timeSlotsLabels[pod.time_slot]],
                      ['Your pod language', pod.language],
                      ['Your megapod', pod.megapod_name],
                    ]}
                  />
                )}
              </>
            )}
          </Block>

          <Block title="Your pod project info" className="student-project-info">
            <Table
              rows={[
                ['Your pod dataset', pod?.dataset],
                ['Your pod second dataset', pod?.second_dataset],
                ['Your project TA name', pod?.project_ta_info?.full_name],
                ['Your project TA email', pod?.project_ta_info?.email],
              ]}
            />
          </Block>

          <Block title="Your pod Zoom info" className="student-zoom-info">
            <Table
            rows={[
              ['Meeting Link', pod?.zoom?.meeting_link],
              ['Claim host key', pod?.zoom?.claim_host_key],
            ]}
            />
          </Block>
        </div>
      )}
    </>
  );
};
