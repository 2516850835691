import { Layout } from '~/common/components/Layout/Layout';
import { useTitle } from '~/modules/app/models/app.hooks';
import { UserLogistics } from '~/modules/user/pages/Logistics/blocks/Logistics';

export const LogisticsPage = () => {
  useTitle('Logistics');
  return (
    <Layout>
      <UserLogistics />
    </Layout>
  );
};
