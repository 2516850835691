import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useUser } from '~/modules/user/models/user.hooks';
import { useDispatch } from 'react-redux';
import { verificateNewEmail, whoami } from '~/modules/user/redux/user.actions';
import { history } from '~/app/history/history';
import { userRoutes } from '~/modules/user/router/Router';
import { applicationsRoutes } from '~/modules/applications/router/Router';
import { useSnackbar } from '~/modules/app/models/app.hooks';

export const NewEmailVerification = () => {
  const [sent, setSent] = useState(false);
  const { token } = useParams();
  const dispatch = useDispatch();
  const user = useUser();
  const { setSnackbar } = useSnackbar();
  useEffect(() => {
    if (!sent) {
      dispatch(verificateNewEmail(token))
        .then(() => {
          setSent(true);
          if (user) {
            dispatch(whoami()).then(() => {
              history.push(userRoutes.account);
            });
          } else {
            history.push(applicationsRoutes.homepage);
          }
          setSnackbar('Email has been changed');
        })
        .catch(e => {
          const error = e?.response?.data?.non_field_errors;
          const errorType = error[0]?.code;
          if (errorType) {
            setSnackbar('Your token has expired', 'Notification', false);
          } else {
            setSnackbar('Your token is inactive', 'Notification', false);
          }
          if (!user) {
            history.push(userRoutes.login);
          } else {
            history.push(userRoutes.account);
          }
        });
    }

  }, [token, user, sent, dispatch, setSnackbar]);
  return <></>;
};
