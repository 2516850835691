import { Header } from '~/common/components/Layout/components/Header/Header';
import { Footer } from '~/common/components/Layout/components/Footer/Footer';

export const Layout = ({ children, withFooter = true }) => {
  return (
    <>
      <Header />
      <main className="main">
        {children}
        {withFooter && <Footer />}
      </main>
    </>
  );
};
