import { useParams } from 'react-router-dom';
import { useGetApplicationRequest, useGetPod, useGetCourse } from '~/modules/applications/models/application.hooks';
import { applicationTypes, taForTypes } from '~/modules/applications/models/application.model';
import { TAPodInfo } from '~/modules/applications/pages/CourseDashboardPage/blocks/components/TAPodInfo/TAPodInfo';
import { ProjectTAPodInfo } from '~/modules/applications/pages/CourseDashboardPage/blocks/components/ProjectTAPodInfo/ProjectTAPodInfo';
import { TAApplicationStatus } from '~/modules/applications/pages/CourseDashboardPage/blocks/components/TAApplicationStatus/TAApplicationStatus';
import { useEffect, useState } from 'react';

export const TADashboard = () => {
  const [podState, setPod] = useState(null);
  const [podIdState, setPodId] = useState(null);
  const { id } = useParams();
  const application = useGetApplicationRequest(id, applicationTypes.ta);
  const podId = application?.pod;
  useEffect(() => {
    if (podId) {
      setPodId(podId);
    }
  }, [podId]);

  const podIds = Array.isArray(podIdState) ? '?' + podId.map(id => `id=${id}`).join('&') : podId;

  const pod = useGetPod(podIds);

  useEffect(() => {
    if (pod) {
      setPod(pod);
    }
  }, [pod]);

  const course = useGetCourse(application?.course);
  return (
    <>
      {application && course && (
        <div className="narrow mt-20 ta-dashboard">
          {application.pod && pod ? (<>{podState && (application.ta_for === taForTypes.regular_TA ? <TAPodInfo pod={podState} setPod={setPod} application={application} course={course} /> : <ProjectTAPodInfo pods={podState} application={application} />)}</>) :
              (<TAApplicationStatus application={application} />)
          }
        </div>
      )}
    </>
  );
};
