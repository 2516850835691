import { setLoading } from '~/modules/app/redux/app.actions';

const ActionSubtypes = {
  REQUEST: 'REQUEST',
  SUCCESS: 'SUCCESS',
  FAILURE: 'FAILURE',
  UPDATING: 'UPDATING',
  CLEAR: 'CLEAR',
};

export class ReduxController {
  static createState = value => ({
    value,
    isLoading: false,
    isLoaded: false,
    isFailed: false,
    error: null,
  });

  static applyAction = (state, sliceName, action, ...args) => {
    return !sliceName
      ? action(state, ...args)
      : {
          ...state,
          [sliceName]: action(state[sliceName], ...args),
        };
  };

  static createTypesSequence = basename => {
    const result = {
      REQUEST: '',
      SUCCESS: '',
      FAILURE: '',
      UPDATING: '',
      CLEAR: '',
    };

    [
      ActionSubtypes.REQUEST,
      ActionSubtypes.SUCCESS,
      ActionSubtypes.FAILURE,
      ActionSubtypes.UPDATING,
      ActionSubtypes.CLEAR,
    ].forEach(item => {
      result[item] = `${basename}_${item}`;
    }, {});

    return result;
  };

  static createReducer = (initialState, handlers) => {
    return function reducer(state = initialState, action) {
      if (Object.hasOwnProperty.call(handlers, action.type)) {
        return handlers[action.type](state, action);
      } else {
        return state;
      }
    };
  };

  static createHandlers = (actionGroupName, sliceName) => {
    return {
      [`${actionGroupName}_${ActionSubtypes.REQUEST}`](state) {
        return ReduxController.applyAction(state, sliceName, ReduxController.request);
      },
      [`${actionGroupName}_${ActionSubtypes.SUCCESS}`](state, { payload, isReset = false }) {
        return ReduxController.applyAction(state, sliceName, ReduxController.success, payload, isReset);
      },
      [`${actionGroupName}_${ActionSubtypes.UPDATING}`](state, { payload }) {
        return ReduxController.applyAction(state, sliceName, ReduxController.request, payload, true);
      },
      [`${actionGroupName}_${ActionSubtypes.FAILURE}`](state, { error }) {
        return ReduxController.applyAction(state, sliceName, ReduxController.failure, error);
      },
      [`${actionGroupName}_${ActionSubtypes.CLEAR}`](state, { payload = null }) {
        return ReduxController.applyAction(state, sliceName, ReduxController.clear, payload);
      },
    };
  };

  static applyValue = (value, newValue, isReset) => {
    if (isReset) {
      return newValue;
    }

    if (Array.isArray(value) || Array.isArray(newValue)) {
      return [...value, ...newValue];
    }

    if (typeof value === 'object' || typeof newValue === 'object') {
      return {
        ...value,
        ...newValue,
      };
    }

    return newValue;
  };

  static request = (model, payload, isUpdating = false) => {
    return {
      isLoaded: isUpdating ? model.isLoaded : false,
      isLoading: true,
      isFailed: false,
      error: null,
      value: !payload ? model.value : ReduxController.applyValue(model.value, payload),
    };
  };

  static success = (model, payload, isReset) => {
    return {
      ...model,
      value: ReduxController.applyValue(model.value, payload, isReset),
      isLoaded: true,
      isLoading: false,
    };
  };

  static failure = (model, error) => {
    return {
      ...model,
      isLoading: false,
      isFailed: true,
      error,
    };
  };

  static clear = (model, payload) => {
    return ReduxController.createState(payload);
  };

  static createAction = ({
    callApi,
    afterSuccessCall,
    afterFailCall,
    payload,
    actionGroupName,
    withGlobalLoading = true,
    isUpdate = false,
  }) => (dispatch, getState) => {
    if (actionGroupName) {
      dispatch({ type: `${actionGroupName}_${ActionSubtypes.REQUEST}` });
    }
    if (withGlobalLoading) {
      dispatch(setLoading(true));
    }
    return callApi()
      .then(response => {
        if (actionGroupName) {
          if (isUpdate) {
            dispatch({ type: `${actionGroupName}_${ActionSubtypes.UPDATING}`, payload: response.data, ...payload });
          } else {
            dispatch({ type: `${actionGroupName}_${ActionSubtypes.SUCCESS}`, payload: response.data, ...payload });
          }
        }
        if (afterSuccessCall) {
          afterSuccessCall(response.data, dispatch, getState);
        }
        return Promise.resolve(response);
      })
      .catch(error => {
        if (actionGroupName) {
          dispatch({ type: `${actionGroupName}_${ActionSubtypes.FAILURE}`, error: error.response, ...payload });
        }
        if (afterFailCall) {
          afterFailCall(error.response, dispatch, getState);
        }
        return Promise.reject(error);
      })
      .finally(() => {
        if (withGlobalLoading) {
          dispatch(setLoading(false));
        }
      });
  };
}
