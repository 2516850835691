import { CreateStudentApplication } from '~/modules/applications/pages/CreateStudentApplication/blocks/CreateStudentApplication';
import { Layout } from '~/common/components/Layout/Layout';

export const CreateStudentApplicationPage = () => {
  return (
    <Layout>
      <CreateStudentApplication />
    </Layout>
  );
};
