export const USER_STORE_KEY = 'USER';

const getActionGroupName = (name) => {
  return USER_STORE_KEY + '_' + name;
}

export const UserSliceNames = {
  user: 'user',
};

export const UserActionGroups = {
  [UserSliceNames.user]: getActionGroupName(UserSliceNames.user),
};

