import { CreateMentorApplicationForm } from '~/modules/applications/components/CreateMentorApplicationForm/CreateMentorApplicationForm';
import { useParams } from 'react-router-dom';
import {
  useCheckAccessToEdit,
  useGetCourse,
  useGetMentorApplication,
} from '~/modules/applications/models/application.hooks';
import { applicationTypes } from '~/modules/applications/models/application.model';

export const EditMentorApplication = () => {
  const { applicationId, courseId } = useParams();
  const course = useGetCourse(courseId);
  const application = useGetMentorApplication(applicationId);
  const formattedApplication = application && { ...application.data };
  useCheckAccessToEdit(applicationTypes.mentor);
  if (formattedApplication) {
    Object.entries(formattedApplication).forEach(([key, value]) => {
      if (typeof value === 'number') {
        formattedApplication[key] = value.toString();
      }
    });
  }

  return (
    <div className="narrow mt-20">
      {course && application && (
        <CreateMentorApplicationForm
          applicationId={applicationId}
          course={course}
          defaultValues={formattedApplication}
          isUpdate
        />
      )}
    </div>
  );
};
