export const Textarea = ({ name, cols = 30, rows = 10, placeholder, defaultValue, className = '', innerRef, label, error }) => {
  return (
    <div className={'textarea-field ' + className + (error ? ' --with-error' : '')}>
      {label && <label className="form-label">{label}</label>}
      <textarea
        name={name}
        id=""
        cols={cols}
        rows={rows}
        placeholder={placeholder}
        defaultValue={defaultValue}
        className="form-control"
        ref={innerRef}
      />
      {error && <div className="invalid-feedback">{error}</div>}
    </div>
  );
};
