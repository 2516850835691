import { useDispatch } from 'react-redux';
import { deleteSnackbar } from '~/modules/app/redux/app.actions';

export const Snackbar = ({ title, text, id }) => {
  const dispatch = useDispatch();
  const deleteSnackbarCallback = () => dispatch(deleteSnackbar(id));
  return (
    <div className="toast snackbar" role="alert" aria-live="assertive" aria-atomic="true">
      <div className="toast-header">
        <strong className="mr-auto">{title}</strong>
        {/*<small className="text-muted">just now</small>*/}
        <button
          type="button"
          className="ml-2 mb-1 close"
          data-dismiss="toast"
          aria-label="Close"
          onClick={deleteSnackbarCallback}
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="toast-body">{text}</div>
    </div>
  );
};
