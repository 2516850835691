import { Layout } from '~/common/components/Layout/Layout';
import { ForgotPassword } from '~/modules/user/pages/ForgotPassword/blocks/ForgotPassword';
import { useTitle } from '~/modules/app/models/app.hooks';

export const ForgotPasswordPage = () => {
  useTitle('Forgot Password');
  return (
    <Layout withFooter={false}>
      <ForgotPassword />
    </Layout>
  );
};
