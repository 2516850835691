import { useState } from 'react';
import { ApplicationIntro } from '~/modules/applications/components/ApplicationIntro/ApplicationIntro';
import { useParams } from 'react-router-dom';
import { useGetCourse } from '~/modules/applications/models/application.hooks';
import { CreateTAApplicationForm } from '~/modules/applications/components/CreateTAApplicationForm/CreateTAApplicationForm';

const pages = {
  intro: 'intro',
  form: 'form',
};

export const CreateTAApplication = () => {
  const { id } = useParams();
  const course = useGetCourse(id);
  const [page, setPage] = useState(pages.intro);

  const nextPageCallback = () => {
    setPage(pages.form);
  };

  const previousPageCallback = () => {
    setPage(pages.intro);
  };

  if (!course) {
    return null;
  }

  return (
    <div className="create-ta-application">
      {page === pages.form && <CreateTAApplicationForm previousPage={previousPageCallback} course={course} />}
      {page === pages.intro && (
        <ApplicationIntro
          title={'Teaching Assistant Application - ' + course.name}
          text={course.ta_application_intro}
          description={course.description}
          nextPage={nextPageCallback}
        />
      )}
    </div>
  );
};
