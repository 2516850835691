import { Button } from "~/common/components/Button/Button";
import React, { useState, useEffect } from "react";
import { useGetMediaFiles } from "~/modules/app/models/app.hooks";
import { setSnackbar } from '~/modules/app/redux/app.actions';
import { useDispatch } from 'react-redux';
import axios from "axios";

const FileTree = ({ files, onDownloadFile }) => {
  const renderTree = (nodes) => {
    return nodes.sort((a, b) => {
        if (a.type !== b.type) {
          return a.type === "directory" ? 1: -1;
        }
        return a.name.localeCompare(b.name);
      }).map((node, index) => {
      if (node.type === "directory") {
        return (
          <div key={index}>
            <div className="directory-container">
              <span className="directory-name">{node.name}</span>
            </div>
            <ul className="sub-tree">{renderTree(node.children)}</ul>
          </div>
        );
      } else {
        return (
          <li key={index} className="file-item">
            <span className="file-name">{node.name}</span>
            <Button onClick={() => onDownloadFile(node.url, node.name)}>
              Download
            </Button>
          </li>
        );
      }
    });
  };
  return <ul className="file-tree">{renderTree(files)}</ul>;
};

const buildFileTree = (files) => {
  const root = {
    type: 'directory',
    name: '/',
    children: []
  };

  files.forEach(file => {
    const parts = file.url.split('/');
    let currentNode = root;

    parts.forEach((part, index) => {
      if (part) {
        const isLastPart = index === parts.length - 1;
        const existingNode = currentNode.children.find(child => child.name === part);

        if (existingNode) {
          if (isLastPart) {
            // Update the existing directory node to a file node
            existingNode.type = 'file';
            existingNode.url = file.url;
          } else {
            // Traverse down the tree
            currentNode = existingNode;
          }
        } else {
          if (isLastPart) {
            // Add a new file node
            currentNode.children.push({
              type: 'file',
              name: part,
              url: file.url
            });
          } else {
            // Add a new directory node and traverse down the tree
            const newNode = {
              type: "directory",
              name: part,
              children: [],
            };
            currentNode.children.push(newNode);
            currentNode = newNode;
          }
        }
      }
    });
  });

  return root.children;
};

const FileViewer = ({title}) => {
    const [files, setFiles] = useState([]);
    const [progress, setProgress] = useState(0);
    const dispatch = useDispatch();
    const response = useGetMediaFiles();
    useEffect(() => {
        if (response) {
          // Build a tree structure from the response
          const tree = buildFileTree(response);
          setFiles(tree);
        }
    }, [response]);

    const downloadFile = async (url, fileName) => {
      dispatch(setSnackbar('Download started, this may take a while. Please be patient.', 'Notification', true, 7000));
      try {
        const response = await axios.get(url, {
          responseType: "arraybuffer",
          // responseType: "blob", // important
          withCredentials: true,
          onDownloadProgress: (progressEvent) => {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            setProgress(percentCompleted);
          },
        });
        setProgress(100);
        const contentType = response.headers["content-type"];
        const blob = new Blob([response.data], { type: contentType });

        const a = document.createElement("a");
        a.href = URL.createObjectURL(blob);
        a.download = fileName;
        a.target = "_blank";
        a.rel = "noreferrer";
        a.style.display = "none";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        dispatch(setSnackbar('Download successfully completed!', 'Notification', true, 7000));
      } catch (error) {
        dispatch(setSnackbar('There was an error downloading this file.', 'Notification', true, 7000));
        console.error("Error downloading the file:", error);
      }
      setProgress(0);
    };

    return (
        <div className="file-viewer">
          <h1>{title}</h1>
          <h5><b>Note:</b> We only support downloading one file at a time.</h5>
          {progress > 0 && (
            <div id="bardiv">
              <p>Download Progress: {progress}%</p>
              <progress id="bar" value={progress} max="100">{progress}%</progress>
            </div>
          )}
          <FileTree files={files} onDownloadFile={downloadFile} />
        </div>
    );
};

export default FileViewer;
