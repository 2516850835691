import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useGetCertificateRequest } from '~/modules/user/models/user.hooks';
import { history } from '~/app/history/history';
import { applicationsRoutes } from '~/modules/applications/router/Router';
import dayjs from 'dayjs';
import { getNumberWithOrdinal } from '~/modules/app/models/app.model';
import { certificateTypes } from '~/modules/user/models/user.model';
import { userRoutes } from '~/modules/user/router/Router';
import { Link } from '~/common/components/Link/Link';

const LABELS = {
  [certificateTypes.completed_the_course]: 'completion of the interactive track of',
  [certificateTypes.completed_the_course_and_project]:
    'for completion of the interactive track and the course project of',
  [certificateTypes.ta_certificate]: 'for their service as a Teaching Assistant during',
  [certificateTypes.lead_ta_certificate]: 'for their service as a Lead Teaching Assistant during',
  [certificateTypes.project_ta_certificate]: 'for their service as a Project Teaching Assistant during',
};

const CERTIFICATE_LABEL = {
  [certificateTypes.completed_the_course]: 'Completion',
  [certificateTypes.completed_the_course_and_project]: 'Completion',
  [certificateTypes.ta_certificate]: 'Excellence',
  [certificateTypes.lead_ta_certificate]: 'Excellence',
  [certificateTypes.project_ta_certificate]: 'Excellence',
};

const BADGES = {
  [certificateTypes.completed_the_course]: "/badges_completed_the_course.png",
  [certificateTypes.completed_the_course_and_project]: "/badges_completed_the_course_and_project.png",
  [certificateTypes.ta_certificate]: "/badges_ta_certificate.png",
  [certificateTypes.lead_ta_certificate]: "/badges_lead_ta_certificate.png",
  [certificateTypes.project_ta_certificate]: "/badges_project_ta_certificate.png"
}

export const Certificate = () => {
  const { id } = useParams();
  const [certificate, setCertificate] = useState(null);
  const getCertificate = useGetCertificateRequest();

  useEffect(() => {
    getCertificate(id)
      .then(response => {
        setCertificate(response.data);
      })
      .catch(() => {
        history.push(applicationsRoutes.homepage);
      });
  }, [id, getCertificate]);

  useEffect(() => {
    document.body.classList.add('body-certificate');

    return () => {
      document.body.classList.remove('body-certificate');
    };
  }, []);

  const endDateNumber = getNumberWithOrdinal(+dayjs(certificate?.course.deadlines.end_date).format('DD'));
  const endDateMonth = dayjs(certificate?.course.deadlines.end_date).format('MMMM');
  const endDateYear = dayjs(certificate?.course.deadlines.end_date).format('YYYY');
  const courseName = String(certificate?.course.name);
  const courseLength =  certificate?.certificate_type == certificateTypes.completed_the_course ? certificate?.course.course_length : certificate?.course.course_length_project; 
  const badges_path = certificate?.course.badges_path; 
  const signatures_path = certificate?.course.signatures_path; 
  const public_url = process.env.REACT_APP_PUBLIC_URL;

  return (
    <>
      {certificate && (
        <div className="certificate">
          <img src={badges_path + "/cert_header.png"} style={{ marginBottom: 36 }} className="certificate__header-img" />
          <p>We are honored to present this</p>
          <h1>Certificate of {CERTIFICATE_LABEL[certificate?.certificate_type]}</h1>
          <p>to</p>
          <h2>{certificate?.full_name}</h2>
          <p>
            On the {endDateNumber} of {endDateMonth} {endDateYear}
          </p>
          <p>
            {LABELS[certificate?.certificate_type]}
            <br />
            {courseName}, with a duration of {courseLength} hours.
          </p>
          <div className="badges">
            <div className="row">
              <div className="column" >
                <img src={badges_path + BADGES[certificate?.certificate_type]} width="230"/>
                <div className="row"> 
                 <span> <p>Verify the authenticity of this certificate at: <br/>  
                <Link to={ userRoutes.certificate + '/' + certificate.id }> { public_url + userRoutes.certificate + '/' + certificate.id} </Link></p></span>
                </div>
              </div>
              <div className="column">
                <h6>Executive Committee</h6>
                <div className="row">
                  <img src={signatures_path} width="350" />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
